import {
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
	DeliveryMethod,
	RootState,
	fetchDeliveryMethods,
	getDeliveryMethods,
	setDeliveryMethod,
	store,
} from '../../../redux';
import { useSelector } from 'react-redux';
import { formatPriceToForint } from '../../../helpers';
import {useFormikContext} from "formik";

export const DeliveryMethodDetails = ({
	name,
	delivery_method_id,
}) => {
	const formikContext = useFormikContext();
	// @ts-ignore
	const shippingValues = formikContext.values.shipping
	const deliveryMethods: DeliveryMethod[] | undefined =
		useSelector(getDeliveryMethods);
	const selectedDeliveryMethod = useSelector(
		(state: RootState) =>
			state.checkout.selectedDeliveryMethod,
	);
	useEffect(() => {
		store.dispatch(fetchDeliveryMethods());
	}, []);

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	const handleDeliveryMethodSelection = async (
		method: DeliveryMethod,
	) => {
		await formikContext.setFieldValue(name, method.id);
		store.dispatch(setDeliveryMethod(method));
	};

	const DeliveryMethodLabel = ({
		deliveryMethod,
	}: {
		deliveryMethod: DeliveryMethod;
	}) => {
		return (
			<Grid container flexDirection="row">
				<Grid
					sx={{
						width: 'max-content',
						minWidth: '100px',
					}}
				>
					<Typography variant="h6" component="h5">
						{deliveryMethod.fee === 0
							? prompts[currentLocale].shop.free
							: formatPriceToForint(deliveryMethod.fee)}
					</Typography>
					{deliveryMethod?.main_image?.src ? (
						<img
							src={deliveryMethod?.main_image?.src}
							width="50"
							height="auto"
							style={{ marginBottom: '0px' }}
						/>
					) : null}
				</Grid>
				<Grid
					flexDirection="column"
					sx={{ flex: 1 }}
				>
					<Typography variant="body1" component="h6">
						{deliveryMethod.name[currentLocale]}
					</Typography>
					<Typography variant="body2" component="p">
						{deliveryMethod.notes[currentLocale]}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	return deliveryMethods ? (
		<RadioGroup
			aria-label="position"
			sx={{
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				marginTop: 1,
			}}
			value={shippingValues.delivery_method_id}
		>
			{deliveryMethods.map((method) => {
				return (
					<React.Fragment key={method.id}>
						<FormControlLabel
							checked={
								method.id === selectedDeliveryMethod?.id
							}
							sx={{
								marginLeft: 0,
								marginBottom: 1,
								width: '100%',
								justifyContent: 'space-between',
								cursor: 'default',
								'& .MuiFormControlLabel-label': {
									width: '100%',
								},
								order: method.fee === 0 ? 0 : 1,
								paddingBottom: '4px',
							}}
							key={method.id}
							value={method.fee}
							name={name}
							control={
								<Radio sx={{ wiidth: '20%', padding: 0 }} />
							}
							label={
								<DeliveryMethodLabel
									deliveryMethod={method}
								/>
							}
							onChange={() =>
								handleDeliveryMethodSelection(method)
							}
							labelPlacement="start"
						/>
					</React.Fragment>
				);
			})}
		</RadioGroup>
	) : (
		<Grid container>
			<CircularProgress />
		</Grid>
	);
};
