import {
	combineReducers,
	configureStore,
} from '@reduxjs/toolkit';
import {
	// cartReducerForUs,
	// cartSlice,
	cartReducer,
	shopReducer,
	productReducer,
	genericReducer,
	authReducer,
	localeReducer,
	checkoutReducer,
	productListenerMiddleware,
} from './slices';

const rootReducer = combineReducers({
	generic: genericReducer,
	shop: shopReducer,
	products: productReducer,
	cart: cartReducer,
	auth: authReducer,
	checkout: checkoutReducer,
	locale: localeReducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().prepend(
			productListenerMiddleware.middleware,
		),
	devTools: process.env.REACT_APP_ENV !== 'production',
});
