import {
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import {
	Country,
	DeliveryDetails,
	DeliveryMethod,
	OrderUser,
	PromoCode,
	RootState,
	GeolocationType as Geolocation,
	PaymentMethod,
} from '../types';
import {
	applyDiscount,
	checkStock,
	fetchCountries,
	fetchDeliveryMethods,
	fetchParcelShops,
	fetchPaymentMethods,
	fetchWebshopDetails,
	postOrder,
	prepOrder,
	validateTaxNumber,
} from '../thunks';
import {
	getSubTotalOfCart,
	getVatOfCart,
} from './cartSlice';
export type CheckoutState = {
	orderUser?: OrderUser;
	orderUserResponse?: any;
	isBillingSame: boolean;
	order: any;
	paymentMethods?: PaymentMethod[];
	deliveryMethods?: DeliveryMethod[];
	selectedDeliveryMethod?: DeliveryMethod;
	selectedPaymentMethod?: PaymentMethod;
	canProgress?: boolean;
	isCheckoutLoading?: boolean;
	discount: PromoCode | null;
	deliveryDetails: any | undefined;
	billingDetails: any | undefined;
	personalDetails: any | undefined;
	shouldSubscribe: boolean | undefined;
	business: {
		isBusiness: boolean;
		company_name?: string;
		tax_number: {
			value?: number | undefined;
			vatExempt?: boolean | undefined;
			isBeingFetched: boolean;
		};
	};
	countries: {
		items: Country[] | undefined;
		isBeingFetched: boolean | undefined;
		selected: Country;
	};
	webshop: {
		details: any | undefined;
		isBeingFetched: boolean | undefined;
		stock_available: boolean | undefined;
	};
	geolocation: Geolocation;
	parcelShops: {
		isBeingFetched: boolean;
		all: object[];
		currentPage: object[];
		selected: object | undefined;
	};
};
const initialState: CheckoutState = {
	shouldSubscribe: undefined,
	business: {
		isBusiness: false,
		tax_number: { isBeingFetched: false },
	},
	webshop: {
		details: undefined,
		isBeingFetched: undefined,
		stock_available: undefined,
	},
	orderUser: undefined,
	orderUserResponse: undefined,
	isBillingSame: true,
	order: undefined,
	deliveryMethods: undefined,
	canProgress: false,
	isCheckoutLoading: false,
	discount: null,
	selectedDeliveryMethod: undefined,
	selectedPaymentMethod: undefined,
	deliveryDetails: undefined,
	paymentMethods: undefined,
	billingDetails: undefined,
	personalDetails: undefined,
	countries: {
		items: undefined,
		isBeingFetched: undefined,
		selected: {
			id: 101,
			name: 'Hungary',
			code: 'HU',
			created_at: null,
			updated_at: null,
		},
	},
	parcelShops: {
		isBeingFetched: false,
		all: [],
		currentPage: [],
		selected: undefined,
	},
	geolocation: {
		enabled: null,
		coordinates: undefined,
	},
};

export const checkoutSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		updateSubscriptionPreference: (
			state,
			{ payload }: { payload: boolean | undefined },
		) => {
			state.shouldSubscribe = payload ?? false;
		},
		updateSelectedLocker: (
			state,
			{ payload }: { payload: object | undefined },
		) => {
			state.parcelShops.selected = payload;
		},
		updateGeolocation: (
			state,
			{ payload }: { payload: Geolocation },
		) => {
			state.geolocation = payload;
		},
		updateIsBusiness: (state, { payload }) => {
			state.business.isBusiness = payload;
		},
		updateBusinessDetails: (state, { payload }) => {},
		updateSelectedCountry: (
			state,
			{ payload }: { payload: number },
		) => {
			const country = state.countries.items?.find(
				(country) => country.id === payload,
			);
			if (country) {
				state.countries.selected = country;
			}
		},
		applyDiscount: (state, { payload }) => {
			// todo: to be implemented
		},
		setCheckoutLoading: (state, { payload }) => {
			state.isCheckoutLoading = payload;
		},
		setCanProgress: (state, { payload }) => {
			state.canProgress = payload;
		},
		setBillingIsSameAsShipping: (state, { payload }) => {
			state.isBillingSame = payload;
		},
		personalDetailsForOrderUser: (state, { payload }) => {
			// todo: handle this better
			state.personalDetails = payload;
			return state;
		},
		cancelPayment: (state) => {
			state.orderUserResponse = undefined;
			state.order = undefined;
		},
		resetCheckout: (state) => {
			state.personalDetails = initialState.personalDetails;
			state.billingDetails = initialState.billingDetails;
			state.deliveryDetails = initialState.deliveryDetails;
			state.orderUserResponse = undefined;
			state.order = initialState.order;
			state.discount = null;
			state.shouldSubscribe = initialState.shouldSubscribe;
			state.selectedPaymentMethod =
				initialState.selectedPaymentMethod;
			state.selectedDeliveryMethod =
				initialState.selectedDeliveryMethod;
			state.business = initialState.business;
		},
		setDeliveryMethod: (checkoutState, action) => {
			checkoutState.selectedDeliveryMethod = action.payload;
		},
		setPaymentMethod: (checkoutState, action) => {
			checkoutState.selectedPaymentMethod = action.payload;
		},
		deliveryDetailsForOrderUser: (state, { payload }) => {
			const { shipping } = payload;
			state.deliveryDetails = {
				...shipping,
				countryId: shipping.country.id,
			};
		},
		billingDetailsForOrderUser: (state, { payload }) => {
			const { billing } = payload;
			state.billingDetails = {
				...billing,
				countryId: billing.country.id,
			};
		},
		deliveryAndBillingForOrderUser: (
			state,
			{ payload }: { payload: DeliveryDetails },
		) => {
			if (state.orderUser) {
				state.orderUser = {
					...state.orderUser,
					billing: payload,
					shipping: payload,
				};
			} else {
				state = {
					...state,
					orderUser: {
						// todo: find a better way to do this !!!
						first_name: '',
						last_name: '',
						email: '',
						// phone: '',
						billing: payload,
						shipping: payload,
					},
				};
			}
			return state;
		},
	},
	extraReducers(builders) {
		builders
			.addCase(
				prepOrder.rejected,
				(checkoutState, action) => {
					checkoutState.orderUserResponse = action.payload;
				},
			)
			.addCase(
				prepOrder.fulfilled,
				(checkoutState, { payload }) => {
					checkoutState.orderUserResponse = payload.data;
					checkoutState.canProgress = true;
				},
			)
			.addCase(
				postOrder.rejected,
				(checkoutState, action) => {
					checkoutState.isCheckoutLoading = false;
					checkoutState.canProgress = false;
					checkoutState.order = action.payload;
				},
			)
			.addCase(
				postOrder.pending,
				(checkoutState, action) => {
					checkoutState.isCheckoutLoading = true;
				},
			)
			.addCase(
				postOrder.fulfilled,
				(checkoutState, action) => {
					checkoutState.isCheckoutLoading = false;
					checkoutState.order = action.payload.data;
				},
			)
			.addCase(
				fetchPaymentMethods.fulfilled,
				(checkoutState, action) => {
					checkoutState.paymentMethods =
						action.payload.data;
				},
			)
			.addCase(
				fetchDeliveryMethods.fulfilled,
				(checkoutState, action) => {
					checkoutState.deliveryMethods =
						action.payload.data;
					// return action.payload.data;
				},
			)
			.addCase(
				fetchDeliveryMethods.rejected,
				(checkoutState, action) => {
					// console.log(action.payload);
					// checkoutState.deliveryMethods =
					// action.payload;
				},
			)
			.addCase(
				applyDiscount.fulfilled,
				(checkoutState, { payload }) => {
					checkoutState.discount = payload.data;
				},
			)
			.addCase(
				applyDiscount.rejected,
				(checkoutState, { payload }) => {
					checkoutState = {
						...checkoutState,
						discount: null,
					};
				},
			)
			.addCase(
				fetchCountries.fulfilled,
				(checkoutState, { payload }) => {
					checkoutState.countries = {
						...checkoutState.countries,
						items: payload.data,
						isBeingFetched: false,
					};
				},
			)
			.addCase(fetchCountries.pending, (checkoutState) => {
				checkoutState.countries = {
					...checkoutState.countries,
					isBeingFetched: true,
				};
			})
			.addCase(fetchCountries.rejected, (checkoutState) => {
				checkoutState.countries = {
					...checkoutState.countries,
					isBeingFetched: false,
				};
			})
			.addCase(
				validateTaxNumber.fulfilled,
				(checkoutState, { payload }) => {
					checkoutState.business = {
						...checkoutState.business,
						company_name: payload.company_name,
						tax_number: {
							vatExempt: true,
							value: payload.value,
							isBeingFetched: false,
						},
					};
				},
			)
			.addCase(
				validateTaxNumber.pending,
				(checkoutState) => {
					checkoutState.business.tax_number = {
						...checkoutState.business.tax_number,
						isBeingFetched: true,
					};
				},
			)
			.addCase(
				validateTaxNumber.rejected,
				(checkoutState) => {
					checkoutState.business.tax_number = {
						...checkoutState.business?.tax_number,
						isBeingFetched: false,
					};
				},
			)
			.addCase(
				fetchParcelShops.fulfilled,
				(checkoutState, { payload }) => {
					checkoutState.parcelShops = {
						...checkoutState.parcelShops,
						all: [
							...checkoutState.parcelShops.all,
							...payload.data,
						],
						currentPage: payload.data,
						isBeingFetched: false,
					};
				},
			)
			.addCase(
				fetchParcelShops.pending,
				(checkoutState) => {
					checkoutState.parcelShops = {
						...checkoutState.parcelShops,
						isBeingFetched: true,
					};
				},
			)
			.addCase(
				fetchParcelShops.rejected,
				(checkoutState) => {
					checkoutState.parcelShops = {
						...checkoutState.parcelShops,
						isBeingFetched: false,
					};
				},
			)
			.addCase(
				fetchWebshopDetails.fulfilled,
				(checkoutState, { payload }) => {
					checkoutState.webshop = {
						...checkoutState.webshop,
						isBeingFetched: false,
						details: payload.data,
					};
				},
			)
			.addCase(
				fetchWebshopDetails.pending,
				(checkoutState) => {
					checkoutState.webshop = {
						...checkoutState.webshop,
						isBeingFetched: true,
					};
				},
			)
			.addCase(
				fetchWebshopDetails.rejected,
				(checkoutState) => {
					checkoutState.webshop = {
						...checkoutState.webshop,
						isBeingFetched: false,
					};
				},
			)
			.addCase(checkStock.fulfilled, (checkoutState) => {
				checkoutState.webshop = {
					...checkoutState.webshop,
					isBeingFetched: false,
					stock_available: true,
				};
			})
			.addCase(checkStock.pending, (checkoutState) => {
				checkoutState.webshop = {
					...checkoutState.webshop,
					isBeingFetched: true,
				};
			})
			.addCase(checkStock.rejected, (checkoutState) => {
				checkoutState.webshop = {
					...checkoutState.webshop,
					isBeingFetched: false,
					stock_available: false,
				};
				checkoutState.orderUserResponse = undefined;
				checkoutState.order = undefined;
			});
	},
});

const discountSelector = (state: RootState) => {
	return state.checkout?.discount;
};

const calculateTotal = (state: RootState) => {
	const subtotal = getSubTotalOfCart(state);
	const vat = getVatOfCart(state);
	const discount = discountValueSelector(state);
	const selectedDelivery = deliveryMethodSelector(state);
	if (selectedDelivery?.fee) {
		return Math.ceil(
			subtotal + vat - discount + selectedDelivery.fee,
		);
	}
	return Math.ceil(subtotal + vat - discount);
};

export const getTotalOfCart = createSelector(
	[calculateTotal],
	(total) => total,
);

export const getCountryFromCountryCode = (
	state: RootState,
	countryCode: 'string',
) => {
	const countries = state.checkout.countries.items;
	const country = countries?.find(
		(country) =>
			country.code.toLowerCase() ===
			countryCode.toLowerCase(),
	);
	if (country) {
		return country;
	}
	return null;
};

// alawys round down if percentage
const discountValueSelector = (state: RootState) => {
	const discount = discountSelector(state);
	const subtotal = getSubTotalOfCart(state);
	if (discount === undefined) {
		return 0;
	}
	// maybe move this out so that
	// it reflects that the code is invalid
	if (Array.isArray(discount)) {
		return 0; //'Invalid discount code';
	} else {
		if (discount?.discount_flat) {
			return discount.discount_flat;
		} else if (discount?.discount_percentage) {
			const discountValue =
				subtotal * discount.discount_percentage;
			return Math.floor(discountValue);
		}
		return 0;
	}
};

export const getDiscountValue = createSelector(
	[discountValueSelector],
	(discountValue) => discountValue,
);

export const getDiscount = createSelector(
	[discountSelector],
	(discount) => {
		discount;
	},
);

const deliveryMethodsSelector = (state: RootState) => {
	return state.checkout.deliveryMethods;
};
export const getDeliveryMethods = createSelector(
	[deliveryMethodsSelector],
	(methods) => methods,
);

const checkoutSelector = (state: RootState) => {
	return state.checkout;
};
export const getIsCheckoutLoading = createSelector(
	[checkoutSelector],
	(checkout) => checkout?.isCheckoutLoading ?? false,
);
const currentOrderSelector = (state: RootState) => {
	return state.checkout?.order;
};
export const stripeSecretSelector = createSelector(
	[currentOrderSelector],
	(order) => order?.payment?.service_payment_id,
);
export const getCurrentOrder = createSelector(
	[currentOrderSelector],
	(order) => order,
);
const isBillingSameSelector = (state: RootState) => {
	return state.checkout.isBillingSame;
};

export const getIsBillingSame = createSelector(
	[isBillingSameSelector],
	(isBillingSame) => isBillingSame,
);

const deliveryMethodSelector = (state: RootState) => {
	return state.checkout.selectedDeliveryMethod;
};

export const getSelectedDelivery = createSelector(
	[deliveryMethodSelector],
	(deliveryMethod) => deliveryMethod,
);

export const {
	setDeliveryMethod,
	setPaymentMethod,
	cancelPayment,
	personalDetailsForOrderUser,
	deliveryAndBillingForOrderUser,
	setBillingIsSameAsShipping,
	setCanProgress,
	setCheckoutLoading,
	billingDetailsForOrderUser,
	deliveryDetailsForOrderUser,
	updateSelectedCountry,
	updateIsBusiness,
	updateBusinessDetails,
	updateGeolocation,
	updateSubscriptionPreference,
	updateSelectedLocker,
	resetCheckout,
} = checkoutSlice.actions;

export const checkoutReducer = checkoutSlice.reducer;
