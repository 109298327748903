import React from 'react';
import {
	Grid,
	IconButton,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	cancelPayment,
	store,
	setCanProgress,
	updateDialogDetails,
} from '../../../../redux';
import { RenderDetails } from './RenderDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../../themes';
import EditIcon from '@mui/icons-material/Edit';
import {
	doScrollToTop,
	useTranslation,
} from '../../../../helpers';

export const BillingSummary = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const checkoutDetails = useSelector(
		(state: RootState) => state.checkout,
	);

	const billingDetails = checkoutDetails.billingDetails;

	const translations = useTranslation();
	const name = checkoutDetails?.personalDetails?.name;
	if (!name) {
		store.dispatch(setCanProgress(false));
		store.dispatch(
			updateDialogDetails({
				visible: true,
				content: translations.errors.generic,
				isError: true,
			}),
		);
	}
	const { tax_number, isBusiness, company_name } =
		checkoutDetails.business;
	const businessDetails =
		isBusiness && tax_number.vatExempt
			? {
					tax_number: tax_number.value,
					company_name,
			  }
			: undefined;
	const navigateBack = () => {
		store.dispatch(setCanProgress(false));
		store.dispatch(cancelPayment());
		doScrollToTop();
	};
	return (
		<Paper
			variant="outlined"
			square
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							marginBottom: 4,
							padding: 4,
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
					  }
			}
		>
			<Grid
				alignItems="center"
				container
				sx={{ marginBottom: 2 }}
			>
				<FontAwesomeIcon
					icon={faFileInvoice}
					style={{
						padding: 8,
						marginRight: 8,
						background: colors['brp-yellow'].main,
					}}
				/>
				<Typography
					variant="body1"
					component="span"
					sx={{
						textTransform: 'capitalize',
						color: colors['brp-black'].main,
					}}
					marginBottom="-5px"
				>
					{translations.navigation.billing_details}
				</Typography>
				<IconButton
					sx={{ marginLeft: 'auto' }}
					onClick={navigateBack}
				>
					<EditIcon />
				</IconButton>
			</Grid>
			<RenderDetails
				title={null}
				payload={{
					name: isBusiness ? company_name : name,
					...billingDetails,
					country: billingDetails?.country.name,
					tax_number: businessDetails?.tax_number,
				}}
			/>
		</Paper>
	);
};
