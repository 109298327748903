import { Grid, Typography } from '@mui/material';
import { AccountSummary } from '.';
import styles from './account.module.scss';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	RootState,
	selectAccountDetails,
} from '../../redux';

export const Account = () => {
	const accountDetails = useSelector(selectAccountDetails);
	const navigate = useNavigate();

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	const [tabSelected, setTabSelected] = useState(0);
	const handleTabChange = (tabIndex: number) => {
		setTabSelected(tabIndex);
		switch (tabIndex) {
			case 0:
				navigate('details');
				break;
			case 1:
				navigate('delivery-details');
				break;
			case 2:
				navigate('order-history');
				break;
			case 3:
				navigate('password-change');
				break;
			default:
				navigate('/account');
				break;
		}
	};
	return (
		<Grid
			container
			flexDirection="column"
			maxWidth="md" //"lg"
			sx={{ margin: '0 auto' }}
		>
			{/* My Account */}
			<Typography
				component="h1"
				variant="h4"
				sx={{ paddingX: {xs: 4, md: 0}, paddingY: {xs: 2, md: 1}, paddingBottom: 0, textTransform: 'uppercase' }}
			>
				{translations.account.title}
			</Typography>
			<Grid container className={styles.yellowToGrey} sx={{ marginBottom: {xs: 0, md: 2}}}></Grid>
			<Grid container>
				<AccountSummary
					tabSelected={tabSelected}
					handleTabChange={handleTabChange}
					accountDetails={accountDetails}
				/>
				<Grid item xs={12} md={9} padding={3}>
					<Outlet context={accountDetails} />
				</Grid>
				{/* Account summary with nav items on the left*/}
				{/* content changing on the right */}
			</Grid>
		</Grid>
	);
};
