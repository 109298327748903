import { Grid, Skeleton } from '@mui/material';

export const CategoryCardsSkeleton = () => {
	return (
		<Grid container>
			<Skeleton
				variant="rectangular"
				width="100%"
				height="min(10vh,100px)"
			/>
			<Grid
				container
				maxWidth="lg"
				justifyContent="space-between"
				marginX="auto"
				paddingBottom={4}
			>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={250}
					sx={{ marginTop: 2 }}
				/>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={250}
					sx={{ marginTop: 2 }}
				/>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={250}
					sx={{ marginTop: 2 }}
				/>
				<Skeleton
					variant="rectangular"
					width="45%"
					height={250}
					sx={{ marginTop: 2 }}
				/>
			</Grid>
		</Grid>
	);
};
