import {
	faCheckCircle,
	faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import { colors } from '../../themes';
import { useSelector } from 'react-redux';
import {
	RootState,
	resetCart,
	resetCheckout,
	store, selectAccountDetails, setCanProgress,
} from '../../redux';
import { YouMayLike } from './components';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { CanAmSubscription } from '../../components';

export const Completion = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const reference = urlParams.get('ref');
	const status = urlParams.get('redirect_status');
	const success = status === 'succeeded';

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const accountDetails = useSelector(selectAccountDetails);
	const userIsLoggedIn = !!accountDetails?.id;
	const navigate = useNavigate();
	const navigateToHome = () => {
		navigate('/');
	};
	const navigateToAccount = () => {
		navigate('/account');
	};
	const navigateToLogin = () => {
		navigate('/login');
	};

	useEffect(() => {
		store.dispatch(setCanProgress(false));
		store.dispatch(resetCheckout());
		store.dispatch(resetCart());
	}, []);

	return (
		<>
			<Grid
				container
				justifyContent="center"
				margin="32px auto"
				maxWidth="lg"
			>
				{/* top */}
				<Grid
					container
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					sx={{
						width: 'max-content',
						paddingBottom: 1,
						paddingX: { xs: 2, md: 0 },
					}}
					// sx={{  }}
				>
					<FontAwesomeIcon
						icon={success ? faCheckCircle : faTimesCircle}
						style={{
							color: success
								? colors['green'].main
								: colors['canam-on-red'].main,
							fontSize: 48,
						}}
					/>
					<Typography
						variant="h5"
						component="h1"
						sx={{
							color: success
								? colors['green'].main
								: colors['canam-on-red'].main,
							mt: 2,
							textAlign: 'center',
						}}
					>
						{success ? (
							<span>
								{
									prompts[currentLocale].text
										.completion_success
								}
							</span>
						) : (
							<span>
								{
									prompts[currentLocale].text
										.completion_error
								}
							</span>
						)}
					</Typography>
					{reference ? (
						<Typography
							variant="body2"
							component="p"
							sx={{
								color: colors['brp-black'].main,
								mt: 2,
								borderBottom: '2px dashed black',
							}}
						>
							{
								prompts[currentLocale].text
									.completion_reference
							}
							: {reference}
						</Typography>
					) : null}
					{success ? (
						<Typography
							variant="body2"
							// component="p"
							sx={{
								color: colors['brp-black'].main,
								mt: 2,
								wordWrap: 'break-word',
								maxWidth: 300,
								textAlign: 'center',
							}}
						>
							{prompts[currentLocale].text.completion}
						</Typography>
					) : null}
				</Grid>
				<Grid
					container
					justifyContent="center"
					marginTop={2}
				>
					<Button
						onClick={navigateToHome}
						variant="brp-black"
						sx={{ textTransform: 'inherit', marginRight: 2 }}
					>
						{prompts[currentLocale].shop.back_to_home}
					</Button>
					{userIsLoggedIn
						? <Button
							onClick={navigateToAccount}
							variant="brp-yellow"
							sx={{ textTransform: 'inherit' }}
						>
							{prompts[currentLocale].navigation.account}
						</Button>
						: <Button
							onClick={navigateToLogin}
							variant="brp-yellow"
							sx={{ textTransform: 'inherit' }}
						>
							{prompts[currentLocale].navigation.login}
						</Button>
					}
				</Grid>
				{/* bottom */}
				<YouMayLike category={null} />
			</Grid>
			<CanAmSubscription />
		</>
	);
};
