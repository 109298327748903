import {
	createListenerMiddleware,
	createSelector,
	createSlice,
	current,
} from '@reduxjs/toolkit';
import {
	Category,
	Product,
	ProductAttribute,
	ProductState,
	ProductSummary,
	RootState,
	VehicleDetails,
} from '../types';
import {
	fetchProducts,
	fetchFilteredProducts,
	fetchMostPopularProducts,
	fetchSpecificProducts,
	search,
	fetchProduct,
} from '../thunks';
import brpWorld from '../../assets/brp_world.png';
import brpWorld_thumbnail from '../../assets/brp.png';
import {
	extendProductWithFormattedPrices,
	formatPriceToForint,
} from '../../helpers';

// import { ProductState } from '../types';
// todo: add ProductState instaed of any to types like above
const initialState: ProductState = {
	currentProduct: {
		isBeingFetched: false,
		details: {},
		variantSelected: undefined,
	},
	items: [],
	displayable: undefined,
	attributeValues: [],
	filtersApplied: {
		price: [],
		colour: [],
		attribute: [],
		categories: [],
		// this is how these fields
		// are named in the backend
		active_discount: undefined,
		inStock: undefined,
	},
	mostPopular: undefined,
	mostPopularProducts: undefined,
	sortApplied: {
		type: 'name',
		order: 'ASC',
		label: 'Alphabetic: A-Z',
	},
	status: '',
	pagination: undefined,
	defaultPagination: undefined,
	pageSize: 12,
	pageNumber: 1,
};

export const productListenerMiddleware =
	createListenerMiddleware();

export const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		updatePageSize: (
			state,
			{ payload }: { payload: 12 | 24 | 36 },
		) => {
			state.pageSize = payload;
		},
		updatePageNumber: (
			state,
			{ payload }: { payload: number },
		) => {
			state.pageNumber = payload;
		},
		updateDisplayableProducts: (state, { payload }) => {
			// selectedItems? is it category ? be more specific
			if (payload === undefined) {
				state.displayable = state.items;
				state.pagination = state.defaultPagination;
			} else {
				const { selectedItems, lastSelected } = payload;
				// console.log('lastSelected: ', lastSelected);
				// console.log('selectedItems: ', selectedItems);
				const allProducts = getAllProductsFromCategories([
					lastSelected,
				]);
				state.displayable = [];
				state.displayable = allProducts ?? [
					...selectedItems.products,
				]; // is this true?
				// state.displayable = [...selectedItems.products];
			}
		},
		updateDisplayableVehicles: (state, { payload }) => {
			// selectedItems? is it category ? be more specific
			if (payload === undefined) {
				state.displayable = state.items;
				state.pagination = state.defaultPagination;
			} else {
				const { selectedItems, lastSelected } = payload;
				const allProducts = getAllProductsFromCategories([
					lastSelected,
				]);
				state.displayable = [];
				state.displayable = allProducts ?? [
					...selectedItems.products,
				]; // is this true?
				// state.displayable = [...selectedItems.products];
			}
		},
		getGalleryImages: (state, action) => {
			const { images } = action.payload;
			if (images.length === 0) {
				return [];
			} else {
				return images.map((image) => ({
					description: image.desc,
					original: image,
				}));
			}
		},
		applyPriceRange: (productState, action) => {
			const [min, max] = action.payload;
			const maxPossibleValue = Math.max(
				...getItemPrices(productState),
			);
			const minPossibleValue = Math.min(
				...getItemPrices(productState),
			);

			productState.filtersApplied.price = [
				min < minPossibleValue ? minPossibleValue : min,
				max > maxPossibleValue ? maxPossibleValue : max,
			];
		},
		// might be better in shopSlice
		togglePriceRange: (state, action) => {
			const { value } = action.payload;
			const { price } = state.filtersApplied;
			const index = price.indexOf(value);
			if (index === -1) {
				state.filtersApplied.price = [...price, value];
			} else {
				state.filtersApplied.price = price.filter(
					(range) => range !== value,
				);
			}
		},
		toggleSale: (state) => {
			if (state.filtersApplied.active_discount) {
				state.filtersApplied.active_discount = undefined;
			} else {
				state.filtersApplied.active_discount = true;
			}
		},
		toggleInStock: (state) => {
			if (state.filtersApplied.inStock) {
				state.filtersApplied.inStock = undefined;
			} else {
				state.filtersApplied.inStock = true;
			}
		},
		toggleCategory: (state, action) => {
			const {
				categoryIds: categoriesToFetch,
				onlyReceivedCategories = false,
			} = action.payload;
			const { categories } = state.filtersApplied;
			if (onlyReceivedCategories) {
				state.filtersApplied.categories = categoriesToFetch;
			} else {
				for (const categoryIdToFetch of categoriesToFetch) {
					const index = categories.indexOf(
						categoryIdToFetch,
					);
					if (index === -1) {
						state.filtersApplied.categories = [
							...categories,
							categoryIdToFetch,
						];
					} else {
						state.filtersApplied.categories =
							categories.filter(
								(category) =>
									category !== categoryIdToFetch,
							);
					}
				}
			}
		},
		toggleAttribute: (state, action) => {
			const { value } = action.payload;
			const { attribute } = state.filtersApplied;
			const index = attribute.indexOf(value);
			if (index === -1) {
				state.filtersApplied.attribute = [
					...attribute,
					value,
				];
			} else {
				state.filtersApplied.attribute = attribute.filter(
					(attr) => attr !== value,
				);
			}
		},
		applySort: (state, { payload }) => {
			state.sortApplied = payload;
		},
		resetFiltersApplied: (state) => {
			state.filtersApplied = initialState.filtersApplied;
			state.displayable = state.items;
		},
		resetFilter: (state) => {
			state.filtersApplied = initialState.filtersApplied;
			state.displayable = state.items;
			state.sortApplied = {
				type: 'name',
				order: 'ASC',
			};
		},
		getUniqueAttributeValues: (state) => {
			// todo: is it items?
			const items =
				state.items.length > 0
					? state.items
					: state.displayable;
			if (items && items.length > 0) {
				let productAttributes = items.map(
					(product: Product) => product.attributes,
				);
				const flattenedProductAttributes =
					productAttributes.flat();
				if (flattenedProductAttributes) {
					let uniqueAttributeValues =
						flattenedProductAttributes.filter(
							(attribute, index, self) =>
								index ===
								self.findIndex(
									(currentAtt) =>
										currentAtt.id === attribute.id,
								),
						);
					state.attributeValues = uniqueAttributeValues;
				}
			}
		},
		updateProductFetchStatus: (
			state,
			{
				payload,
			}: { payload: 'loading' | 'failed' | 'fulfilled' },
		) => {
			state.status = payload;
		},
		updateCurrentProductBasedOnVariant: (
			state,
			{
				payload,
			}: {
				payload: {
					locale: string;
					size: string;
					colour: string;
					items: any[];
				};
			},
		) => {
			const { locale, size, colour, items } = payload;

			const productToSwitchTo = items.find((item) => {
				if (size && (!item.size || item.size !== size)) {
					return false;
				}

				if (colour) {
					if (
						!item.colour ||
						!item.colour[locale] ||
						item.colour[locale] !== colour
					) {
						return false;
					}
				}

				return true;
			});
			state.currentProduct.variantSelected =
				productToSwitchTo ?? { id: -1 };
		},
		updateCurrentProduct: (state, { payload }) => {
			state.currentProduct.details = payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchProduct.pending, (state, action) => {
				state.currentProduct = {
					isBeingFetched: true,
					details: {},
					variantSelected: undefined,
				};
			})
			.addCase(fetchProduct.fulfilled, (state, action) => {
				// todo: show success
				const product = extendProductWithFormattedPrices(
					action.payload,
				);
				const productIsInState = state.items.some(
					(item) => item.id === product.id,
				);
				if (!productIsInState) {
					state.items = [...state.items, product];
				}
				state.currentProduct = {
					isBeingFetched: false,
					variantSelected: undefined,
					details: {
						...product,
					},
				};
			})
			.addCase(fetchProduct.rejected, (state) => {
				state.currentProduct = {
					isBeingFetched: false,
					details: {},
					variantSelected: undefined,
				};
			})
			.addCase(fetchProducts.pending, (state) => {
				// ...state,
				state.status = 'loading';
				// state.items = [];
				// state.pagination = undefined;
			})
			.addCase(fetchProducts.fulfilled, (state, action) => {
				state.status = 'fulfilled';
				// this will only store the data itself, no pagination links

				const products =
					action.payload.data.length > 0
						? action.payload.data.map((product) =>
								extendProductWithFormattedPrices(product),
						  )
						: [];
				if (state.items.length > 0) {
					const productIdsInState = state.items.map(
						(item) => item.id,
					);
					const newProductsToAdd = products.filter(
						(product) =>
							!productIdsInState.includes(product.id),
					);
					state.items = [
						...state.items,
						...newProductsToAdd,
					];
				} else {
					state.items = products;
				}
				const productsToAdd = products.filter(
					(product) =>
						!checkIfProductIsAlreadyInItems(
							state,
							product.id,
						),
				);
				state.items = [...state.items, ...productsToAdd];
				state.displayable = products;
				state.pagination = action.payload.meta;
				state.defaultPagination = action.payload.meta;
			})
			.addCase(fetchProducts.rejected, (state) => {
				state.status = 'failed';
				state.items = [];
				state.pagination = undefined;
				// state.products.error = action.error.message;
			})
			.addCase(fetchFilteredProducts.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(fetchFilteredProducts.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(
				fetchFilteredProducts.fulfilled,
				(state, { payload }) => {
					try {
						const products = Array.isArray(payload?.data)
							? payload.data
									.map((product) =>
										product
											? extendProductWithFormattedPrices(
													product,
											  )
											: null,
									)
									.filter(Boolean)
							: [];

						const productsToAdd = products.filter(
							(product) =>
								product &&
								!checkIfProductIsAlreadyInItems(
									state,
									product.id,
								),
						);

						state.items = [
							...(state.items || []),
							...productsToAdd,
						];
						state.displayable = products;
						state.pagination = payload?.meta || {};
						state.status = 'fulfilled';
					} catch (error) {
						console.error(
							'Error processing fetched products:',
							error,
						);
						state.status = 'failed';
					}
				},
			)
			.addCase(search.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(search.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(search.fulfilled, (state, { payload }) => {
				const products =
					payload.data.length > 0
						? payload.data.map((product) =>
								extendProductWithFormattedPrices(product),
						  )
						: [];
				state.displayable = products;
				state.pagination = payload.meta;
				state.status = 'fulfilled';
			})
			.addCase(
				fetchMostPopularProducts.fulfilled,
				(state, { payload }) => {
					if (payload.data && payload.data.length === 1) {
						const mostPopular = payload.data.map(
							(product) =>
								extendProductWithFormattedPrices(product),
						);
						state.mostPopular = mostPopular;
						state.mostPopularProducts = mostPopular;
					} else {
						const mostPopularProducts =
							payload.data.length > 0
								? payload.data.map((product) =>
										extendProductWithFormattedPrices(
											product,
										),
								  )
								: [];
						state.mostPopularProducts = mostPopularProducts;
					}
					state.status = 'fulfilled';
				},
			)
			.addCase(
				fetchMostPopularProducts.rejected,
				(state) => {
					state.status = 'failed';
				},
			)
			.addCase(
				fetchMostPopularProducts.pending,
				(state) => {
					state.status = 'loading';
				},
			)
			.addCase(
				// todo: this is error prone that in some cases,
				// ie in new page size, the individually fetched products
				// will be removed
				fetchSpecificProducts.fulfilled,
				(state, { payload }) => {
					// todo: rethink this, what do we wanna do in this case
					const products =
						payload.data.length > 0
							? payload.data.map((product) =>
									extendProductWithFormattedPrices(product),
							  )
							: [];
					if (state.items.length > 0) {
						const productIdsInState = state.items.map(
							(item) => item.id,
						);
						const newProductsToAdd = products.filter(
							(product) =>
								!productIdsInState.includes(product.id),
						);
						state.items = [
							...state.items,
							...newProductsToAdd,
						];
					} else {
						state.items = products;
					}
				},
			);
	},
});

const checkIfProductIsAlreadyInItems = (
	state: ProductState,
	productId: number,
) => {
	return state.items.some((item) => item.id === productId);
};

const getAllProductsFromCategories = (
	categories: Category[],
): Product[] => {
	let allProducts: Product[] = [];
	for (const category of categories) {
		// console.log(category);
		allProducts = allProducts.concat(category.products);

		if (category.children?.length > 0) {
			const subCategoryProducts =
				getAllProductsFromCategories(category.children);
			allProducts = allProducts.concat(subCategoryProducts);
		}
	}

	return allProducts;
};
export const getAllCategoryIdForParent = (
	categories: Category[],
): number[] => {
	let allCategoryIds: number[] = [];
	for (const category of categories) {
		allCategoryIds = allCategoryIds.concat(category.id);

		if (category.children?.length > 0) {
			const subCategory = getAllCategoryIdForParent(
				category.children,
			);
			allCategoryIds = allCategoryIds.concat(subCategory);
		}
	}

	return allCategoryIds;
};

const sortSelector = (state: RootState) => {
	return state.products.sortApplied;
};
const allAppliedFiltersSelector = (state: RootState) => {
	const { filtersApplied, pageNumber, pageSize } =
		state.products;
	// return {filters: filters}
};
export const getAllAppliedFilters = createSelector(
	[sortSelector],
	(sort) => sort,
);
export const selectedSort = createSelector(
	[sortSelector],
	(sort) => sort,
);

const getItemPrices = (state: RootState | ProductState) => {
	const products =
		'products' in state ? state.products : state;
	const items = products.items || [];
	return items.map(
		(product: Product) => product.current_price,
	);
};

export const mostExpensiveItemSelector = createSelector(
	[getItemPrices],
	(items) => (items.length > 0 ? Math.max(...items) : 0),
);

export const leastExpensiveItemSelector = createSelector(
	[getItemPrices],
	(items) => (items.length > 0 ? Math.min(...items) : 0),
);

const getPriceFiltersApplied = (state: RootState) => {
	return state.products.filtersApplied.price;
};
const getColourFiltersApplied = (state: RootState) => {
	return state.products.filtersApplied.colour;
};
const getFiltersApplied = (state: RootState) => {
	return state.products.filtersApplied;
};
const getSortApplied = (state: RootState) => {
	return state.products.sortApplied;
};
export const filtersAppliedSelector = createSelector(
	[getFiltersApplied],
	(filters) => filters,
);
export const getFiltersAndSortApplied = createSelector(
	[getFiltersApplied, getSortApplied],
	(filters, sort) => {
		// return [priceFilters, colourFilters];
		return {
			filters: {
				price: filters.price,
				colour: filters.colour,
				attribute: filters.attribute,
			},
			sort,
		};
	},
);

export const calculateDiscountPrice = (
	product: Product | ProductSummary,
) => {
	const { price, active_discount } = product;
	if (active_discount) {
		const { discount_flat, discount_percentage } =
			active_discount;
		if (discount_flat) {
			const unformatted = price - discount_flat;
			return formatPriceToForint(unformatted);
		} else if (discount_percentage) {
			const discountedValue =
				price - price * discount_percentage;
			const unformatted = Math.floor(discountedValue);
			return formatPriceToForint(unformatted);
		} else {
			// shouldn't happen, maybe throw?
			return formatPriceToForint(price);
		}
	}
	return formatPriceToForint(price);
};

export const getProduct = (
	state: RootState,
	productId: number,
) => {
	const { items: products } = state.products;

	return products.find(
		(product) => product.id === productId,
	);
};

const productSelector = (
	state: RootState,
	productId?: number,
) => {
	const { items: products } = state.products;

	return products.find(
		(product) => product.id === productId,
	);
};
export const checkIfProductCanBeAddedToCartSelector = (
	state: RootState,
	productId: number,
	quantityToAdd?: number,
) => {
	const { items, displayable: products } = state.products;
	const productsToUse = products
		? [...products, ...items]
		: items;
	const product = productsToUse
		? productsToUse.find(
				(productItem) => productItem.id === productId,
		  )
		: undefined;
	if (product) {
		const availableStock = product?.available_stock ?? 0;
		const productQuantityInCart =
			state.cart.items.find(
				(cartItem) => cartItem.id === productId,
			)?.quantity ?? 0;
		const quantityToBeAdded =
			quantityToAdd ??
			state.cart.updatedQuantities.find(
				(product) => product.id === productId,
			)?.quantity ??
			1;
		// note: there is an edge case here, if they are
		// updating the quantity in the cart popover, and are also on the
		// product's individual page, and try to add it back in from there
		// the itemToBeAdded quantity will be the same as the items reduced/increased
		// in the cart. which may cause the 'add to cart' incorrectly not add
		// further items to the cart

		// note: the quantity to be added needs to be reviewed for better
		// handling, but works fine for now
		return (
			availableStock -
				productQuantityInCart -
				quantityToBeAdded >=
			0
		);
	}
	return false;
};
export const canAddToCart = createSelector(
	[checkIfProductCanBeAddedToCartSelector],
	(canBeAdded) => canBeAdded,
);

export const selectProduct = createSelector(
	[productSelector],
	(product) => product,
);

export const getImagesForProduct = (
	product: Product | VehicleDetails | undefined,
) => {
	const hasImages =
		product?.images && product.images.length > 0;

	if (hasImages) {
		return (
			product.images?.map((image) => ({
				// description: image.desc,
				original: image.src,
				thumbnail: image.src,
				originalWidth: '100%',
				originalHeight: '300px',
			})) || []
		);
	}

	return [
		{
			original: brpWorld,
			thumbnail: brpWorld_thumbnail,
			originalWidth: '100%',
			originalHeight: '300px',
		},
	];
};

export const selectProductImages = createSelector(
	[productSelector],
	(product) => {
		if (product && product?.images) {
			return product?.images.map((image) => ({
				// description: image.desc,
				original: image.src,
				thumbnail: image.src,
				originalWidth: '100%',
				originalHeight: '300px',
			}));
		}
		return [];
	},
);

const productsSelector = (state: RootState) =>
	state.products;

const currentProductSelector = (state: RootState) =>
	state.products.currentProduct;
const currentVehicleSelector = (state: RootState) =>
	state.generic.vehicles;

export const selectProducts = createSelector(
	[productsSelector],
	(products) => {
		return products?.displayable ?? products?.items;
	},
);
export const selectCurrentProduct = createSelector(
	[currentProductSelector],
	(product) => product,
);
export const selectCurrentVehicle = createSelector(
	[currentVehicleSelector],
	(vehicle) => vehicle,
);

export const {
	toggleCategory,
	applyPriceRange,
	toggleAttribute,
	getUniqueAttributeValues,
	applySort,
	togglePriceRange,
	getGalleryImages,
	resetFilter,
	updatePageSize,
	updatePageNumber,
	toggleSale,
	toggleInStock,
	updateDisplayableProducts,
	updateProductFetchStatus,
	updateCurrentProduct,
	resetFiltersApplied,
	updateCurrentProductBasedOnVariant,
} = productSlice.actions;

export const productReducer = productSlice.reducer;
