import {
	Box,
	Button,
	Container,
	Grid,
	List,
	ListItem,
	Tab,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import {
	RootState,
	store,
	selectCurrentVehicle,
	getImagesForProduct as getImagesForVehicle,
	VehicleDetails,
	fetchVehicle,
	updateVehicleReuqestFormVisibility,
	updateVehicleForQuote,
} from '../../../redux';
import { colors } from '../../../themes';
import {
	AddToCart,
	Gallery,
	Heading,
	ShopNow,
	QuantityButtons,
	ProductSkeleton,
	ProductCard,
} from '../../../components';
import {
	Link,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import {
	faAngleLeft,
	faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Sort, YouMayLike } from '../components';
import { parseHtml } from '../../helpers';
import {
	formatPriceToEuro,
	useTranslation,
} from '../../../helpers';

export const Vehicle = () => {
	const isFetching = useSelector(
		(state: RootState) =>
			state.generic.vehicles.selectedVehicle === null,
	);
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const { state } = useLocation();
	const currentVehicle: VehicleDetails | undefined | null =
		useSelector(selectCurrentVehicle).selectedVehicle;

	const params = useParams();
	const { id: urlId } = params;
	const id = state?.productId || urlId;

	useEffect(() => {
		store.dispatch(
			fetchVehicle(parseInt(id) ?? currentVehicle?.id),
		);
	}, [id]);

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const translations = useTranslation();

	const galleryImages = !!currentVehicle
		? getImagesForVehicle(currentVehicle)
		: [];

	return isFetching ? (
		<ProductSkeleton />
	) : currentVehicle &&
	  Object.keys(currentVehicle)?.length > 0 ? (
		<Container>
			<Grid
				container
				style={{
					width: '100%',
					//  padding: 0, margin: 0
				}}
				sx={{ marginTop: { xs: 2, md: 0 } }}
			>
				<section
					onClick={() =>
						navigate(
							`/shop/vehicles?category=${currentVehicle.category_id}`,
						)
					}
					style={{
						width: 'max-content',
						paddingLeft: 6,
						display: 'flex',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<FontAwesomeIcon
						icon={faAngleLeft}
						size="1x"
						color={colors['brp-black'].main}
					/>
					<Typography
						gutterBottom
						variant="h6"
						component="p"
						sx={{
							margin: 0,
							marginLeft: '4px',
							marginBottom: '-5px',
						}}
					>
						{translations.buttons.back}
					</Typography>
				</section>
			</Grid>
			<Grid
				container
				maxWidth="lg"
				spacing={1}
				sx={{
					display: 'flex',
					padding: { md: 6 },
					justifyContent: 'space-between',
				}}
			>
				<Grid
					item
					xs={12}
					md={7}
					sx={{
						minHeight: '30vh',
						height: 'max-content',
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Gallery
						items={galleryImages}
						showPlayButton={false}
						showFullscreenButton={false}
						thumbnailPosition={
							isSmallScreen ? 'bottom' : 'left'
						}
					/>
				</Grid>
				<Grid
					xs={12}
					md={5}
					item
					sx={{
						height: 'max-content',
						// padding: 2,
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Grid container sx={{ flexDirection: 'column' }}>
						<Typography component="h2" variant="h4">
							{currentVehicle.year} {currentVehicle.name}
						</Typography>
						{currentVehicle?.price ? (
							<Typography
								variant="h5"
								component="h3"
								style={{ marginBottom: '16px' }}
							>
								{currentLocale === 'en' ? 'From ' : null}
								{formatPriceToEuro(currentVehicle.price)}
								{currentLocale !== 'en'
									? ' + Áfától'
									: ' + VAT'}
							</Typography>
						) : null}
						<Grid container className="description">
							{parseHtml(currentVehicle.description)}
						</Grid>
						<Container
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'space-between',
								padding: 0,
								marginTop: 16,
								columnGap: '8px',
							}}
						>
							{currentVehicle?.spec?.url ? (
								<Link
									to={currentVehicle.spec.url}
									target="_blank"
									rel="noopener noreferrer"
									// style={{
									// 	display: 'flex',
									// 	alignItems: 'center',
									// 	marginLeft: 'auto',
									// }}
									style={{
										width: isSmallScreen ? '100%' : '45%',
										marginLeft: 0,
										marginTop: 8,
										minWidth: 120,
									}}
								>
									<Button
										variant="brp-white"
										size="small"
										style={{ width: '100%' }}
										// to={currentVehicle.spec.url}
										// target="_blank"
										// rel="noopener noreferrer"
									>
										{translations.text.specification}
										{/*<span>
									</span>
									 <FontAwesomeIcon
										style={{ marginLeft: 4 }}
										icon={faExternalLinkAlt}
									/> */}
									</Button>
								</Link>
							) : null}
							{currentVehicle.external_link ? (
								<Link
									to={currentVehicle.external_link}
									target="_blank"
									rel="noopener noreferrer"
									style={{
										width: isSmallScreen ? '100%' : '45%',
									}}
								>
									<Button
										variant="brp-yellow"
										style={{
											width: '100%',
											marginLeft: 0,
											marginTop: 8,
											minWidth: 120,
										}}
									>
										{translations.text.customise_vehicle}
									</Button>
								</Link>
							) : null}
						</Container>
					</Grid>
				</Grid>
			</Grid>
			<YouMayLike
				category={currentVehicle.recommend_products_from_id}
			/>
		</Container>
	) : (
		<Grid
			container
			flexDirection="column"
			alignItems="center"
			paddingTop={2}
			paddingBottom={4}
		>
			<Typography
				variant="h5"
				component="p"
				sx={{
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: '16px',
					position: 'relative',
					width: 'max-content',
				}}
			>
				{translations.shop.product_not_found}
			</Typography>
			<Button
				variant="brp-yellow"
				onClick={() => navigate('/shop')}
			>
				{translations.shop.back_to_shop}
			</Button>
		</Grid>
	);
};
