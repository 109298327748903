import {
	Grid,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	Category,
	fetchFilteredVehicles,
	fetchVehicleCategories,
	handleViewChange,
	RootState,
	store,
} from '../../../redux';
import styles from '../shop.module.scss';
import {
	doScrollToTop,
	useTranslation,
} from '../../../helpers';
import {
	NoItem,
	ProductsSkeleton,
	VehicleCard,
	VehicleCards,
	VehiclePagination,
} from '../../../components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const VehicleSection = ({ isLoading }) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const translations = useTranslation();
	const vehicles = useSelector(
		(state: RootState) => state.generic.vehicles,
	);
	const selectedVehicleCategory = vehicles.selectedCategory;
	const currentVehicleCategoryHasChildren =
		selectedVehicleCategory?.children &&
		selectedVehicleCategory.children.length > 0;

	const parentCategories = vehicles.parentCategories;
	const categoryHasChildren =
		currentVehicleCategoryHasChildren ??
		(parentCategories
			? parentCategories.some(
					(categ) =>
						categ.id === selectedVehicleCategory?.id &&
						categ.children.length > 0,
			  )
			: false);

	const areVehiclesBeingFetched = vehicles.isBeingFetched;
	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);

	const ShowCategoryCards = () => {
		const cards = selectedVehicleCategory.children.map(
			(obj: Category) => (
				<Grid
					key={obj.id}
					xs={12}
					md={5}
					onClick={() => {
						doScrollToTop();
						navigate(`/shop/vehicles?category=${obj.id}`);
					}}
				>
					<VehicleCards key={obj.id} payload={obj} />
				</Grid>
			),
		);
		return (
			<Grid
				container
				sx={{
					padding: { xs: 2, md: 0 },
					justifyContent: 'space-between',
				}}
			>
				{cards}
			</Grid>
		);
	};

	useEffect(() => {
		store.dispatch(handleViewChange('grid'));
		if (!parentCategories) {
			store.dispatch(fetchVehicleCategories());
		}
	}, []);
	const displayVehicles = () => {
		if (
			!vehicles?.displayable ||
			vehicles.displayable.length === 0
		) {
			return <NoItem translations={translations} />;
		}

		return vehicles.displayable.map((vehicle) => (
			<Grid
				item
				xs={6}
				xl={isGridView ? 3 : 12}
				lg={isGridView ? 3 : 12}
				md={isGridView ? 4 : 12}
				key={vehicle.id}
			>
				<VehicleCard vehicle={vehicle} />
			</Grid>
		));
	};
	useEffect(() => {
		if (
			!areVehiclesBeingFetched &&
			!categoryHasChildren &&
			selectedVehicleCategory?.id
		) {
			store.dispatch(
				fetchFilteredVehicles({
					sort: store.getState().generic.vehicles
						.sortApplied,
					filters:
						store.getState().generic.vehicles
							.filtersApplied,
					categoryOverride: selectedVehicleCategory.id,
				}),
			);
		}
	}, [selectedVehicleCategory]);

	return (
		<>
			<Grid
				item
				xs={12}
				className={styles.filterContainer}
				sx={{
					minHeight: '30vh',
					height: 'max-content',
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					position: isSmallScreen ? 'relative' : 'sticky',
					top: 0,
					boxSizing: 'border-box',
					padding: isSmallScreen ? 2 : 1,
					overflowY: isSmallScreen ? 'visible' : 'auto',
				}}
				flexBasis={
					isSmallScreen ? 'auto' : '30% !important'
				}
			>
				{isLoading || areVehiclesBeingFetched ? (
					<ProductsSkeleton />
				) : categoryHasChildren ? (
					<ShowCategoryCards />
				) : (
					<>
						<Grid container className="vehicleContainer">
							{displayVehicles()}
						</Grid>
						{/* Show pagination if there are vehicles & we are not showing category tiles*/}
						{vehicles?.displayable &&
							vehicles?.displayable.length > 0 && (
								<VehiclePagination />
							)}
					</>
				)}
			</Grid>
		</>
	);
};
