import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	TextField,
	Typography,
	Container,
	Grid,
} from '@mui/material';
import {
	RootState,
	forgottenPassword,
	store,
} from '../../redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../themes';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from '../../helpers';

export const ForgottenPassword: React.FC = () => {
	const translations = useTranslation();
	const validationSchema = Yup.object({
		email: Yup.string()
			.email(translations.validation.generic.email_invalid)
			.required(translations.validation.generic.email),
	});
	const status = useSelector(
		(state: RootState) => state.auth.passwordResetResponse,
	);
	const initialValues = {
		email: '',
	};
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			await store.dispatch(
				forgottenPassword({ email: values.email }),
			);
			resetForm();
		},
	});

	return (
		<Grid
			container
			maxWidth="lg"
			sx={{ margin: '0 auto' }}
			flexDirection="column"
		>
			<Link
				to="/login"
				style={{
					width: 'max-content',
					paddingLeft: 6,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<FontAwesomeIcon
					icon={faAngleLeft}
					size="1x"
					color={colors['brp-black'].main}
				/>
				<Typography
					gutterBottom
					variant="h6"
					component="p"
					sx={{
						margin: 0,
						marginLeft: '4px',
						marginBottom: '-5px',
					}}
				>
					{translations.navigation.login}
				</Typography>
			</Link>

			<Container sx={{ my: 8 }} maxWidth="xs">
				<Typography
					component="h1"
					sx={{ mb: 1 }}
					variant="h5"
				>
					{translations.forms.forgot_password}
				</Typography>
				<form onSubmit={formik.handleSubmit}>
					<TextField
						fullWidth
						id="email"
						name="email"
						label={translations.forms.email}
						placeholder={translations.placeholder.email}
						sx={{ mb: 2 }}
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={
							formik.touched.email &&
							Boolean(formik.errors.email)
						}
						helperText={
							formik.touched.email && formik.errors.email
						}
					/>
					<LoadingButton
						type="submit"
						fullWidth
						variant="brp-yellow"
						loading={status === 'loading'}
					>
						{translations.text.request_new_pass}
					</LoadingButton>
				</form>
			</Container>
		</Grid>
	);
};
