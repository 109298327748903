import {
	Button,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { colors } from '../../../themes';
import { useNavigate } from 'react-router-dom';

export const CheckoutDisabled = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	const navigateHome = () => {
		navigate('/');
	};
	return (
		<Grid
			container
			sx={{
				minHeight: '40vh',
				background: colors['brp-white'].main,
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				textAlign: isSmallScreen ? 'center' : 'left',
			}}
		>
			<Typography
				variant="h6"
				component="p"
				sx={{
					color: colors['brp-black'].main,
				}}
			>
				{translations.shop.checkout_disabled_1}
			</Typography>
			<Typography
				variant="h6"
				component="p"
				sx={{
					color: colors['brp-black'].main,
					marginBottom: '16px',
				}}
			>
				{translations.shop.checkout_disabled_2}
			</Typography>
			<Button variant="brp-yellow" onClick={navigateHome}>
				{translations.navigation.home}
			</Button>
		</Grid>
	);
};
