import { useState } from 'react';
import {
	LockOutlined,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import {
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import { useTranslation } from '../../helpers';

export const ToggleablePasswordField = ({
	placeholder,
	...props
}) => {
	const { field, form, sx, inputProps } = props;
	const { name, value } = field;
	const { setFieldValue } = form;
	const [showPassword, setShowPassword] = useState(false);
	const translations = useTranslation();
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const placeholderToUse =
		name === 'current_password' || name === 'password'
			? translations.placeholder.password
			: name === 'password_confirmation'
			  ? translations.placeholder.password_new_confirmation
			  : translations.placeholder.password_new;

	return (
		<TextField
			sx={{ ...sx }}
			inputProps={{ ...inputProps }}
			variant="outlined"
			required
			type={showPassword ? 'text' : 'password'}
			name={name}
			value={value}
			onChange={(e) => {
				setFieldValue(name, e.target.value);
				props.onChange(e.target.value);
			}}
			fullWidth
			label={props?.label ?? translations.forms.password}
			placeholder={props?.placeholder ?? placeholderToUse}
			InputProps={{
				startAdornment:
					props?.hideStartIcons ? undefined : (
						<InputAdornment
							sx={{ pointerEvents: 'none' }}
							position="start"
						>
							<IconButton
								tabIndex={-1}
								aria-label="Password field"
							>
								<LockOutlined />
							</IconButton>
						</InputAdornment>
					),
				endAdornment: props?.hideEndIcons ? undefined : (
					<InputAdornment position="end">
						<IconButton
							tabIndex={-1}
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{showPassword ? (
								<Visibility />
							) : (
								<VisibilityOff />
							)}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
};
