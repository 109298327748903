import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
	TextField,
	Button,
	Typography,
	Grid,
	Container,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	RootState,
	passwordReset,
	store,
} from '../../redux';
import { useSelector } from 'react-redux';
import {
	Error,
	ToggleablePasswordField,
} from '../../components';
import { passwordResetValidationSchema } from '../../helpers';
import { LoadingButton } from '@mui/lab';

export const PasswordReset = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get('token');
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const validationSchema =
		passwordResetValidationSchema(translations);

	return (
		<Formik
			initialValues={{
				password: '',
				password_confirmation: '',
			}}
			onSubmit={async (values, { resetForm }) => {
				const { payload: response } = await store.dispatch(
					passwordReset({ ...values, token }),
				);
				if (!response.error) {
					resetForm();
					navigate('/login');
				}
			}}
			validationSchema={validationSchema}
		>
			{({ handleChange, isSubmitting }) =>
				!token ? (
					<Grid
						container
						sx={{
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
							paddingTop: 2,
						}}
					>
						<Typography variant="h5" component="h2">
							{
								translations.forms
									.password_reset_invalid_link
							}
						</Typography>
						<Button
							sx={{
								justifyContent: 'center',
								marginTop: 1,
							}}
							variant="brp-white"
							size="small"
							onClick={() =>
								navigate('/forgotten-password')
							}
						>
							{translations.forms.password_reset_again}
						</Button>
					</Grid>
				) : (
					<Container sx={{ paddingTop: 2 }} maxWidth="sm">
						<Form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h5">
										{
											translations.forms
												.password_reset_title
										}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Field
										inputProps={{ tabIndex: 1 }}
										as={TextField}
										component={ToggleablePasswordField}
										type="password"
										name="password"
										onChange={handleChange}
										fullWidth
										variant="outlined"
									/>
									<Error name="password" />
								</Grid>
								<Grid item xs={12}>
									<Field
										inputProps={{
											tabIndex: 2,
										}}
										as={TextField}
										component={ToggleablePasswordField}
										onChange={handleChange}
										type="password_confirmation"
										name="password_confirmation"
										fullWidth
										variant="outlined"
									/>
									<Error name="password_confirmation" />
								</Grid>
								<Grid item xs={12}>
									<LoadingButton
										style={{ textTransform: 'capitalize' }}
										type="submit"
										loading={isSubmitting}
										variant="brp-yellow"
									>
										<span>
											{translations.forms.password_reset}
										</span>
									</LoadingButton>
								</Grid>
							</Grid>
						</Form>
					</Container>
				)
			}
		</Formik>
	);
};
