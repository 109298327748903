import {
	AccordionProps,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { Delete, Error } from '../../components';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useEffect, useState } from 'react';
import { CountryDropdown, DeliveryDetails } from '../shop';
import {
	DeliveryDetails as DeliveryDetailsType,
	RootState,
	createUserShippingAddress,
	fetchCountries,
	selectAccountDetails,
	store,
	updateUserShippingAddress,
} from '../../redux';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
}));

const AccordionSummary = styled(
	(props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			expandIcon={
				<ArrowForwardIosSharpIcon
					sx={{ fontSize: '0.9rem', pointerEvents: 'auto' }}
				/>
			}
			{...props}
		/>
	),
)(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		justifyContent: 'space-between',
		alignItems: 'center',
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(
	({ theme }) => ({
		padding: theme.spacing(2),
		borderTop: '1px solid rgba(0, 0, 0, .125)',
	}),
);

const DeliveryAddressToShow = ({
	isNew = false,
	address,
	isSmallScreen,
	expanded,
	translations,
	countries,
	index,
	handleChange,
	setShowAddNew,
}) => {
	const formik = useFormik({
		initialValues: {
			shipping: address,
		},
		onSubmit: async (values) => {
			const { shipping } = values;
			if (isNew) {
				await store.dispatch(
					createUserShippingAddress({
						...shipping,
						country: shipping.country.id,
					}),
				);
			} else {
				await store.dispatch(
					updateUserShippingAddress({
						id: shipping.id,
						shipping: {
							...shipping,
							country: shipping.country_id,
						},
					}),
				);
			}
			setShowAddNew(false);
		},
	});
	const addressSummary =
		formik.values.shipping?.street &&
		formik.values.shipping?.city
			? `${formik.values.shipping.city}, ${formik.values.shipping.street}`
			: translations.account.delivery.new_address;
	return (
		<Accordion
			style={{ marginTop: '16px' }}
			expanded={isNew || expanded === `panel${index + 1}`}
			onChange={handleChange(`panel${index + 1}`)}
		>
			<AccordionSummary
				sx={{ pointerEvents: 'none' }}
				aria-controls={`panel${index + 1}-content`}
				id={`panel${index + 1}-header`}
			>
				<Typography>{addressSummary}</Typography>
				<Delete
					style={{
						padding: '0 4px',
						pointerEvents: 'auto',
					}}
					productId={address.id}
					deletionType="ADDRESS"
					text={translations.shop.delete}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<form onSubmit={formik.handleSubmit}>
					<Grid container>
						{/* <RenderErrors errors={formik.errors} /> */}
						<Grid
							container
							columnGap={2}
							flexDirection={
								isSmallScreen ? 'column' : 'row'
							}
						>
							<TextField
								style={{
									marginBottom: 16,
									flex: 1,
								}}
								name="shipping.street"
								fullWidth
								value={formik.values.shipping.street}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								required
								label={translations.forms.street}
								placeholder={
									translations.placeholder.street
								}
							/>
							{/* <Error name={`shipping.street`} /> */}
							<TextField
								style={{
									marginBottom: 16,
									flex: 1,
								}}
								name="shipping.street_extra"
								value={formik.values.shipping.street_extra}
								fullWidth
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								label={translations.forms.street_extra}
								placeholder={
									translations.placeholder.street_extra
								}
							/>
							{/* <Error name={`shipping.street_extra`} /> */}
						</Grid>
						<Grid
							container
							columnGap={2}
							flexDirection={
								isSmallScreen ? 'column' : 'row'
							}
						>
							<TextField
								style={{
									marginBottom: 16,
									flex: 1,
								}}
								name="shipping.city"
								value={formik.values.shipping.city}
								fullWidth
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								required
								label={translations.forms.city}
								placeholder={translations.placeholder.city}
							/>
							{/* <Error name={`shipping.city`} /> */}
							<TextField
								style={{
									marginBottom: 16,
									flex: 1,
								}}
								name="shipping.post_code"
								value={formik.values.shipping.post_code}
								fullWidth
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								required
								label={translations.forms.post_code}
								placeholder={
									translations.placeholder.post_code
								}
							/>
							{/* <Error name={`shipping.post_code`} /> */}
						</Grid>
						<Grid container columnGap={2}>
							<CountryDropdown
								style={{
									marginBottom: 16,
									flex: 1,
								}}
								countries={countries}
							/>
							{/* <Error name={`shipping.country`} /> */}
						</Grid>
					</Grid>
					<Button variant="brp-yellow" type="submit">
						{translations.buttons.save}
					</Button>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export const DeliveryAddresses = () => {
	const [showAddNew, setShowAddNew] = useState(false);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const { items: countries, isBeingFetched } = useSelector(
		(state: RootState) => state.checkout.countries,
	);
	useEffect(() => {
		if (!countries) {
			store.dispatch(fetchCountries());
		}
	}, []);

	const accountDetails = useSelector(selectAccountDetails);
	const deliveryAddresses =
		accountDetails.shipping_addresses;

	const [expanded, setExpanded] = useState<string | false>(
		'panel1',
	);

	const handleChange =
		(panel: string) =>
		(event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};
	const newAddress = {
		street: '',
		street_extra: '',
		city: '',
		post_code: '',
		state: '',
		phone_number: '',
		country: {
			id: 101,
			name: 'Hungary',
			code: 'HU',
		},
	};

	return (
		<Grid container flexDirection="column">
			<Typography component="h2" variant="h5">
				{prompts[currentLocale].account.delivery.title}
			</Typography>
			<Typography component="p" variant="body2">
				{
					prompts[currentLocale].account.delivery
						.description
				}
			</Typography>
			<Button
				onClick={() => setShowAddNew(true)}
				style={{
					width: 'max-content',
					marginTop: 16,
					marginBottom: 16,
				}}
				variant="brp-white"
				size="small"
				startIcon={
					<FontAwesomeIcon
						style={{ marginTop: '-5px' }}
						icon={faPlusSquare}
					/>
				}
			>
				{prompts[currentLocale].account.delivery.add_new}
			</Button>
			{showAddNew && (
				<DeliveryAddressToShow
					isNew={true}
					countries={countries}
					handleChange={() => setShowAddNew(true)}
					expanded={expanded}
					key="new-address"
					address={newAddress}
					index={deliveryAddresses.length}
					isSmallScreen={isSmallScreen}
					translations={prompts[currentLocale]}
					setShowAddNew={setShowAddNew}
				/>
			)}
			{isBeingFetched ? (
				<CircularProgress size={20} color="inherit" />
			) : (
				deliveryAddresses.map((address, index) => {
					return (
						<DeliveryAddressToShow
							setShowAddNew={setShowAddNew}
							countries={countries}
							handleChange={handleChange}
							expanded={expanded}
							key={index}
							address={address}
							index={index}
							isSmallScreen={isSmallScreen}
							translations={prompts[currentLocale]}
						/>
					);
				})
			)}
		</Grid>
	);
};
