// src/features/todoSlice.ts
import {
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import { fetchProducts, getAttributes } from '../thunks';
import { Attribute, RootState, ShopState } from '../types';

const initialState: ShopState = {
	selectedItems: [],
	products: [],
	attributes: {
		all: undefined,
		displayable: undefined,
	},
	isAttributeBeingFetched: false,
	isGridView: true,
	// selectedSort: undefined,
};

export const shopSlice = createSlice({
	name: 'shop',
	initialState,
	reducers: {
		handleProductAddition: (state, action) => {
			// get quantity for product
		},
		handleViewChange: (
			shopState,
			{ payload }: { payload: string },
		) => {
			shopState.isGridView = payload === 'grid';
		},
		toggleLoadingSkeleton: (state) => {
			if (state.isAttributeBeingFetched) {
				state.isAttributeBeingFetched = false;
			} else {
				state.isAttributeBeingFetched = true;
			}
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getAttributes.pending, (state) => {
				state.isAttributeBeingFetched = true;
			})
			.addCase(getAttributes.rejected, (state) => {
				state.isAttributeBeingFetched = false;
			})
			.addCase(
				getAttributes.fulfilled,
				(state, { payload }) => {
					state.isAttributeBeingFetched = false;

					if (payload && Array.isArray(payload.data)) {
						state.attributes.all = payload.data;

						state.attributes.displayable = payload.data.filter(
							(attribute: Attribute) => attribute && attribute.display
						);
					} else {
						state.attributes.all = [];
						state.attributes.displayable = [];
					}
				}
			);
	},
});

export const { handleViewChange, toggleLoadingSkeleton } =
	shopSlice.actions;

// Selector to get all todos
// export const selectTodos = (state: RootState) => state.todo.todos;

// export default shopSlice.reducer;
export const shopReducer = shopSlice.reducer;
