import {
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import grant1 from '../../assets/government-grant.png';
import pressRelease1 from '../../assets/press-release.png';
import grant2 from '../../assets/government-grant-2.jpeg';
import grant3 from '../../assets/government-grant-3.jpeg';
import pressRelease2 from '../../assets/press-release-2.jpeg';
import construction1 from '../../assets/construction-1.jpeg';
import construction2 from '../../assets/construction-2.jpeg';
import construction3 from '../../assets/construction-3.jpeg';
import vehicle1 from '../../assets/vehicle-1.jpeg';
import vehicle2 from '../../assets/vehicle-2.jpeg';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { parseHtml } from '../helpers';
import { colors } from '../../themes';

export const Grants = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	return (
		<Grid
			container
			maxWidth="lg"
			justifyContent="space-between"
			// sx={{
			//     margin: '0 auto 32px',
			// }}
			paddingX={3}
			paddingY={1}
			sx={{
				flexDirection: 'column',
				marginX: 'auto',
				marginBottom: { xs: 2, md: 3 },
				marginTop: isSmallScreen ? 2 : 0,
			}}
		>
			<Typography variant="h3" component="h1">
				{translations.grants.title}
			</Typography>
			<img src={grant3} style={{ width: '60%' }} />
			<Grid
				container
				sx={{
					flexDirection: 'column',
					width: 'max-content',
					textAlign: 'justify',
				}}
			>
				<Typography
					variant="h4"
					component="h2"
					sx={{ marginTop: 4 }}
				>
					{translations.grants.service_centre}
				</Typography>
				<ul
					style={{
						listStyleType: 'none',
						maxWidth: '900px',
						lineHeight: 1.5,
					}}
				>
					<li>{translations.grants.recipient_name}</li>
					<li>{translations.grants.project_identifier}</li>
					<li>{translations.grants.call_title}</li>
					<li>{translations.grants.call_code}</li>
					<li>{translations.grants.project_title}</li>
					<li>
						{translations.grants.contracted_support_amount}
					</li>
					<li>{translations.grants.support_rate}</li>
					<li>{translations.grants.project_start_date}</li>
					<li>
						{
							translations.grants
								.planned_project_completion_date
						}
					</li>
				</ul>
				<Typography
					sx={{ marginTop: 1 }}
					variant="h6"
					component="p"
					maxWidth="md"
				>
					{translations.grants.project_description}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{parseHtml(
						translations.grants.building_construction,
					)}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{parseHtml(
						translations.grants.infrastructure_development,
					)}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{translations.grants.renewable_energy_utilization}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{
						translations.grants
							.workshop_equipment_procurement
					}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{
						translations.grants
							.information_technology_development
					}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{translations.grants.training_initiative}
				</Typography>
			</Grid>{' '}
			<Grid
				container
				sx={{
					flexDirection: 'column',
					width: 'max-content',
					textAlign: 'justify',
					borderBottom: `1px solid ${colors['brp-black'].main}`,
				}}
			>
				<Typography
					variant="h4"
					component="h2"
					sx={{ marginTop: 4 }}
				>
					{translations.grants.press_release}
				</Typography>
				<Typography variant="body1" component="p">
					{translations.grants.press_release_sub}
				</Typography>
				<Typography variant="body1" component="p">
					{translations.grants.press_release_date}
				</Typography>
				<Typography
					sx={{ marginTop: 4 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{translations.grants.press_release_1}
				</Typography>
			</Grid>
			<Grid
				container
				sx={{
					flexDirection: 'column',
					width: 'max-content',
					textAlign: 'justify',
				}}
			>
				<Typography
					sx={{ marginTop: 2 }}
					variant="h6"
					component="p"
					maxWidth="md"
				>
					{translations.grants.press_release_2_title}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{translations.grants.press_release_2}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{parseHtml(translations.grants.press_release_3)}
				</Typography>
				<Typography
					sx={{ marginTop: 2 }}
					variant="h6"
					component="p"
					maxWidth="md"
				>
					{translations.grants.press_release_4_title}
				</Typography>
				<Typography
					sx={{ marginTop: 1 }}
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{translations.grants.press_release_4}
				</Typography>
				<ul
					style={{
						listStyleType: 'none',
						maxWidth: '900px',
						lineHeight: 1.5,
					}}
				>
					<li>
						{translations.grants.press_release_4_details}
					</li>
					<li>
						{translations.grants.press_release_4_phone}
					</li>
					<li>
						{translations.grants.press_release_4_email}
					</li>
				</ul>
			</Grid>
			<Grid
				container
				sx={{
					flexDirection: 'column',
					width: 'max-content',
					textAlign: 'justify',
				}}
			>
				<Typography
					variant="h4"
					component="h2"
					sx={{ marginTop: 4 }}
				>
					{translations.grants.site}
				</Typography>
				<Typography
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{translations.grants.site_desc}
				</Typography>
				<Grid
					container
					flexDirection="column"
					maxWidth="sm"
				>
					<img src={grant1} style={{ width: '100%' }} />
					<img
						src={pressRelease1}
						style={{ width: '100%' }}
					/>
					<img
						src={construction1}
						style={{ width: '100%', marginTop: '16px' }}
					/>
					<img
						src={construction2}
						style={{ width: '100%', marginTop: '16px' }}
					/>
					<img
						src={construction3}
						style={{ width: '100%', marginTop: '16px' }}
					/>
				</Grid>
				<Typography
					variant="h4"
					component="h2"
					sx={{ marginTop: 4 }}
				>
					{translations.grants.service}
				</Typography>
				<Typography
					variant="body1"
					component="p"
					maxWidth="md"
				>
					{translations.grants.service_desc}
				</Typography>
				<Grid
					container
					flexDirection="column"
					maxWidth="sm"
				>
					<img src={grant2} style={{ width: '100%' }} />
					<img
						src={pressRelease2}
						style={{ width: '100%' }}
					/>
					<img
						src={vehicle1}
						style={{ width: '100%', marginTop: '16px' }}
					/>
					<img
						src={vehicle2}
						style={{ width: '100%', marginTop: '16px' }}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
