import * as Yup from 'yup';

export const passwordResetValidationSchema = (
	translations,
) =>
	Yup.object().shape({
		password: Yup.string()
			.required(translations.validation.password.required)
			.min(8, translations.validation.password.min_length),
		password_confirmation: Yup.string()
			.required(
				translations.validation.password
					.confirmation_required,
			)
			.oneOf(
				[Yup.ref('password')],
				translations.validation.password.confirmation_match,
			),
	});
export const passwordChangeValidationSchema = (
	translations,
) =>
	Yup.object().shape({
		current_password: Yup.string().required(
			translations.validation.password.required,
		),
		password_new: Yup.string()
			.required(translations.validation.password.required)
			.min(8, translations.validation.password.min_length),
		password_confirmation: Yup.string()
			.required(
				translations.validation.password
					.confirmation_required,
			)
			.oneOf(
				[Yup.ref('password_new')],
				translations.validation.password.confirmation_match,
			),
	});
export const signUpValidationSchema = (translations) =>
	Yup.object().shape({
		first_name: Yup.string().required(
			translations.validation.generic.first_name,
		),
		last_name: Yup.string().required(
			translations.validation.generic.last_name,
		),
		email: Yup.string()
			.email(translations.validation.generic.email_invalid)
			.required(translations.validation.generic.email),
		password: Yup.string()
			.required(translations.validation.password.required)
			.min(8, translations.validation.password.min_length),
		password_confirmation: Yup.string()
			.required(
				translations.validation.password
					.confirmation_required,
			)
			.oneOf(
				[Yup.ref('password')],
				translations.validation.password.confirmation_match,
			),
		agreeTerms: Yup.boolean().oneOf(
			[true],
			translations.checkbox.checkout_t_and_c_not_checked,
		),
	});

export const personalDetailsValidationSchema = (
	translations,
	isBusiness?: boolean,
	isLoggedIn?: boolean,
) =>
	Yup.object().shape({
		name: isBusiness
			? Yup.string()
			: Yup.string().required(
					translations.validation.generic.name,
			  ),
		email: Yup.string()
			.email(translations.validation.generic.email_invalid)
			.required(translations.validation.generic.email),
		isBusiness: Yup.boolean(),
		company_name: isBusiness
			? Yup.string().required(
					translations.validation.generic.company_name,
			  )
			: Yup.string(),
		tax_number: isBusiness
			? Yup.string().required(
					translations.validation.generic.tax_number,
			  )
			: Yup.string(),
		password: isLoggedIn
			? Yup.string()
			: Yup.string()
					.required(
						translations.validation.password.required,
					)
					.min(
						8,
						translations.validation.password.min_length,
					),
		password_confirmation: isLoggedIn
			? Yup.string()
			: Yup.string()
					.required(
						translations.validation.password
							.confirmation_required,
					)
					.oneOf(
						[Yup.ref('password')],
						translations.validation.password
							.confirmation_match,
					),
	});

export const phoneNumberAndDeliveryMethodValidation = (
	translations,
) =>
	Yup.object({
		phone_number: Yup.string().required(
			translations.validation.generic.phone,
		),
		delivery_method_id: Yup.string().required(
			translations.validation.shipping_billing
				.delivery_method_id,
		),
	});

export const paymentMethodValidation = (translations) =>
	Yup.string().required(
		translations.validation.shipping_billing
			.payment_method_id,
	);

export const deliveryDetailsValidationSchema = (
	translations,
	hasPhone = false,
) =>
	Yup.object({
		hasPhone: Yup.boolean(),
		street: Yup.string().required(
			translations.validation.shipping_billing.street,
		),
		street_extra: Yup.string()
			.notRequired()
			.min(
				3,
				translations.validation.shipping_billing
					.street_extra,
			),
		city: Yup.string().required(
			translations.validation.shipping_billing.city,
		),
		post_code: Yup.string()
			.required(
				translations.validation.shipping_billing.post_code,
			)
			.min(
				4,
				translations.validation.shipping_billing
					.post_code_min,
			),
		phone_number: Yup.string().required(
			translations.validation.generic.phone,
		),
		delivery_method_id: Yup.string().required(
			translations.validation.shipping_billing
				.delivery_method_id,
		),
	});

export const billingDetailsValidationSchema = (
	translations,
) =>
	Yup.object({
		// billing: Yup.object({
		street: Yup.string().required(
			translations.validation.shipping_billing.street,
		),
		street_extra: Yup.string()
			.notRequired()
			.min(
				3,
				translations.validation.shipping_billing
					.street_extra,
			),
		city: Yup.string().required(
			translations.validation.shipping_billing.city,
		),
		post_code: Yup.string()
			.required(
				translations.validation.shipping_billing.post_code,
			)
			.min(
				4,
				translations.validation.shipping_billing
					.post_code_min,
			),
		// }),
	});

export const contactValidationSchema = (translations) =>
	Yup.object().shape({
		first_name: Yup.string().required(
			translations.validation.generic.first_name,
		),
		last_name: Yup.string().required(
			translations.validation.generic.last_name,
		),
		email: Yup.string()
			.email(translations.validation.generic.email_invalid)
			.required(translations.validation.generic.email),
		message: Yup.string().required(
			translations.validation.generic.message,
		),
		agreePrivacy: Yup.boolean().oneOf(
			[true],
			translations.checkbox.checkout_privacy_not_checked,
		),
	});
export const subscriptionValidationSchema = (
	translations,
) =>
	Yup.object().shape({
		email: Yup.string()
			.email(translations.validation.generic.email_invalid)
			.required(translations.validation.generic.email),
		agreePrivacy: Yup.boolean().oneOf(
			[true],
			translations.checkbox.checkout_privacy_not_checked,
		),
	});
