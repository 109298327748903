import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export function useTranslation() {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	return prompts[currentLocale];
}
