import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	store,
	updateSubscriptionPreference,
} from '../../../../redux';
import { Link } from 'react-router-dom';
import { RenderErrors } from './RenderErrors';

export const CheckoutCheckboxes = ({
	translations,
	shouldSubscribe,
	isTandCAccepted,
	setIsTandCAccepted,
	setIsPrivacyPolicyAccepted,
	isPrivacyPolicyAccepted,
}: {
	translations: any;
	shouldSubscribe: boolean;
	isTandCAccepted: any;
	setIsTandCAccepted: ({
		isAccepted,
		checked,
		message,
	}) => void;
	setIsPrivacyPolicyAccepted: ({
		isAccepted,
		checked,
		message,
	}) => void;
	isPrivacyPolicyAccepted: any;
}) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const handleTandC = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		// todo: this probably needs to be sent to the backend too - ask peter
		// hook it up with redux
		if (event.target.checked) {
			setIsTandCAccepted({
				isAccepted: true,
				checked: true,
				message: undefined,
			});
		} else {
			setIsTandCAccepted({
				isAccepted: false,
				checked: true,
				message:
					translations.checkbox
						.checkout_t_and_c_not_checked,
			});
		}
	};

	const handlePrivacyPolicy = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (event.target.checked) {
			setIsPrivacyPolicyAccepted({
				isAccepted: true,
				checked: true,
				message: undefined,
			});
		} else {
			setIsPrivacyPolicyAccepted({
				isAccepted: false,
				checked: true,
				message:
					translations.checkbox
						.checkout_privacy_not_checked,
			});
		}
	};
	return (
		<FormGroup
			sx={{
				paddingLeft: 0,
			}}
		>
			<FormControlLabel
				control={
					<Checkbox
						checked={shouldSubscribe}
						onChange={() => {
							store.dispatch(
								updateSubscriptionPreference(
									!shouldSubscribe,
								),
							);
						}}
						value={shouldSubscribe}
					/>
				}
				label={
					<span>
						{translations.text.subscribe_to_newsletter}
					</span>
				}
			/>
			{!isTandCAccepted.isAccepted &&
			isTandCAccepted.checked ? (
				<RenderErrors
					errors={{
						tAndC: isTandCAccepted.message,
					}}
					isTandC={true}
					// may not need prefix below
					// prefix={prefix}
				/>
			) : null}
			<FormControlLabel
				control={
					<Checkbox
						required
						checked={isTandCAccepted.isAccepted}
						onChange={handleTandC}
						value={isTandCAccepted.isAccepted}
						// 	productAttribute.value?.en ??
						// 	productAttribute.value
						// }
					/>
				}
				label={
					<span style={{ marginBottom: '-3px' }}>
						{translations.checkbox.checkout_t_and_c_part1}
						<Link
							to="/terms-of-use"
							target="_blank"
							rel="noreferrer"
							style={{
								textDecoration: 'underline',
							}}
						>
							{translations.checkbox.checkout_t_and_c_part2}
						</Link>
						.
					</span>
				}
			/>
			{!isPrivacyPolicyAccepted.isAccepted &&
			isPrivacyPolicyAccepted.checked ? (
				<RenderErrors
					errors={{
						privacyPolicy: isPrivacyPolicyAccepted.message,
					}}
					isPrivacyPolicy={true}
					// may not need prefix below
					// prefix={prefix}
				/>
			) : null}
			<FormControlLabel
				control={
					<Checkbox
						required
						checked={isPrivacyPolicyAccepted.isAccepted}
						onChange={handlePrivacyPolicy}
						value={isPrivacyPolicyAccepted.isAccepted}
						// 	productAttribute.value?.en ??
						// 	productAttribute.value
						// }
					/>
				}
				label={
					<span>
						{translations.checkbox.checkout_privacy_part1}
						<Link
							to="/privacy-policy"
							target="_blank"
							rel="noreferrer"
							style={{
								textDecoration: 'underline',
							}}
						>
							{translations.checkbox.checkout_privacy_part2}
						</Link>
						.
					</span>
				}
			/>
		</FormGroup>
	);
};
