import React, { useState } from 'react';
import {
	Dialog,
	Fab,
	Button,
	MobileStepper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import julyPromotion from '../../assets/julyPromotion.jpeg';
import summerSale from '../../assets/summerSale.jpeg';
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
} from '@mui/icons-material';
import {
	RootState,
	store,
	toggleSale,
	toggleYear,
	updatePromoPopover,
} from '../../redux';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../helpers';

export const Promotion = () => {
	const translations = useTranslation();
	const navigate = useNavigate();
	const isPromoPopoverOpen = useSelector(
		(state: RootState) => state.generic.promoPopover.isOpen,
	);
	const handleFabClick = () =>
		store.dispatch(updatePromoPopover(!isPromoPopoverOpen));

	const handleYearToggle = async (
		e,
		year: '2023' | '2024',
	) => {
		store.dispatch(
			toggleYear({
				yearToFetch: year,
				onlyReceivedYears: true,
			}),
		);
		navigate('shop/vehicles');
	};
	const handleSaleToggle = async () => {
		store.dispatch(toggleSale());
		navigate('shop/products');
	};

	const promotions = [julyPromotion, summerSale];

	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = promotions.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<>
			<Dialog
				open={isPromoPopoverOpen}
				onClose={handleFabClick}
			>
				<div
					style={{
						margin: 0,
						padding: 0,
						height: '90vh',
						maxHeight: 720,
						cursor: 'pointer',
					}}
				>
					<img
						src={promotions[activeStep]}
						alt={`Promotional offer ${activeStep + 1}`}
						style={{ height: 'calc(100% - 60px)' }}
						onClick={(e) => {
							if (activeStep === 0) {
								handleYearToggle(e, '2023');
							} else if (activeStep === 1) {
								handleSaleToggle();
							}
						}}
					/>
					<MobileStepper
						steps={maxSteps}
						position="static"
						activeStep={activeStep}
						nextButton={
							<Button
								size="small"
								onClick={handleNext}
								disabled={activeStep === maxSteps - 1}
							>
								{translations.buttons.next}
								<KeyboardArrowRight
									sx={{ marginBottom: '4px' }}
								/>
							</Button>
						}
						backButton={
							<Button
								size="small"
								onClick={handleBack}
								disabled={activeStep === 0}
							>
								<KeyboardArrowLeft
									sx={{ marginBottom: '4px' }}
								/>
								{translations.buttons.back}
							</Button>
						}
					/>
				</div>
			</Dialog>
			<Fab
				color="primary"
				sx={{
					position: 'fixed',
					bottom: '16px',
					left: '16px',
				}}
				onClick={handleFabClick}
			>
				<FontAwesomeIcon
					icon={isPromoPopoverOpen ? faTimes : faBullhorn}
				/>
			</Fab>
		</>
	);
};
