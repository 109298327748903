import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';

export const AddressDropdown = ({
	addresses,
	setSelected,
	translations,
}) => {
	const handleChange = (event) => {
		setSelected(event.target.value);
	};
	return (
		<FormControl fullWidth sx={{ marginBottom: 2 }}>
			<InputLabel>
				{translations.text.previous_addresses}
			</InputLabel>
			<Select onChange={handleChange}>
				{addresses.map((address) => (
					<MenuItem key={address.id} value={address}>
						{`${address.street}, ${address.city}, ${address.post_code}, ${address.country_name}`}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
