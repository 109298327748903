import {
	Breadcrumbs,
	Link as MuiLink,
	Grid,
} from '@mui/material';
import {
	Category,
	RootState,
	SelectedCategory,
	fetchProducts,
	resetBreadcrumbs,
	resetFiltersApplied,
	store,
	updateBreadcrumbs,
	updateDisplayableProducts,
	updateProductFetchStatus,
} from '../../../redux';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { colors } from '../../../themes';
import { useSelector } from 'react-redux';

export const CustomBreadcrumbs = ({ style = {} }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const breadcrumbItems = useSelector(
		(state: RootState) =>
			state.generic.categories.breadcrumbs,
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const query = new URLSearchParams(location.search);
	const handleBreadcrumbClick = (item, index) => {
		const categoryParam = query.get('category');
		if (
			categoryParam !== null &&
			parseInt(categoryParam) === item?.category_id
		) {
			return;
		}

		store.dispatch(updateBreadcrumbs(item.name));

		if (index === 0) {
			store.dispatch(updateProductFetchStatus('loading'));
			store.dispatch(updateDisplayableProducts(undefined));
			store.dispatch(resetBreadcrumbs());
			store.dispatch(resetFiltersApplied());

			const productItems = store.getState().products.items;

			if (productItems.length === 0) {
				store.dispatch(fetchProducts());
			}

			// fake loading for UX
			setTimeout(() => {
				store.dispatch(
					updateProductFetchStatus('fulfilled'),
				);
			}, 1000);

			navigate('/shop/products');
		} else {
			navigate(item.path);
		}
	};

	return (
		<Grid
			container
			marginBottom={1}
			sx={{
				paddingX: { xs: 2, md: 3 },
				paddingTop: { xs: 2, md: 0 },
				...style,
			}}
		>
			<Breadcrumbs
				aria-label="breadcrumb"
				sx={{ lineHeight: 1.6 }}
			>
				{breadcrumbItems.map((item, index) => (
					<MuiLink
						key={item.path}
						component={Link}
						to={item.path}
						underline="hover"
						sx={{
							cursor: 'pointer',
							color: colors['brp-black'].main,
							fontSize: '20px',
							fontFamily:
								'"Trade Gothic LT Pro Cn" !important',
						}}
						onClick={() =>
							handleBreadcrumbClick(item, index)
						}
					>
						{item.name === 'Products'
							? currentLocale === 'hu'
								? 'Termékek'
								: 'Products'
							: item.name}
					</MuiLink>
				))}
			</Breadcrumbs>
		</Grid>
	);
};
const findCategoryById = (
	categories: Category[],
	categoryId: number,
): Category | undefined => {
	for (const category of categories) {
		if (category.id === categoryId) {
			return category;
		}

		if (category.children?.length) {
			const found = findCategoryById(
				category.children,
				categoryId,
			);
			if (found) {
				return found;
			}
		}
	}
	return undefined;
};
export const getCategoryChain = (
	categories: Category[],
	selectedCategoryId: number,
	chain: Category[] = [],
): Category[] => {
	// Use the new helper function to find category at any depth
	const currentCategory = findCategoryById(
		categories,
		selectedCategoryId,
	);

	if (!currentCategory) {
		return chain;
	}

	chain.unshift(currentCategory);

	if (currentCategory.parent_id) {
		return getCategoryChain(
			categories,
			currentCategory.parent_id,
			chain,
		);
	}

	const breadcrumbs = chain.map((category) => ({
		name: category.name,
		path: `/shop/products?category=${category.id}`,
		category_id: category.id,
		hasChildren:
			Array.isArray(category.children) &&
			category.children.length > 0
				? true
				: undefined,
	}));

	store.dispatch(updateBreadcrumbs(breadcrumbs));
	return chain;
};
export const generateParentBreadcrumbs = (
	categories,
	selected,
	breadcrumbs: any[] = [],
) => {
	if (selected && selected.parent_id) {
		const parentCategory = categories.find(
			(category) => category.id === selected.parent_id,
		);

		if (parentCategory) {
			const breadcrumb = {
				name: parentCategory.name,
				path: `/shop/products?category=${parentCategory.id}`,
				category_id: parentCategory.id,
				hasChildren: parentCategory.children.length > 0,
			};

			// Insert the parent breadcrumb at the second position (after the first breadcrumb)
			breadcrumbs.splice(1, 0, breadcrumb);

			// Recursive call to handle parent's parent
			generateParentBreadcrumbs(
				categories,
				parentCategory,
				breadcrumbs,
			);
		}
	}

	return breadcrumbs;
};

const createCategory = (
	category: Category,
	parentId?: number,
) => {
	if (category?.children?.length > 0) {
		return {
			...category,
			path: `/shop/products?category=${category.id}`,
			children: category.children.map((categ) =>
				createCategory(categ, categ.id),
			),
		} as SelectedCategory;
	}
	return {
		...category,
		path: `/shop/products?category=${category.id}`,
	} as SelectedCategory;
};
// could probably factor away the wrapper function
export const getRecurringCategories = (
	category: Category,
) => {
	return createCategory(category, undefined);
};
