import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, Form, Formik, useFormik } from 'formik';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { colors } from '../../themes';
import {
	RootState,
	search,
	searchBlog,
	store,
	updateSearchPopover,
	vehicleSearch,
} from '../../redux';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { doScrollToTop } from '../../helpers';

const Search = styled('section')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(
			theme.palette.common.white,
			0.25,
		),
	},
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		width: 'auto',
	},
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: colors['brp-black'].main,
	maxWidth: '100%',
	width: 200,
	'& .MuiInputBase-input': {
		paddingLeft: 8,
		// paddingLeft: `calc(1em + ${theme.spacing(3)})`, // Keep the left padding
		// paddingRight: `calc(1em + ${theme.spacing(3)})`, // Add right padding to push the text towards the left
		transition: theme.transitions.create('width'),
		[theme.breakpoints.up('sm')]: {
			width: '100%', // Set initial width
			// '&:focus': {
			// 	width: '20ch', // Set width on focus
			// },
		},
	},
}));
const LoadingContainer = styled('section')({
	position: 'absolute',
	right: 10,
	top: '50%',
	transform: 'translateY(-40%)',
});
const SearchForm = ({ style = {} }) => {
	const navigate = useNavigate();
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const searchType = useSelector(
		(state: RootState) => state.generic.searchType,
	);
	return (
		<Formik
			initialValues={{ searchedValue: '' }}
			onSubmit={async (
				values,
				{ setSubmitting, resetForm },
			) => {
				setSubmitting(true);
				if (searchType === 'item') {
					await store.dispatch(
						search(values.searchedValue),
					);
					navigate(
						`/shop/products?s=${encodeURIComponent(
							values.searchedValue,
						)}`,
					);
				} else {
					await store.dispatch(
						vehicleSearch(values.searchedValue),
					);
					navigate(
						`/shop/vehicles?s=${encodeURIComponent(
							values.searchedValue,
						)}`,
					);
				}
				store.dispatch(updateSearchPopover(false));
				resetForm();
				setSubmitting(false);
			}}
		>
			{({ isSubmitting }) => (
				<Form style={style}>
					<Search>
						<Field name="searchedValue">
							{({ field }) => (
								<StyledInputBase
									autoFocus
									{...field}
									placeholder={`${translations.text.search}...`}
									inputProps={{
										'aria-label': 'search',
									}}
								/>
							)}
						</Field>
						{isSubmitting && (
							<LoadingContainer>
								<CircularProgress
									size={20}
									color="inherit"
								/>
							</LoadingContainer>
						)}
					</Search>
				</Form>
			)}
		</Formik>
	);
};
export const SearchFormForBlog = ({
	style = {},
	dontShowAll,
}) => {
	const navigate = useNavigate();
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];

	return (
		<Formik
			initialValues={{ searchedValue: '' }}
			onSubmit={async (
				values,
				{ setSubmitting, resetForm },
			) => {
				doScrollToTop();
				setSubmitting(true);
				await store.dispatch(
					searchBlog(values.searchedValue),
				);
				dontShowAll();
				navigate(
					`/blog?s=${encodeURIComponent(
						values.searchedValue,
					)}`,
				);
				resetForm();
				setSubmitting(false);
			}}
		>
			{({ isSubmitting }) => (
				<Form style={style}>
					<Search>
						<Field name="searchedValue">
							{({ field }) => (
								<StyledInputBase
									autoFocus
									{...field}
									placeholder={`${translations.text.search}...`}
									inputProps={{
										'aria-label': 'search',
									}}
								/>
							)}
						</Field>
						{isSubmitting && (
							<LoadingContainer>
								<CircularProgress
									size={20}
									color="inherit"
								/>
							</LoadingContainer>
						)}
					</Search>
				</Form>
			)}
		</Formik>
	);
};
SearchForm.propTypes = {
	handleIsSearching: PropTypes.func,
};
export default SearchForm;
