import { Chip, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
	RootState,
	getUserOrders,
	selectAccountDetails,
	selectUserOrders,
	store,
} from '../../redux';
import { useSelector } from 'react-redux';
import {
	formatPriceToForint,
	getFormattedDateTime,
} from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ItemsDialogue } from '../../components';
import { OrderHistoryItem } from './OrderHistoryItem';

export const OrderHistory = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const orders = useSelector(selectUserOrders);
	const accountDetails = useSelector(selectAccountDetails);
	const ordersAreBeingFetched = useSelector(
		(state: RootState) => state.auth.orders.isBeingFetched,
	);

	const [selectedRowDetails, setSelectedRowDetails] =
		useState(undefined);
	const [showItem, setShowItem] = useState(false);

	// this is probably not needed, think about case straight after order though
	// how should that be handled? should completion do it?
	useEffect(() => {
		if (!orders) {
			store.dispatch(getUserOrders());
		}
	}, []);

	const handleRowSelection = (params) => {
		const { row } = params;
		setSelectedRowDetails(row);
		handleClickOpen();
	};
	const handleClickOpen = () => {
		setShowItem(true);
	};

	const handleClose = () => {
		setShowItem(false);
		setSelectedRowDetails(undefined);
	};

	const columns: GridColDef[] = [
		{
			field: 'reference',
			headerName:
				prompts[currentLocale].account.order_history_table
					.reference,
			hideable: false,
			disableColumnMenu: true,
			width: 130,
		},
		{
			field: 'placed_at',
			headerName:
				prompts[currentLocale].account.order_history_table
					.purchase_date,
			width: 150,
			hideable: false,
			// sortable: false,
			filterable: false,
			disableColumnMenu: true,
			renderCell: (params) => {
				const purchase_date = new Date(params.value);
				const formattedDate =
					getFormattedDateTime(purchase_date);
				return <>{formattedDate}</>;
			},
		},
		{
			field: 'payable_total',
			headerName: `${prompts[currentLocale].shop.price} (HUF)`,
			width: 120,
			disableColumnMenu: true,
			hideable: false,
			renderCell: (params) => (
				<Typography variant="body2" component="p">
					{formatPriceToForint(params.row.payable_total)}
				</Typography>
			),
		},
		{
			sortable: false,
			disableColumnMenu: true,
			filterable: false,
			hideable: false,
			field: 'status',
			headerName:
				prompts[currentLocale].account.order_history_table
					.status,
			width: 150,
			renderCell: (params) => (
				<Chip
					sx={{ marginTop: 1, marginBottom: 1 }}
					color={
						params.value.toLowerCase() ===
						'customer cancelled'
							? 'error'
							: 'success'
					}
					label={params.value}
				/>
				// <Chip color='error' label='success' />
				// <Chip color='info' label='success' />
			),
		},
		{
			field: 'actions',
			headerName: '',
			filterable: false,
			sortable: false,
			hideable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<FontAwesomeIcon
						onClick={() => handleRowSelection(params)}
						style={{
							marginLeft: 'auto',
							marginRight: 8,
							cursor: 'pointer',
						}}
						icon={faEllipsisV}
					/>
				);
			},
		},
	];
	return (
		<Grid container flexDirection="column">
			<Typography component="h2" variant="h5">
				{prompts[currentLocale].navigation.order_history}
			</Typography>
			<Typography
				component="p"
				variant="body2"
				style={{
					marginBottom: 16,
				}}
			>
				{
					prompts[currentLocale].account.order_history_table
						.disclaimer
				}
			</Typography>
			{selectedRowDetails ? (
				<OrderHistoryItem
					rowDetails={selectedRowDetails}
					open={showItem}
					onClose={handleClose}
					translations={prompts[currentLocale]}
				/>
			) : (
				<Grid
					container
					sx={{
						height: {
							md: '40vh',
						},
					}}
				>
					<DataGrid
						disableColumnMenu
						hideFooter
						onRowClick={handleRowSelection} //(e) => console.log(e)}
						getRowHeight={() => 'auto'}
						localeText={{
							noRowsLabel:
								prompts[currentLocale].account
									.order_history_table.no_orders_yet,
						}}
						loading={ordersAreBeingFetched}
						rows={
							!ordersAreBeingFetched && orders ? orders : []
						}
						// slots={{ noRowsOverlay: CustomNoRowsOverlay }}
						sx={{
							minHeight: '200px',
							width: '100%',
							'--DataGrid-overlayHeight': '300px',
							'&.MuiDataGrid-root .MuiDataGrid-row': {
								cursor: 'pointer',
							},
							'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
								{ outline: 'none !important' },
							'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within':
								{ outline: 'none' },
						}}
						columns={columns}
					/>
				</Grid>
			)}
		</Grid>
	);
};
