import { Button, Grid, Typography } from '@mui/material';
import {
	Country,
	RootState,
	getCountryFromCountryCode,
	postOrder,
	store,
	subscribe,
	updateGeolocation,
	updateSelectedLocker,
	setCanProgress,
} from '../../../redux';
import { useSelector } from 'react-redux';
import { doScrollToTop } from '../../../helpers';

const scrollToElementById = (id: string) => {
	const container = window.document.getElementById(id);
	container?.scrollIntoView({ behavior: 'smooth' });
};

export const scrollToElementWhenErrors = ({ errors }) => {
	const errorKeys = Object.keys(errors);
	if (errorKeys.includes('personalDetails')) {
		scrollToElementById('personal-details');
		return;
	}
	if (errorKeys.includes('billing')) {
		scrollToElementById('billing-details');
		return;
	}
	if (errorKeys.includes('shipping')) {
		scrollToElementById('delivery-details');
		return;
	}
};

export const getLocation = () => {
	if (navigator.geolocation) {
		return navigator.geolocation.getCurrentPosition(
			(position) => {
				const coordinates = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				};
				store.dispatch(
					updateGeolocation({
						enabled: true,
						coordinates,
					}),
				);
				return coordinates;
			},
			(error) => {
				console.error(
					'Error getting location:',
					error.message,
				);
			},
		);
	} else {
		store.dispatch(
			updateGeolocation({
				enabled: false,
				coordinates: undefined,
			}),
		);
		console.error(
			'Geolocation is not supported by this browser.',
		);
	}
};

export const SelectedLocker = ({ lockerDetail }) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const resetLocker = () =>
		store.dispatch(updateSelectedLocker(undefined));
	return (
		<Grid
			container
			flexDirection="column"
			sx={{ marginTop: 1 }}
		>
			<Grid container justifyContent="space-between">
				<Typography variant="h5" component="h3">
					{translations.text.locker_selected}:
				</Typography>
				<Button
					variant="brp-white"
					size="small"
					onClick={resetLocker}
				>
					{translations.text.choose_another_locker}
				</Button>
			</Grid>
			{renderLockerDetails({ lockerDetail, translations })}
		</Grid>
	);
};

const allWeekdays = [
	'MON',
	'TUE',
	'WED',
	'THU',
	'FRI',
	'SAT',
	'SUN',
];
const OpeningHours = ({ openingDays, translations }) => {
	const completeOpeningDays = allWeekdays.map((weekday) => {
		const existingDay = openingDays.find(
			(day) => day.weekday === weekday,
		);

		return (
			existingDay ?? {
				weekday,
				hours: translations.text.closed,
				key: weekday,
			}
		);
	});
	const openingTimes = completeOpeningDays.map((day) => (
		<Grid container key={day.weekday}>
			<Grid item xs={5}>
				{translations.opening_days[day.weekday]}
			</Grid>
			{typeof day.hours === 'string'
				? day.hours
				: day.hours.map((hour) => (
						<Grid
							item
							xs={7}
							key={day.weekday + hour.openingTime}
						>
							{hour.openingTime}: {hour.closingTime}
						</Grid>
				  ))}
		</Grid>
	));
	return <>{openingTimes}</>;
};

export const placeOrder = async ({
	shipping,
	name,
	company_name,
	billing,
	email,
	password,
	password_confirmation,
	cartItems,
	selectedDelivery,
	shouldSubscribe,
	selectedLocker,
	deliveryDetails,
	payment_method_id,
	phone_number = null,
	promo_code,
	userDetails,
	tax_number,
	navigate,
	setIsGuestStart,
}) => {
	const isUserLoggedIn = !!userDetails?.id;

	try {
		const restOfOrder = isUserLoggedIn
			? {
					user_id: userDetails.id,
			  }
			: {
					email,
					password,
					password_confirmation,
			  };
		const orderPlaced = await store.dispatch(
			postOrder({
				billing: { ...billing, country: 101 },
				shipping: { ...shipping, country: 101 },
				name,
				company_name,
				promo_code,
				payment_method_id,
				products: cartItems,
				tax_number,
				delivery_method_id: selectedDelivery?.id,
				phone_number:
					deliveryDetails?.phone_number ?? phone_number,
				locker_id: selectedLocker?.parcelShopId ?? null,
				...restOfOrder,
			}),
		);
		if (
			// @ts-ignore
			orderPlaced?.error?.message.toLowerCase() ===
				'rejected' &&
			// @ts-ignore
			orderPlaced?.payload?.errorCode !== 409
		) {
			store.dispatch(setCanProgress(false));
			return;
		}
		if (orderPlaced?.payload?.errorCode === 409) {
			doScrollToTop();
			setIsGuestStart(false);
			navigate('/checkout/login', {
				state: {
					from: '/checkout/review',
					isRedirect: true,
				},
			});
			return;
		}
		// todo: maybe this isn't the best place
		// revisit this
		if (shouldSubscribe) {
			await store.dispatch(
				subscribe({
					email,
				}),
			);
		}
		store.dispatch(setCanProgress(true));
		return orderPlaced.payload.data;
	} catch (e) {
		console.log('Something went wrong with your order', e);
		store.dispatch(setCanProgress(false));
	}
};

export const convertLockerToShippingAddress = ({
	lockerDetails,
}) => {
	const { address, name } = lockerDetails;
	const {
		city,
		street,
		zipCode,
		houseNumber,
		countryCode,
	} = address;
	const country: Country | null = getCountryFromCountryCode(
		store.getState(),
		countryCode,
	);
	return {
		street: name,
		street_extra: `${street} ${houseNumber}`,
		post_code: zipCode,
		city,
		country: country?.id ?? countryCode,
	};
};

export const renderLockerDetails = ({
	lockerDetail,
	translations,
}) => {
	const {
		address,
		openingDays,
		distance,
		externalContactDetails,
		partnerLogos,
		paymentMethods,
		parcelExchangeTime,
		name,
	} = lockerDetail;
	const { city, street, zipCode } = address;

	return (
		<Grid container flexDirection="column">
			<Typography
				variant="h6"
				component="p"
				// style={{ marginTop: 8 }}
			>
				{name}
			</Typography>
			<Typography variant="body1" component="p">
				{city}
			</Typography>
			<Typography variant="body1" component="p">
				{street} {zipCode}
			</Typography>
			<Typography
				variant="h6"
				component="p"
				style={{ marginTop: 8 }}
			>
				{translations.text.opening_hours}:
			</Typography>
			<OpeningHours
				translations={translations}
				openingDays={openingDays}
			/>
		</Grid>
	);
};
