import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	TextField,
	Button,
	Typography,
	Container,
	Grid,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import {
	RootState,
	activateAccount,
	store,
	updateDialogDetails,
} from '../../redux';
import { colors } from '../../themes';
import { useNavigate } from 'react-router-dom';
import { useTranslation, wordAndLineBreakStyle } from '../../helpers';
import { useSelector } from 'react-redux';

const getValidationSchema = (prompts, currentLocale) => {
	return Yup.object({
		password: Yup.string()
			.required(
				prompts[currentLocale].validation.password.required,
			)
			.min(
				8,
				prompts[currentLocale].validation.password
					.min_length,
			),
		password_confirmation: Yup.string()
			.required(
				prompts[currentLocale].validation.password
					.confirmation_required,
			)
			.oneOf(
				[Yup.ref('password')],
				prompts[currentLocale].validation.password
					.confirmation_match,
			),
	});
};

const initialValues = {
	password: '',
	password_confirmation: '',
};

const InvalidUrl = ({ prompts, currentLocale }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const navigate = useNavigate();

	const navigateHome = () => {
		navigate('/home');
	};
	return (
		<Grid
			container
			sx={{
				minHeight: '40vh',
				background: colors['brp-white'].main,
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				textAlign: isSmallScreen ? 'center' : 'left',
			}}
		>
			<Typography
				variant="h4"
				component="h1"
				sx={{
					position: 'relative',
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: '16px',
				}}
			>
				{prompts[currentLocale].errors.generic}
				{isSmallScreen ? null : (
					<span
						style={{
							position: 'absolute',
							right: '10%',
							bottom: 0,
							width: '100px',
							height: '4px',
							backgroundColor: colors['brp-yellow'].main,
							marginLeft: 'calc(100% - 100px - 25%)',
						}}
					></span>
				)}
			</Typography>
			<Typography
				variant="body2"
				component="p"
				sx={{
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: '16px',
					...wordAndLineBreakStyle,
				}}
			>
				{prompts[currentLocale].account.activation.error}
			</Typography>
			<Button variant="brp-yellow" onClick={navigateHome}>
				{prompts[currentLocale].navigation.home}
			</Button>
		</Grid>
	);
};

export const AccountActivation: React.FC = () => {
	const navigate = useNavigate();
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = useTranslation();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const activation_token = urlParams.get(
		'activation_token',
	);
	const validationSchema = getValidationSchema(
		prompts,
		currentLocale,
	);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, { resetForm }) => {
			// at this point it must be available
			if (activation_token) {
				const { password, password_confirmation } = values;
				const isSuccessful = await store.dispatch(
					activateAccount({
						activation_token,
						password,
						password_confirmation,
					}),
				);
				if (isSuccessful) {
					store.dispatch(
						updateDialogDetails({
							visible: true,
							content: translations.forms.successful_activation,
							isError: false,
						}),
					);
					navigate('/login');
				}
				resetForm();
			}
		},
	});
	useEffect(() => {
		formik.validateForm();
	}, [currentLocale]);

	return !activation_token ? (
		<InvalidUrl
			prompts={prompts}
			currentLocale={currentLocale}
		/>
	) : (
		<Container sx={{ my: 4 }} maxWidth="xs">
			<Typography
				component="h1"
				sx={{ mb: 1 }}
				variant="h5"
			>
				{prompts[currentLocale].account.activation.title}
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					type="password"
					name="password"
					label={
						prompts[currentLocale].placeholder
							.password
					}
					sx={{ mb: 2 }}
					tabIndex={1}
					value={formik.values.password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={
						formik.touched.password &&
						Boolean(formik.errors.password)
					}
					helperText={
						formik.touched.password &&
						formik.errors.password
					}
				/>
				<TextField
					fullWidth
					type="password"
					tabIndex={2}
					name="password_confirmation"
					label={
						prompts[currentLocale].placeholder
							.password_confirmation
					}
					sx={{ mb: 2 }}
					value={formik.values.password_confirmation}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={
						formik.touched.password_confirmation &&
						Boolean(formik.errors.password_confirmation)
					}
					helperText={
						formik.touched.password_confirmation &&
						formik.errors.password_confirmation
					}
				/>
				<Button
					type="submit"
					fullWidth
					variant="brp-yellow"
				>
					{prompts[currentLocale].buttons.activate}
				</Button>
			</form>
		</Container>
	);
};
