import { styled } from '@mui/material';
import { ErrorMessage, ErrorMessageProps } from 'formik';
import { colors } from '../themes';

export const Error = styled((props: ErrorMessageProps) => (
	<ErrorMessage {...props} component="span" />
))(() => ({
	marginTop: '8px',
	display: 'block',
	color: colors['canam-on-red'].main,
}));
