import React, { useEffect, useState } from 'react';
import {
	Link,
	Outlet,
	useLocation,
} from 'react-router-dom';
import { Header, Footer, InfoLine } from '..';
import horizontalGrant from '../../assets/grantHorizontal.jpg';
import bottomRightGrant from '../../assets/bottomRightGrant.png';
import { colors } from '../../themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery, useTheme } from '@mui/material';

const BottomRightGrant = () => {
	const [shown, setShown] = useState(true);
	const location = useLocation();
	return location.pathname === '/' ? (
		<section
			style={{
				backgroundColor: 'transparent', //colors['brp-black'].light,
				textAlign: 'right',
				justifyContent: 'flex-end',
				position: 'fixed',
				bottom: 0,
				right: 0,
				zIndex: 1,
				display: shown ? 'flex' : 'none',
			}}
		>
			{/* <FontAwesomeIcon
				icon={faTimes}
				onClick={() => setShown(false)}
				style={{
					cursor: 'pointer',
					fontSize: '1.5em',
					position: 'absolute',
					top: 20,
					right: 10,
					strokeWidth: '30px',
					stroke: 'white',
				}}
			/> */}
			<Link
				to="/sponsors"
				style={{
					display: 'flex',
					width: '70%',
					maxWidth: '450px',
				}}
			>
				<img
					style={{ width: '100%' }}
					src={bottomRightGrant}
				/>
			</Link>
		</section>
	) : null;
};

const TopLeftGrant = () => {
	const [shown, setShown] = useState(true);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const location = useLocation();
	return location.pathname === '/' ? (
		<section
			style={{
				backgroundColor: 'white', //colors['brp-black'].light,
				textAlign: 'right',
				justifyContent: 'flex-start',
				position: 'sticky',
				top: isSmallScreen ? 45 : 0,
				left: 0,
				zIndex: 2,
				display: shown ? 'flex' : 'none',
				height: 'max-content',
				maxHeight: '150px',
			}}
		>
			{/* <FontAwesomeIcon
				icon={faTimes}
				onClick={() => setShown(false)}
				style={{
					cursor: 'pointer',
					fontSize: '1.5em',
					position: 'absolute',
					top: 10,
					right: 10,
					strokeWidth: '30px',
					stroke: 'white',
				}}
			/> */}
			<Link
				to="/sponsors"
				style={{
					height: '100%',
					width: '100%',
					margin: '0 auto',
				}}
			>
				<img
					src={horizontalGrant}
					style={{
						objectFit: 'contain',
						width: '100%',
						height: '100%',
					}}
				/>
			</Link>
		</section>
	) : null;
};
export const Layout = () => {
	return (
		<>
			<InfoLine />
			<Header />
			<TopLeftGrant />
			<main className="app">
				<Outlet />
			</main>
			<BottomRightGrant />
			<Footer />
		</>
	);
};
