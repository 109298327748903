import {
	Button,
	Dialog,
	Grid,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import * as Yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import { colors } from '../../../themes';
import {
	billingDetailsValidationSchema,
	deliveryDetailsValidationSchema,
	doScrollToTop,
	paymentMethodValidation,
	personalDetailsValidationSchema,
	phoneNumberAndDeliveryMethodValidation,
	useTranslation,
} from '../../../helpers';
import {
	CartSummary,
	CartSummarySection,
	EmptyCart,
} from '../cart-page';
import {
	PersonalDetails,
	DeliveryDetails,
	PaymentDetails,
} from '..';
import {
	getIsBillingSame,
	store,
	getItemsInCart,
	getCurrentOrder,
	setCheckoutLoading,
	getIsCheckoutLoading,
	selectAccountDetails,
	getNumberOfItemsInCart,
	getSelectedDelivery,
	RootState,
	fetchCountries,
	validateTaxNumber,
	isUserLoggedIn,
	personalDetailsForOrderUser,
	deliveryDetailsForOrderUser,
	billingDetailsForOrderUser,
	setCanProgress,
} from '../../../redux';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailsSummary } from './components';
import { GridCloseIcon } from '@mui/x-data-grid';
import {
	convertLockerToShippingAddress,
	getLocation,
	placeOrder,
	scrollToElementWhenErrors,
} from './helpers';
import { Form, Formik } from 'formik';
import { ConfirmDetails } from './components';
import { PaymentMethods } from './PaymentMethods';
import { LoginForm } from '../../login';

type PersonalDetails = {
	first_name?: string;
	last_name?: string;
	phone_number?: string;
	email?: string;
};
const ONLINE_PAYMENT = 'online_bankcard';

export const Checkout = () => {
	const [isGuestStart, setIsGuestStart] = useState(false);
	const navigate = useNavigate();
	const translations = useTranslation();
	const location = useLocation();
	const navigatedFrom = location.state?.from || '/';
	const fromReviewScreen = navigatedFrom
		? navigatedFrom.includes('review')
		: false;

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const isCheckoutLoading = useSelector(
		getIsCheckoutLoading,
	);
	const numOfItemsInCart = useSelector(
		getNumberOfItemsInCart,
	);
	const checkout = useSelector(
		(state: RootState) => state.checkout,
	);
	const isBillingSame = useSelector(getIsBillingSame);
	const currentOrder = useSelector(getCurrentOrder);
	const selectedDelivery = useSelector(getSelectedDelivery);
	const accountDetails = useSelector(selectAccountDetails);
	const discount = checkout.discount;
	const businessDetails = checkout.business;
	const shouldSubscribe = checkout.shouldSubscribe ?? false;
	const selectedLocker = checkout.parcelShops.selected;
	const selectedDeliveryMethod =
		checkout?.selectedDeliveryMethod;
	const selectedPaymentMethod =
		checkout.selectedPaymentMethod;
	const anOrderHasBeenInitiated = useSelector(
		(state: RootState) => state.checkout?.order?.payment,
	);
	const isOnlinePayment =
		selectedPaymentMethod?.payment_method_string ===
		ONLINE_PAYMENT;
	const userIsLoggedIn = useSelector(isUserLoggedIn);
	const cartItems = useSelector(getItemsInCart);
	const userDetails = useSelector(
		(state: RootState) => state.auth.details,
	);
	const progressedToReviewScreen = checkout.canProgress;
	const personalDetailsInState = checkout?.personalDetails;
	const billingDetailsInState = checkout?.billingDetails;

	const deliveryDetailsInState = checkout?.deliveryDetails;
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);

	useEffect(() => {
		doScrollToTop();
		//status check is protecting against returning to shop after placing an order
		!userIsLoggedIn && !isGuestStart
			? navigate('/checkout/login')
			: anOrderHasBeenInitiated && isOnlinePayment //? stockIsAvailable : true)
			  ? // perhaps we need to pass state back and forth
			    // so that details don't need to be re-entered again
			    navigate('/checkout/payment')
			  : progressedToReviewScreen
			    ? navigate('/checkout/review')
			    : navigate('/checkout');
	}, [
		userIsLoggedIn,
		anOrderHasBeenInitiated,
		progressedToReviewScreen,
	]);

	const handleNextStep = async (values) => {
		store.dispatch(setCheckoutLoading(true));
		const { shipping, billing, personalDetails } = values;

		const shippingDetails = isBillingSame
			? billing
			: shipping;
		store.dispatch(
			billingDetailsForOrderUser({
				billing: {
					...billing,
					country: {
						id: 101,
					},
				},
			}),
		);
		store.dispatch(
			deliveryDetailsForOrderUser({
				shipping: {
					...shippingDetails,
					phone_number: shipping.phone_number,
					country: {
						id: 101,
					},
				},
			}),
		);
		store.dispatch(
			personalDetailsForOrderUser(personalDetails),
		);
		const phone_number = shipping.phone_number;
		const orderDetails = {
			shipping: selectedDelivery?.is_locker
				? convertLockerToShippingAddress({
						lockerDetails: selectedLocker,
				  })
				: {
						...shippingDetails,
						country: checkout?.deliveryDetails?.countryId,
				  },
			billing: {
				...billing,
				country: billing?.countryId,
			},
			name: personalDetails.name,
			company_name:
				personalDetails?.company_name === '' ||
				personalDetails?.company_name === undefined
					? null
					: personalDetails?.company_name,
			email: accountDetails?.email ?? personalDetails.email,
			phone_number: phone_number ?? null,
			tax_number:
				personalDetails?.tax_number === '' ||
				personalDetails?.tax_number === undefined
					? null
					: personalDetails?.tax_number.replace(/-/g, ''),
		};

		if (businessDetails?.isBusiness) {
			const { payload } = await store.dispatch(
				validateTaxNumber({
					tax_number: orderDetails.tax_number,
					company_name: orderDetails.name,
				}),
			);
			// todo: enhance this once issue is resolved
			if (payload === 'invalid') {
				store.dispatch(setCheckoutLoading(false));
				return;
			}
		}
		if (isOnlinePayment) {
			await placeOrder({
				name: personalDetails.name,
				company_name: orderDetails.company_name,
				shipping: orderDetails.shipping,
				billing: orderDetails.billing,
				email: orderDetails.email,
				payment_method_id: selectedPaymentMethod?.id ?? '',
				password: personalDetails.password,
				password_confirmation:
					personalDetails.password_confirmation,
				cartItems,
				selectedDelivery,
				shouldSubscribe,
				selectedLocker,
				deliveryDetails: checkout.deliveryDetails,
				phone_number,
				promo_code: discount?.code,
				userDetails,
				tax_number: orderDetails.tax_number,
				navigate,
				setIsGuestStart,
			});
		} else {
			doScrollToTop();
			store.dispatch(setCanProgress(true));
		}

		store.dispatch(setCheckoutLoading(false));
	};

	const [isCartSummaryOpen, setIsCartSummaryOpen] =
		useState(false);
	const toggleCartSummaryDialog = () => {
		setIsCartSummaryOpen((prevValue) => !prevValue);
	};

	const { items: countries } = useSelector(
		(state: RootState) => state.checkout.countries,
	);

	useEffect(() => {
		if (!countries) {
			store.dispatch(fetchCountries());
		}
	}, []);

	useEffect(() => {
		if (selectedDelivery?.is_locker) {
			getLocation();
		}
	}, [selectedDelivery]);

	const initialCheckoutValues = {
		personalDetails: {
			name:
				accountDetails?.name ??
				personalDetailsInState?.name ??
				'',
			email:
				accountDetails?.email ??
				personalDetailsInState?.email ??
				'',
			phone_number: accountDetails?.phone_number ?? '',
			tax_number: businessDetails?.tax_number.value ?? '',
			company_name: businessDetails?.company_name ?? '',
			password: personalDetailsInState?.password ?? '',
			password_confirmation:
				personalDetailsInState?.password_confirmation ?? '',
		},
		shipping: {
			street: deliveryDetailsInState?.street ?? '',
			street_extra:
				deliveryDetailsInState?.street_extra ?? '',
			city: deliveryDetailsInState?.city ?? '',
			post_code: deliveryDetailsInState?.post_code ?? '',
			state: deliveryDetailsInState?.state ?? '',
			phone_number:
				deliveryDetailsInState?.phone_number ?? '',
			country: {
				id: 101,
				name: 'Hungary',
				code: 'HU',
			},
			delivery_method_id: selectedPaymentMethod?.id ?? '',
		},
		billing: {
			street: billingDetailsInState?.street ?? '',
			street_extra:
				billingDetailsInState?.street_extra ?? '',
			city: billingDetailsInState?.city ?? '',
			post_code: billingDetailsInState?.post_code ?? '',
			state: billingDetailsInState?.state ?? '',
			country: {
				id: 101,
				name: 'Hungary',
				code: 'HU',
			},
		},
		payment_method_id: selectedPaymentMethod?.id ?? '',
	};
	const combinedCheckoutValidationSchema = () => {
		const personalDetailsSchema =
			personalDetailsValidationSchema(
				translations,
				businessDetails.isBusiness,
				userIsLoggedIn,
			);

		const deliverySchema = isBillingSame
			? phoneNumberAndDeliveryMethodValidation(translations)
			: deliveryDetailsValidationSchema(
					translations,
					selectedDelivery?.is_locker ||
						selectedDelivery?.is_cod,
			  );

		const billingSchema =
			billingDetailsValidationSchema(translations);
		const paymentMethodSchema =
			paymentMethodValidation(translations);
		return Yup.object().shape({
			personalDetails: personalDetailsSchema,
			shipping: deliverySchema,
			billing: billingSchema,
			payment_method_id: paymentMethodSchema,
		});
	};
	return numOfItemsInCart > 0 ? (
		<Grid
			container
			maxWidth="lg"
			columnSpacing={isSmallScreen ? 0 : 2}
			margin="0 auto"
			width="100%"
			justifyContent="space-between"
		>
			<Typography
				variant="h5"
				component="h2"
				sx={{
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: isSmallScreen ? 2 : '16px',
					marginTop: isSmallScreen ? 2 : 0,
					width: isSmallScreen ? 'max-content' : '100%',
					paddingX: 2,
				}}
			>
				{translations.shop.checkout}
			</Typography>

			{isSmallScreen ? (
				<>
					<Grid
						width="max-content"
						alignItems="center"
						container
						paddingX={2}
					>
						<Button
							onClick={toggleCartSummaryDialog}
							variant="brp-yellow"
							component="h5"
						>
							{translations.shop.see_cart}
						</Button>
					</Grid>
					<Dialog
						open={isCartSummaryOpen}
						onClose={toggleCartSummaryDialog}
						fullScreen
					>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={toggleCartSummaryDialog}
								aria-label="close"
							>
								<GridCloseIcon />
							</IconButton>
						</Toolbar>
						<CartSummary
							isCheckout={true}
							disableQuantityButtons={
								!!progressedToReviewScreen
							}
						/>
					</Dialog>
				</>
			) : progressedToReviewScreen ? null : (
				<Grid item md={4} order={2}>
					<CartSummary
						isCheckout={true}
						disableQuantityButtons={
							!!progressedToReviewScreen
						}
					/>
				</Grid>
			)}
			{!userIsLoggedIn && !isGuestStart ? (
				<Grid container md={8} xs={12} order={1}>
					<Grid container flexDirection="column">
						{fromReviewScreen ? (
							<Typography
								variant="body1"
								component="p"
								sx={{ paddingLeft: 2 }}
							>
								{translations.shop.account_notice_1}
							</Typography>
						) : null}
						<Typography
							variant="body1"
							component="p"
							sx={{ paddingLeft: 2 }}
						>
							{fromReviewScreen
								? translations.shop.account_notice_2
								: translations.shop.login_notice}
							<span
								onClick={() => {
									setIsGuestStart(true);
									navigate('/checkout');
								}}
								style={{
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
							>
								{currentLocale === 'hu'
									? fromReviewScreen
										? 'ide kattintva'
										: 'kattints ide'
									: fromReviewScreen
									  ? 'by clicking here'
									  : 'click here'}
							</span>
							.
						</Typography>
						<section style={{ marginRight: '16px' }}>
							<LoginForm
								translations={translations}
								isSmallScreen={isSmallScreen}
								isCheckout
							/>
						</section>
					</Grid>
				</Grid>
			) : (
				<Grid
					item={!isSmallScreen}
					// item
					md={8}
					xs={12}
					order={1}
					sx={{ marginBottom: 4 }}
				>
					{progressedToReviewScreen ? (
						<>
							<DetailsSummary />
							<CartSummarySection />
							{currentOrder?.payment?.service_payment_id ? (
								<Grid container flexDirection="column">
									<ConfirmDetails
										isOnlinePayment={isOnlinePayment}
										showButton={false}
										setIsGuestStart={setIsGuestStart}
									/>
									<PaymentDetails />
								</Grid>
							) : (
								<ConfirmDetails
									showButton
									setIsGuestStart={setIsGuestStart}
								/>
							)}
						</>
					) : (
						<Formik
							onSubmit={handleNextStep}
							initialValues={initialCheckoutValues}
							validationSchema={
								combinedCheckoutValidationSchema
							}
						>
							{({ errors }) => {
								return (
									<Form>
										<PersonalDetails
											translations={translations}
										/>
										{/* Billing */}
										<DeliveryDetails
											isDelivery={false}
											isCheckout={true}
											translations={translations}
										/>

										{/* Delivery */}
										<DeliveryDetails
											isLocker={
												selectedDelivery?.is_locker ?? false
											}
											isPickup={
												selectedDelivery?.is_pickup ?? false
											}
											isCod={
												selectedDelivery?.is_cod ?? false
											}
											isDelivery={true}
											isCheckout={true}
											translations={translations}
										/>
										<PaymentMethods />
										<LoadingButton
											color="secondary"
											type="submit"
											onClick={() =>
												scrollToElementWhenErrors({
													errors,
												})
											}
											loading={isCheckoutLoading}
											loadingPosition="start"
											startIcon={<SaveIcon />}
											variant="brp-yellow"
											sx={{
												marginLeft: isSmallScreen ? 2 : 0,
											}}
											className="capitalise"
										>
											<span style={{ marginTop: 2 }}>
												{translations.buttons.review_order}
											</span>
										</LoadingButton>
									</Form>
								);
							}}
						</Formik>
					)}
				</Grid>
			)}
		</Grid>
	) : (
		<Grid
			container
			maxWidth="lg"
			justifyContent="center"
			margin="0 auto"
		>
			<EmptyCart />
		</Grid>
	);
};
