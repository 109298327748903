import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
	RootState,
	store,
	updateCartPopover,
} from '../../../../redux';
import { useSelector } from 'react-redux';

export const EmptyCart = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const navigate = useNavigate();

	const navigateToShop = () => {
		navigate('/shop/products');
		store.dispatch(updateCartPopover(false));
	};
	return (
		<Stack
			height="100%"
			alignItems="center"
			justifyContent="center"
			padding={2}
			minWidth={200}
		>
			<Typography variant="h6" component="p">
				{prompts[currentLocale].shop.nothing_in_cart}
			</Typography>
			<Button
				variant="brp-white"
				sx={{ marginTop: 1 }}
				size="small"
				onClick={navigateToShop}
			>
				{prompts[currentLocale].shop.to_shop}
			</Button>
		</Stack>
	);
};
