import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	getSpecificBlogPost,
	store,
} from '../../redux';
import { useParams } from 'react-router-dom';
import { NoItem } from '../../components';
import { dateFormatter } from '../../helpers';
import { parseHtml } from '../helpers';
import { useEffect } from 'react';

export const BlogPost = () => {
	const { id } = useParams();
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const blogState = useSelector(
		(state: RootState) => state.generic.blog,
	);
	let selectedBlogpost = blogState.selectedPost;
	const isBeingFetched = blogState.isBeingFetched;
	useEffect(() => {
		if (!selectedBlogpost) {
			const postIdNumber = id ? parseInt(id) : undefined;
			if (postIdNumber) {
				store.dispatch(getSpecificBlogPost(postIdNumber));
			}
		}
	}, []);
	return isBeingFetched ? (
		<p>Loading..</p>
	) : selectedBlogpost ? (
		<Grid
			container
			margin="0 auto 32px"
			maxWidth="lg"
			sx={{ padding: 4 }}
			flexDirection="column"
		>
			<Typography variant="h4" component="h3">
				{selectedBlogpost.title}
			</Typography>
			{/* date */}
			<Typography variant="body1" component="h4">
				{dateFormatter(selectedBlogpost.updated_at)}
			</Typography>
			<section
				className="blog description"
				style={{ marginTop: 1, marginBottom: 2 }}
			>
				{parseHtml(selectedBlogpost.post)}
			</section>
			{/* this is a post :{selectedBlogpost?.id ?? 'none'} */}
		</Grid>
	) : (
		<NoItem translations={translations} />
	);
};
