import { Grid, MenuItem, Select } from '@mui/material';
import {
	SortOrder,
	SortType,
	applySort,
	applyVehicleSort,
	fetchFilteredProducts,
	fetchFilteredVehicles,
	store,
} from '../../../redux';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from '../../../helpers';

type SortOption = {
	type: SortType;
	order: SortOrder;
	label: string;
};

export const Sort = ({
	setIsLoading,
	isColumnDisplay = false,
	isVehicles = false,
}) => {
	const translations = useTranslation();
	const [selectedOption, setSelectedOption] =
		useState<string>('');
	const [open, setOpen] = useState(false);

	const performSort = async (
		order: SortOrder,
		type: SortType,
	) => {
		setIsLoading(true);
		const sort = { order, type };

		store.dispatch(
			isVehicles ? applyVehicleSort(sort) : applySort(sort),
		);

		isVehicles
			? await store.dispatch(
					fetchFilteredVehicles({
						sort,
						filters:
							store.getState().products.filtersApplied,
					}),
			  )
			: await store.dispatch(
					fetchFilteredProducts({
						sort,
						filters:
							store.getState().products.filtersApplied,
					}),
			  );

		setIsLoading(false);
	};

	const handleSortChange = (event: any) => {
		const value = event.target.value as string;
		setSelectedOption(value);
		const [type, order] = value.split('-') as [
			SortType,
			SortOrder,
		];
		performSort(order, type);
	};

	const generateSortOptions = (): SortOption[] => {
		return [
			{
				type: 'name',
				order: 'ASC',
				label: translations.sort.a_to_z,
			},
			{
				type: 'name',
				order: 'DESC',
				label: translations.sort.z_to_a,
			},
			{
				type: 'price',
				order: 'DESC',
				label: translations.sort.price_high_to_low,
			},
			{
				type: 'price',
				order: 'ASC',
				label: translations.sort.price_low_to_high,
			},
			{
				type: isVehicles ? 'year' : 'created_at',
				order: 'DESC',
				label: translations.sort.newest,
			},
			{
				type: 'views',
				order: 'DESC',
				label: translations.sort.most_popular,
			},
		];
	};

	const handleDropdownToggle = () => {
		setOpen(!open);
	};

	const getDisplayValue = () => {
		if (!selectedOption) {
			return translations.sort.label;
		}
		const selected = generateSortOptions().find(
			(option) =>
				`${option.type}-${option.order}` === selectedOption,
		);
		return selected
			? `${translations.sort.label}: ${selected.label}`
			: translations.sort.label;
	};

	return (
		<Grid
			container
			sx={{
				width: 'max-content',
				// maxWidth: '100vw',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				display: 'flex',
				textAlign: 'center',
				paddingRight: { xs: 0, md: 2 },
				paddingLeft: 2,
				// marginBottom: isColumnDisplay ? 0 : 1,
				// paddingBottom: isColumnDisplay ? 0 : 2,
				flexWrap: 'wrap',
				flexDirection: isColumnDisplay ? 'column' : 'row',
			}}
		>
			<Select
				value={selectedOption}
				onChange={handleSortChange}
				open={open}
				onClick={handleDropdownToggle}
				displayEmpty
				renderValue={getDisplayValue}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
				}}
				sx={{
					border: 'none',
					boxShadow: 'none',
					'& .MuiOutlinedInput-notchedOutline': {
						border: 'none',
					},
					'& .MuiSelect-icon': {
						transition: 'transform 0.3s',
						cursor: 'pointer',
					},
					'&.Mui-focused .MuiSelect-icon': {
						transform: open
							? 'rotate(180deg)'
							: 'rotate(0deg)',
					},
					'& .MuiSelect-select': {
						paddingY: '8px',
						paddingX: '0px !important',
						display: 'flex',
						alignItems: 'center',
						border: 'none',
					},
				}}
				IconComponent={() => (
					<span
						onClick={handleDropdownToggle}
						style={{ cursor: 'pointer', display: 'grid' }}
					>
						{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</span>
				)}
			>
				{generateSortOptions().map((option, index) => (
					<MenuItem
						key={index}
						value={`${option.type}-${option.order}`}
					>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</Grid>
	);
};
