import { useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import {
	RootState,
	decrementQuantity,
	incrementQuantity,
	store,
	trackProductQuantity,
	updateDialogDetails,
} from '../../redux';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../helpers';

export const QuantityButtons = ({
	isShop = false,
	isCart = false,
	productId,
	currentQuantity,
	maximumQuantity,
	style = {},
	disableQuantityButtons = false,
}) => {
	const translations = useTranslation();
	const [counter, setCounter] = useState(
		currentQuantity ?? 1,
	);
	const updatedQuantities = useSelector(
		(state: RootState) => state.cart.updatedQuantities,
	);
	const updatedQuantity = updatedQuantities?.find(
		(product) => product.id === productId,
	);
	const handleIncrement = () => {
		if (
			(counter + 1 <= maximumQuantity &&
				updatedQuantity === undefined) ||
			(updatedQuantity &&
				updatedQuantity.quantity + 1 <= maximumQuantity)
		) {
			store.dispatch(
				trackProductQuantity({
					id: productId,
					quantity: counter + 1,
				}),
			);
			if (isCart) {
				store.dispatch(
					incrementQuantity({ id: productId }),
				);
			}
			setCounter((prevState) => (prevState += 1));
		} else {
			store.dispatch(
				updateDialogDetails({
					visible: true,
					content: translations.shop.no_more_can_be_added,
					isError: true,
				}),
			);
		}
	};
	const handleDecrement = () => {
		store.dispatch(
			trackProductQuantity({
				id: productId,
				quantity: counter - 1 < 1 ? 1 : counter - 1,
			}),
		);
		if (isCart) {
			store.dispatch(decrementQuantity({ id: productId }));
		}
		setCounter((prevState) =>
			prevState - 1 < 1 ? 1 : prevState - 1,
		);
	};
	return (
		<ButtonGroup
			style={style}
			sx={isShop ? { width: '100%', display: 'flex' } : {}}
		>
			<Button
				variant="brp-grey"
				onClick={handleDecrement}
				disabled={counter <= 1 || disableQuantityButtons}
				sx={isShop ? { width: 'max-content' } : {}}
			>
				-
			</Button>
			<div
				style={
					isShop
						? {
								flex: 1,
								display: 'flex',
								justifyContent: 'center',
						  }
						: {}
				}
			>
				<Button
					style={
						isShop
							? { width: '96%', margin: '0 auto' }
							: { marginLeft: '4px', marginRight: '4px', height: '100%' }
					}
					variant="brp-white"
					disabled
				>
					{counter}
				</Button>
			</div>
			<Button
				variant="brp-grey"
				sx={isShop ? { width: 'max-content' } : {}}
				onClick={handleIncrement}
				disabled={disableQuantityButtons}
			>
				+
			</Button>
		</ButtonGroup>
	);
};
