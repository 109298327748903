import {
	Button,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
	Delete,
	QuantityButtons,
} from '../../../components';
import { useSelector } from 'react-redux';
import {
	RootState,
	calculateDiscountPrice,
	getProductDetailsForCartItems,
} from '../../../redux';
import { Link, useNavigate } from 'react-router-dom';
import {
	CostSummary,
	EmptyCart,
	ExpandableCell,
} from './components';
import { colors } from '../../../themes';
import { formatPriceToForint } from '../../../helpers';
import notFound from '../../../assets/brp_world.png';

const Buttons = ({
	navigateToShop,
	navigateToCheckout,
	prompts,
	currentLocale,
}) => {
	return (
		<Grid
			container
			padding={0}
			sx={{
				justifyContent: {
					xs: 'space-between',
					md: 'flex-start',
				},
				width: { xs: '100%', md: 'max-content' },
			}}
		>
			<Button
				variant="brp-white"
				onClick={navigateToShop}
				size="small"
				sx={{ marginRight: 2 }}
			>
				{prompts[currentLocale].shop.continue_shopping}
			</Button>
			<Button
				variant="brp-yellow"
				onClick={navigateToCheckout}
				size="medium"
			>
				{prompts[currentLocale].shop.to_checkout}
			</Button>
		</Grid>
	);
};

export const CartItemsForSmallScreen = ({
	translations,
	items,
	removeButtons = false,
}) => (
	<Grid container>
		{items.map((item) => (
			<Grid
				container
				flexDirection="row"
				key={item.id}
				alignItems="center"
				sx={{ marginBottom: 4 }}
			>
				<Link
					to={`/shop/products/${item.id}`}
					style={{
						textAlign: 'center',
						flex: 1,
					}}
				>
					<img
						src={item?.image ?? notFound}
						style={{
							maxWidth: '45vw',
							maxHeight: '200px',
							objectFit: 'contain',
						}}
						alt={item.name}
					/>
				</Link>
				<Grid container style={{ flex: 1 }}>
					<Typography variant="h5" component="p">
						{item.name}
					</Typography>
					<Grid
						container
						alignItems="center"
						justifyContent="flex-start"
						flexWrap="wrap"
					>
						{item?.active_discount ? (
							<Grid
								container
								alignItems="center"
								justifyContent="flex-start"
							>
								<Typography
									variant="h5"
									component="p"
									style={{ marginRight: '16px' }}
								>
									{calculateDiscountPrice(item)}
								</Typography>
								<Typography
									variant="body1"
									style={{
										textDecoration: 'line-through',
										color: colors['brp-grey'].faded,
									}}
								>
									{formatPriceToForint(item.price)}
								</Typography>
							</Grid>
						) : (
							<Typography variant="h6" component="span">
								{formatPriceToForint(item.price)}
							</Typography>
						)}
					</Grid>
					<Grid container>
						<Typography variant="body2" component="p">
							{translations.shop.vat}:{' '}
							{formatPriceToForint(
								item.current_price_vat_inclusive,
							)}
						</Typography>
					</Grid>
					{removeButtons ? null : (
						<QuantityButtons
							isCart={true}
							productId={item.id}
							currentQuantity={item.quantity}
							maximumQuantity={item.available_stock}
							style={{ height: 24 }}
						/>
					)}
					{removeButtons ? null : (
						<Grid container>
							<Delete
								text={translations.shop.delete}
								style={{ marginTop: '8px', fontSize: 20 }}
								productId={item.id}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		))}
	</Grid>
);

export const Cart = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const vatPercentage = useSelector(
		(state: RootState) =>
			state.generic.settings.details?.tax_rate,
	);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	const navigate = useNavigate();
	const cartItems = useSelector(
		getProductDetailsForCartItems,
	);

	const navigateToCheckout = () => {
		navigate('/checkout');
	};
	const navigateToShop = () => {
		navigate('/shop/products');
	};

	const columns: GridColDef[] = [
		{
			field: 'product',
			renderHeader: () => (
				<Typography
					flexWrap="wrap"
					variant="h6"
					component="h4"
				>
					{prompts[currentLocale].shop.product}
				</Typography>
			),
			// width: 400,
			flex: 1,
			hideable: false,
			disableColumnMenu: true,
			renderCell: (params) => (
				<Grid container sx={{ paddingY: 1 }}>
					<Grid item xs={4} md={2}>
						<Link to={`/shop/products/${params.row.id}`}>
							<img
								src={params.row?.image ?? notFound}
								alt={params.row.name}
								style={{
									width: '50px',
									height: '50px',
									objectFit: 'contain',
								}}
							/>
						</Link>
					</Grid>
					<Grid item xs={8} md={10}>
						<Link to={`/shop/products/${params.row.id}`}>
							<Typography
								flexWrap="wrap"
								variant="body1"
								style={{ marginTop: 'auto' }}
							>
								{params.row.name}
							</Typography>
						</Link>
						<ExpandableCell
							prompts={prompts}
							currentLocale={currentLocale}
							value={params.row.description}
						/>
					</Grid>
				</Grid>
			),
		},
		{
			field: 'price',
			renderHeader: () => (
				<Typography
					flexWrap="wrap"
					variant="h6"
					component="h4"
				>
					{prompts[currentLocale].shop.price}
				</Typography>
			),
			width: 150,
			disableColumnMenu: true,
			hideable: false,
			renderCell: (params) => {
				const product = params.row;
				return product?.active_discount ? (
					<Grid
						container
						alignItems="center"
						justifyContent="flex-start"
					>
						<Typography
							variant="h6"
							component="span"
							style={{ marginRight: '16px' }}
						>
							{calculateDiscountPrice(product)}
						</Typography>
						<Typography
							variant="body1"
							style={{
								textDecoration: 'line-through',
								color: colors['brp-grey'].faded,
							}}
						>
							{formatPriceToForint(product.price)}
						</Typography>
					</Grid>
				) : (
					<Typography variant="h6" component="span">
						{formatPriceToForint(product.price)}
					</Typography>
				);
			},
		},
		{
			sortable: false,
			disableColumnMenu: true,
			filterable: false,
			hideable: false,
			field: 'vat',
			renderHeader: () => (
				<Typography
					flexWrap="wrap"
					variant="h6"
					component="h4"
				>
					{prompts[currentLocale].shop.vat}
				</Typography>
			),
			width: 150,
			renderCell: (params) => {
				const product = params.row;
				return (
					<Grid container flexWrap="wrap">
						{product?.active_discount ? (
							<Typography variant="h6" component="span">
								{formatPriceToForint(
									product.current_price_vat_inclusive,
								)}
							</Typography>
						) : (
							<Typography variant="h6" component="span">
								{formatPriceToForint(
									product.current_price_vat_inclusive,
								)}
							</Typography>
						)}
						<Typography
							variant="h6"
							component="span"
							marginLeft={2}
						>
							({vatPercentage}%)
						</Typography>
					</Grid>
				);
			},
		},
		{
			field: 'quantity',
			renderHeader: () => (
				<Typography
					flexWrap="wrap"
					variant="h6"
					component="h4"
				>
					{prompts[currentLocale].shop.quantity}
				</Typography>
			),
			width: 175,
			hideable: false,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			renderCell: (params) => {
				return (
					<QuantityButtons
						isCart={true}
						productId={params.row.id}
						currentQuantity={params.row.quantity}
						maximumQuantity={params.row.available_stock}
					/>
				);
			},
		},
		{
			sortable: false,
			disableColumnMenu: true,
			filterable: false,
			hideable: false,
			field: 'actions',
			headerName: '',
			width: 100,
			renderCell: (params) => (
				<Delete
					text={prompts[currentLocale].shop.delete}
					style={{ fontSize: 16 }}
					productId={params.row.id}
				/>
			),
		},
	];
	return (
		<Grid
			container
			maxWidth="lg"
			flexDirection="column"
			alignItems="flex-end"
			sx={{
				margin: '0 auto 32px',
			}}
		>
			<Grid
				container
				sx={{
					margin: '16px auto',
					marginBottom: isSmallScreen ? 0 : '16px',
					justifyContent: 'space-between',
					padding: 2,
					paddingTop: 0,
				}}
				flexDirection={isSmallScreen ? 'column' : 'row'}
			>
				<Typography
					variant="h5"
					component="h4"
					textTransform="uppercase"
				>
					{prompts[currentLocale].shop.shopping_cart}
				</Typography>
				{cartItems.items.length > 0 ? (
					<Buttons
						prompts={prompts}
						currentLocale={currentLocale}
						navigateToShop={navigateToShop}
						navigateToCheckout={navigateToCheckout}
					/>
				) : null}
			</Grid>
			{/* todo: decide whether this should be here or not */}
			{/* {cartItems.items.length > 0 && isSmallScreen && (
				<Grid container paddingX={2}>
					<CostSummary isCart={true} />
				</Grid>
			)} */}
			<Grid
				container
				sx={{
					height: {
						// xs: '100vh',
						md: '40vh',
					},
				}}
			>
				{isSmallScreen ? (
					<CartItemsForSmallScreen
						translations={prompts[currentLocale]}
						items={cartItems.items}
					/>
				) : (
					<DataGrid
						hideFooter
						getRowHeight={() => 'auto'}
						rows={cartItems.items}
						sx={{
							width: '100%',
							'--DataGrid-overlayHeight': '300px',
							'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
								{ outline: 'none !important' },
							'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within':
								{ outline: 'none' },
						}}
						columns={columns}
						slots={{
							noRowsOverlay: () => <EmptyCart />,
						}}
					/>
				)}
			</Grid>
			{cartItems.items.length > 0 && isSmallScreen ? (
				<Grid container paddingX={2}>
					<CostSummary isCart={true} />
				</Grid>
			) : cartItems.items.length > 0 ? (
				<CostSummary isCart={true} />
			) : null}
			{cartItems.items.length > 0 ? (
				<Grid
					container
					justifyContent="flex-end"
					paddingRight="16px"
					paddingY={2}
					sx={{ paddingLeft: { xs: 2, md: 0 } }}
				>
					<Buttons
						prompts={prompts}
						currentLocale={currentLocale}
						navigateToShop={navigateToShop}
						navigateToCheckout={navigateToCheckout}
					/>
				</Grid>
			) : null}
		</Grid>
	);
};
