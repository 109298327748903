import {
	Button,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	getNumberOfItemsInCart,
	getProductDetailsForCartItems,
} from '../../../../redux';
import {
	Delete,
	QuantityButtons,
} from '../../../../components';
import { CostSummary } from './CostSummary';
import notFound from '../../../../assets/brp_world.png';
import { Link, useNavigate } from 'react-router-dom';
import { formatPriceToForint } from '../../../../helpers';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../../themes';
import { parseHtml } from '../../../helpers';
import { CartItemsForSmallScreen } from '../Cart';

export const CartSummarySection = ({
	style = {},
}: {
	style?: object;
}) => {
	const navigate = useNavigate();
	const navigateToShop = () => navigate('/shop/products');
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const productsInCart = useSelector(
		getProductDetailsForCartItems,
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const items = isSmallScreen ? (
		<CartItemsForSmallScreen
			translations={translations}
			items={productsInCart.items}
			removeButtons
		/>
	) : (
		productsInCart.items.map((cartItem) => {
			return (
				<Grid
					container
					sx={{ marginTop: 2 }}
					key={cartItem.id}
					justifyContent={
						isSmallScreen ? 'space-between' : 'flex-start'
					}
					style={style}
				>
					<Link to={`/shop/products/${cartItem.id}`}>
						<img
							style={{
								maxWidth: '150px',
								objectFit: 'contain',
								marginRight: isSmallScreen ? 0 : '16px',
							}}
							src={cartItem?.image ?? notFound}
						/>
					</Link>
					<Grid
						container
						sx={{
							flex: 1,
							width: 'max-content',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<Link to={`/shop/products/${cartItem.id}`}>
							<Typography
								whiteSpace="normal"
								variant="body1"
								component="h4"
								sx={{
									textTransform: 'uppercase',
								}}
							>
								{cartItem.name}
							</Typography>
						</Link>
						<section
							className="description"
							style={{
								marginBottom: '16px',
								color: 'rgba(0, 0, 0, 0.6)',
							}}
						>
							{parseHtml(cartItem.description)}
						</section>
						<Grid container>
							<Typography
								variant="body1"
								component="h5"
								sx={{ marginBottom: '16px' }}
							>
								{formatPriceToForint(
									cartItem.current_price,
								)}
							</Typography>
							<Typography
								variant="body1"
								component="h5"
								sx={{
									marginBottom: '16px',
									marginLeft: 'auto',
								}}
							>
								{translations.shop.quantity}:{' '}
								{cartItem.quantity}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			);
		})
	);
	return (
		<Paper
			variant="outlined"
			square
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							padding: 4,
							marginBottom: 4,
					  }
			}
		>
			<Grid
				container
				sx={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					flexDirection: 'row',
					marginBottom: 1,
				}}
			>
				<Grid
					alignItems="center"
					minWidth="200px"
					container
					sx={{
						width: 'max-content',
					}}
				>
					<FontAwesomeIcon
						icon={faShoppingCart}
						style={{
							padding: 8,
							marginRight: 8,
							background: colors['brp-yellow'].main,
						}}
					/>
					<Typography
						variant="body1"
						component="span"
						sx={{
							textTransform: 'capitalize',
							color: colors['brp-black'].main,
						}}
						marginBottom="-5px"
					>
						{translations.shop.cart_summary}
					</Typography>
				</Grid>
			</Grid>
			<CartItems
				items={items}
				isSmallScreen={isSmallScreen}
			/>
		</Paper>
	);
};

export const CartItems = ({ isSmallScreen, items }) => (
	<Grid
		container
		flexWrap="nowrap"
		maxHeight={isSmallScreen ? 'none' : 'min(600px,60vh)'}
		sx={{ overflowY: 'scroll' }}
		flexDirection="column"
	>
		{items}
	</Grid>
);

export const CartSummary = ({
	isCheckout = false,
	isPopover = false,
	disableQuantityButtons = false,
	style = {},
}: {
	isCheckout?: boolean;
	isPopover?: boolean;
	disableQuantityButtons?: boolean;
	style?: object | undefined;
}) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const numberOfItemsInCart = useSelector(
		getNumberOfItemsInCart,
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const productsInCart = useSelector(
		getProductDetailsForCartItems,
	);

	const items = productsInCart.items.map((cartItem) => {
		return (
			<Grid
				container
				sx={{ my: 2, marginBottom: isPopover ? 0 : 2 }}
				key={cartItem.id}
				justifyContent={
					isSmallScreen ? 'space-between' : 'flex-start'
				}
				style={style}
			>
				<Link to={`/shop/products/${cartItem.id}`}>
					<img
						style={{
							maxWidth: '150px',
							objectFit: 'contain',
							marginRight: isSmallScreen ? 0 : '16px',
						}}
						src={cartItem?.image ?? notFound}
					/>
				</Link>
				<Grid
					container
					sx={{
						flexDirection: 'column',
						alignItems: 'flex-start',
						maxWidth: '50%',
					}}
				>
					<Link to={`/shop/products/${cartItem.id}`}>
						<Typography
							whiteSpace="normal"
							variant="body1"
							component="h4"
							sx={{
								wordBreak: 'break-word',
								textTransform: 'uppercase',
							}}
						>
							{cartItem.name}
						</Typography>
					</Link>
					<Typography
						variant="body1"
						component="h5"
						sx={{ marginBottom: '16px' }}
					>
						{formatPriceToForint(cartItem.current_price)}
					</Typography>
					<QuantityButtons
						style={{ marginBottom: '8px' }}
						currentQuantity={cartItem.quantity}
						maximumQuantity={cartItem.available_stock}
						isCart
						productId={cartItem.id}
						disableQuantityButtons={disableQuantityButtons}
					/>
					{!disableQuantityButtons && (
						<Delete
							style={{
								fontSize: isSmallScreen ? 20 : 'inherit',
							}}
							text={prompts[currentLocale].shop.delete}
							productId={cartItem.id}
						/>
					)}
				</Grid>
			</Grid>
		);
	});

	const stickyStyle = !isSmallScreen
		? {
				position: 'sticky',
				top: '16px',
		  }
		: {};

	return (
		<Grid
			container
			sx={{
				flexDirection: 'column',
				width: isSmallScreen ? '100%' : 400,
				marginBottom: isSmallScreen ? 2 : 0,
				...stickyStyle,
			}}
		>
			<Paper
				square
				variant="outlined"
				sx={
					isSmallScreen
						? {
								padding: 2,
								paddingTop: isPopover ? 2 : 0,
								boxSizing: 'border-box',
								border: 'none',
						  }
						: {
								padding: 2,
								boxSizing: 'border-box',
								marginRight: isCheckout ? 2 : 0,
						  }
				}
			>
				<Typography
					variant="body1"
					component="h5"
					style={{
						paddingBottom: '8px',
						borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
					}}
				>
					{prompts[currentLocale].shop.items_in_cart}:{' '}
					{numberOfItemsInCart}
				</Typography>
				<CartItems
					isSmallScreen={isSmallScreen}
					items={items}
				/>
				<CostSummary
					isPopover={isPopover}
					isCheckout={isCheckout}
				/>
			</Paper>
		</Grid>
	);
};
