import { Grid, Typography } from '@mui/material';
import { DynamicPage, Page, RootState } from '../../redux';
import { parseHtml } from '../helpers';

export const Dynamic = ({
	page,
}: {
	page: DynamicPage;
}) => {
	return (
		<Grid
			container
			flexDirection="column"
			alignItems="center"
			maxWidth="lg"
			sx={{ margin: '0 auto' }}
		>
			<Typography variant="h4" noWrap component="h2">
				{page.title}
			</Typography>
			<Typography variant="body1" noWrap component="h4">
				{page.subtitle}
			</Typography>
			{page.items.map((pageItem, index) => {
				return (
					<Grid
						key={`${pageItem.title}-${index}`}
						container
						flexDirection="column"
						maxWidth="lg"
						sx={{
							paddingY: { xs: 2, md: 4 },
							margin: '0 auto',
							paddingX: 2,
						}}
					>
						<Typography variant="h3" noWrap component="h3">
							{pageItem.title}
						</Typography>
						<section
							className="description"
							style={{ lineHeight: 1.5 }}
						>
							{parseHtml(pageItem.content)}
						</section>
					</Grid>
				);
			})}
		</Grid>
	);
};
