import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './gallery.module.scss';

type GalleryItem = {
	original: string;
	description?: string;
	fullscreen?: string; // fullscreen version of img
	isFullscreen?: boolean;
	originalAlt?: string;
	originalHeight?: string;
	originalWidth?: string;
	originalTitle?: string;
	sizes?: string;
	srcSet?: string;
	loading?: string;
	// handleImageLoaded: func.isRequired,
	// onImageError: func.isRequired,
};
interface GalleryProps {
	items?: GalleryItem[]; // Replace 'GalleryItem' with your actual type
	showFullscreenButton?: boolean;
	showPlayButton?: boolean;
	slideDuration?: number;
	slideInterval?: number;
	renderLeftNav?: () => React.Component;
	renderRightNav?: () => React.Component;
	thumbnailPosition?: 'bottom' | 'top' | 'left' | 'right';
}

export const Gallery: React.FC<GalleryProps> = ({
	items,
	...props
}) => (
	<ImageGallery
		additionalClass="image-gallery-thumbnails-wrapper"
		items={items}
		{...props}
	/>
);
