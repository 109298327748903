import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	RootState,
	passwordChange,
	store,
	updateUser,
} from '../../redux';
import {
	Error,
	ToggleablePasswordField,
} from '../../components';
import { LoadingButton } from '@mui/lab';
import { passwordChangeValidationSchema } from '../../helpers';

export const ChangePassword = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const accountDetails: any = useOutletContext();
	const token = useSelector(
		(state: RootState) => state.auth.token,
	);

	const validationSchema =
		passwordChangeValidationSchema(translations);

	return (
		<Grid container>
			<Formik
				initialValues={{
					current_password: '',
					password_new: '',
					password_confirmation: '',
				}}
				onSubmit={async (values, { resetForm }) => {
					await store.dispatch(
						passwordChange({
							...values,
							password: values.password_new,
							token,
						}),
					);
					resetForm();
				}}
				validationSchema={validationSchema}
			>
				{({ isSubmitting }) => (
					<Grid container maxWidth="sm">
						<Form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h5">
										{
											translations.forms
												.password_reset_title
										}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Field
										inputProps={{ tabIndex: 1 }}
										as={TextField}
										component={ToggleablePasswordField}
										type="password"
										name="current_password"
										fullWidth
										variant="outlined"
									/>
									<Error name="current_password" />
								</Grid>
								<Grid item xs={12}>
									<Field
										inputProps={{ tabIndex: 1 }}
										as={TextField}
										component={ToggleablePasswordField}
										type="password"
										name="password_new"
										fullWidth
										variant="outlined"
									/>
									<Error name="password" />
								</Grid>
								<Grid item xs={12}>
									<Field
										inputProps={{ tabIndex: 2 }}
										as={TextField}
										component={ToggleablePasswordField}
										type="password_confirmation"
										name="password_confirmation"
										fullWidth
										variant="outlined"
									/>
									<Error name="password_confirmation" />
								</Grid>
								<Grid item xs={12}>
									<LoadingButton
										style={{ textTransform: 'capitalize' }}
										type="submit"
										loading={isSubmitting}
										variant="brp-yellow"
									>
										<span>
											{translations.forms.password_reset}
										</span>
									</LoadingButton>
								</Grid>
							</Grid>
						</Form>
					</Grid>
				)}
			</Formik>
		</Grid>
	);
};
