import {
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
	PaymentMethod,
	RootState,
	fetchPaymentMethods,
	setPaymentMethod,
	store,
} from '../../../redux';
import { useSelector } from 'react-redux';
import { Error } from '../../../components';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../themes';

export const PaymentMethods = () => {
	const formikContext = useFormikContext();
	const { setFieldValue } = formikContext;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const paymentMethods = useSelector(
		(state: RootState) => state.checkout.paymentMethods,
	);
	useEffect(() => {
		if (!paymentMethods) {
			store.dispatch(fetchPaymentMethods());
		}
	}, []);

	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const selectedPaymentMethod = useSelector(
		(state: RootState) =>
			state.checkout.selectedPaymentMethod,
	);
	const PaymentMethodLabel = ({
		paymentMethod,
	}: {
		paymentMethod: PaymentMethod;
	}) => {
		return (
			<Grid container flexDirection="column">
				<Grid
					sx={{
						width: 'max-content',
						minWidth: '100px',
					}}
				>
					<Typography variant="h6" component="h5">
						{paymentMethod.name[currentLocale]}
					</Typography>
					{paymentMethod?.main_image?.src ? (
						<img
							src={paymentMethod?.main_image?.src}
							width="50"
							height="auto"
							style={{ marginBottom: '0px' }}
						/>
					) : null}
				</Grid>
				<Grid
					// item
					// xs={8}
					// md={10}
					flexDirection="column"
					sx={{ flex: 1 }}
				>
					<Typography variant="body1" component="h6">
						{paymentMethod.name[currentLocale]}
					</Typography>
					<Typography variant="body2" component="p">
						{paymentMethod.notes[currentLocale]}
					</Typography>
				</Grid>
			</Grid>
		);
	};
	const handlePaymentMethodSelection = (
		method: PaymentMethod,
	) => {
		setFieldValue('payment_method_id', method.id);
		store.dispatch(setPaymentMethod(method));
	};

	return !paymentMethods ? null : (
		<Paper
			variant="outlined"
			square
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							padding: 4,
							marginBottom: 4,
					  }
			}
		>
			<Grid
				container
				sx={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
				}}
			>
				<Grid
					alignItems="center"
					minWidth="200px"
					container
					sx={{
						width: 'max-content',
						marginBottom: 2,
					}}
				>
					<FontAwesomeIcon
						icon={faMoneyBill}
						style={{
							padding: 8,
							marginRight: 8,
							background: colors['brp-yellow'].main,
						}}
					/>
					<Typography
						variant="body1"
						component="span"
						sx={{
							textTransform: 'capitalize',
							color: colors['brp-black'].main,
						}}
					>
						{prompts[currentLocale].shop.payment_method}
					</Typography>
				</Grid>
			</Grid>
			<RadioGroup
				aria-label="position"
				sx={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					marginTop: 1,
				}}
				name='payment_method_id'
			>
				{paymentMethods.map((method) => {
					return (
						<React.Fragment key={method.id}>
							<FormControlLabel
								checked={
									method.id === selectedPaymentMethod?.id
								}
								sx={{
									marginLeft: 0,
									marginBottom: 1,
									width: '100%',
									justifyContent: 'space-between',
									cursor: 'default',
									'& .MuiFormControlLabel-label': {
										width: '100%',
									},
									paddingBottom: '4px',
								}}
								key={method.id}
								value={method.id}
								control={
									<Radio
										sx={{ padding: 0 }}
									/>
								}
								label={
									<PaymentMethodLabel
										paymentMethod={method}
									/>
								}
								onChange={() =>
									handlePaymentMethodSelection(method)
								}
								labelPlacement="start"
							/>
						</React.Fragment>
					);
				})}
			</RadioGroup>
			<Error name='payment_method_id' />
		</Paper>
	);
};
