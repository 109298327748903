import { Grid } from '@mui/material';
import { Sort, ProductView, PageSizeDropdown } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';

export const SortSection = ({
	isBelowLargeScreen,
	toggleFilterDialog,
	isVehicles,
	setIsLoading,
}) => {
	const vehicles = useSelector(
		(state: RootState) => state.generic.vehicles,
	);
	return !isVehicles ||
		(isVehicles && vehicles?.selectedCategory?.id !== 5) ? (
		<Grid
			container
			sx={{
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Sort
				setIsLoading={setIsLoading}
				isVehicles={isVehicles}
			/>

			{isBelowLargeScreen ? (
				<FontAwesomeIcon
					style={{
						fontSize: '24px',
						marginTop: '-5px',
						marginRight: 16,
					}}
					icon={faSlidersH}
					onClick={toggleFilterDialog}
				/>
			) : (
				<Grid
					container
					alignItems="center"
					sx={{ width: 'max-content' }}
				>
					{isVehicles ? null : <ProductView />}
					<PageSizeDropdown />
				</Grid>
			)}
		</Grid>
	) : null;
};
