import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	Attribute,
	ProductAttribute,
	RootState,
	fetchFilteredProducts,
	getAttributes,
	getFiltersAndSortApplied,
	store,
	toggleAttribute,
	resetFilter,
	Category,
	getNCategories,
	updateSelectedCategories,
	updateDisplayableProducts,
	toggleSale,
	toggleInStock,
	toggleLoadingSkeleton,
	resetForFilter,
	updateSelectedCategoriesViaUrl,
	fetchVehicleCategory,
	getUniqueAttributeValues,
	updatePageNumber,
	resetFiltersApplied,
	fetchVehiclesForCategory,
	updateSelectedVehicleCategory,
	toggleYear,
	resetVehicleFilters,
	fetchFilteredVehicles,
} from '../../../redux';
import {
	addOrUpdateParam,
	handleCategoryToggle,
	isAttributeInUrl,
} from '../helpers';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../../../themes';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { RangeSlider } from '.';
import styles from '../shop.module.scss';
import { FilterSkeleton } from '../../../components';
import {
	faCheckSquare,
	faSquare,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '../../../helpers';

export const GenericFilterCheckbox = ({
	label,
	checked,
	onChange,
	value,
	icon,
	checkedIcon,
	productAttribute,
}: {
	label: string;
	checked: boolean;
	onChange: any; //() => void;
	value: boolean;
	icon: any;
	checkedIcon: any;
	productAttribute?: ProductAttribute;
}) => (
	<FormGroup>
		<FormControlLabel
			sx={{ marginLeft: 0 }}
			control={
				<Checkbox
					icon={
						<FontAwesomeIcon
							icon={icon}
							size="2x"
							style={{
								color:
									productAttribute?.value?.en?.toLowerCase() ??
									'white',
								stroke: colors['brp-black'].main,
								strokeWidth: 10,
							}}
						/>
					}
					checkedIcon={
						<FontAwesomeIcon
							icon={checkedIcon}
							size="2x"
							style={{
								color:
									productAttribute?.value?.en?.toLowerCase() ??
									colors['brp-yellow'].main,

								stroke:
									productAttribute?.value?.en?.toLowerCase() ===
									'white'
										? colors['brp-black'].main
										: 'none',
								strokeWidth:
									productAttribute?.value?.en?.toLowerCase() ===
									'white'
										? 10
										: 0,
							}}
						/>
					}
					checked={checked}
					onChange={onChange}
					value={value}
				/>
			}
			label={label}
			labelPlacement={
				productAttribute?.attribute.name === 'colour'
					? 'bottom'
					: 'end'
			}
		/>
	</FormGroup>
);

const FilterCheckbox = ({
	productAttribute,
	currentLocale,
	handleAttributeToggle,
	filters,
}) => {
	const attributeValue =
		productAttribute.value?.[currentLocale] ??
		productAttribute.value;

	const attributeInUrl = isAttributeInUrl(
		productAttribute.value?.en ?? productAttribute.value,
	);
	return (
		<GenericFilterCheckbox
			label={
				productAttribute.value?.[currentLocale] ??
				productAttribute.value
			}
			checked={
				filters.attribute.includes(attributeValue) ||
				attributeInUrl
			}
			onChange={handleAttributeToggle}
			value={
				productAttribute.value?.en ?? productAttribute.value
			}
			icon={faSquare}
			checkedIcon={faCheckSquare}
			productAttribute={productAttribute}
		/>
	);
};

const AttributeAccordion = ({
	attribute,
	currentLocale,
	expanded,
	setExpanded,
	filteredAttributeValues,
}) => {
	const panelId = `panel-${attribute.id}`;
	return filteredAttributeValues.length > 0 ? (
		<Grid item xs={12} key={attribute.id}>
			<Accordion
				style={{ boxShadow: 'none' }}
				sx={{
					'& .Mui-expanded .MuiAccordionSummary-content': {
						marginY: 0,
					},
					// paddingX: 0,
				}}
				expanded={expanded === panelId}
				onChange={() =>
					setExpanded(
						expanded === panelId ? false : panelId,
					)
				}
			>
				<AccordionSummary
					expandIcon={<GridExpandMoreIcon />}
					sx={{
						'&.Mui-expanded': {
							minHeight: 0,
						},
						'& .MuiAccordionSummary-content.Mui-expanded': {
							margin: '12px 0',
						},
						paddingX: { xs: 0, md: 2 },
					}}
				>
					<Typography
						variant="body1"
						sx={{
							textAlign: 'left',
							opacity: '0.6',
							textTransform: 'uppercase',
						}}
					>
						{attribute.label[currentLocale]}
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						paddingTop: 0,
						display: 'flex',
						flexDirection:
							attribute.name.toLowerCase() === 'colour'
								? 'row'
								: 'column',
						flexWrap:
							attribute.name.toLowerCase() === 'colour'
								? 'wrap'
								: 'no-wrap',
					}}
				>
					{filteredAttributeValues}
				</AccordionDetails>
			</Accordion>
		</Grid>
	) : null;
};

export const VehicleCategories = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const translations = useTranslation();
	const parentCategories: any[] | undefined = useSelector(
		(state: RootState) =>
			state.generic.vehicles.parentCategories,
	);
	const queryParams = new URLSearchParams(location.search);
	const categoryId = queryParams.get('category');

	const showSelectedCategoryVehicles = async (category) => {
		store.dispatch(updatePageNumber(1));
		const categoryHasChildren = parentCategories
			? parentCategories.some(
					(categ) =>
						categ.id === category.id &&
						categ.children.length > 0,
			  )
			: false;

		if (!categoryHasChildren) {
			await store.dispatch(
				fetchFilteredVehicles({
					sort: store.getState().generic.vehicles
						.sortApplied,
					filters:
						store.getState().generic.vehicles
							.filtersApplied,
					categoryOverride: category.id,
				}),
			);
		}
		store.dispatch(updateSelectedVehicleCategory(category));

		// change details.id
		navigate(`/shop/vehicles?category=${category.id}`);
	};

	const categories = parentCategories
		? parentCategories.map(
				(
					category: Category | { id: number; name: string },
				) => {
					return (
						<Typography
							variant="h6"
							component="span"
							onClick={() =>
								showSelectedCategoryVehicles(category)
							}
							key={category.id}
							style={{
								cursor: 'pointer',
								textDecoration:
									categoryId &&
									parseInt(categoryId) === category.id
										? //||categoryIsInUrl
										  'underline'
										: 'none',
								textDecorationColor:
									categoryId &&
									parseInt(categoryId) === category.id
										? //||categoryIsInUrl
										  colors['brp-yellow'].main
										: 'inherit',
								textDecorationThickness:
									categoryId &&
									parseInt(categoryId) === category.id
										? //||categoryIsInUrl
										  3
										: 1,
								marginBottom: 1,
							}}
						>
							{category.name}
						</Typography>
					);
				},
		  )
		: [];

	return (
		<Grid
			container
			flexDirection="column"
			alignItems="flex-start"
			xs={12}
			// md={2}
			// lg={2}
			sx={{ padding: isSmallScreen ? 2 : 2, paddingTop: 0 }}
		>
			<Typography
				variant="h6"
				component="h6"
				marginBottom={4}
			>
				{translations.text.categories}
			</Typography>
			{categories}
		</Grid>
	);
};

export const MainCategories = ({ isVehicles = false }) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const selectedCategories = useSelector(
		(state: RootState) => {
			if (
				state.generic.categories?.selectedItems ===
				undefined
			) {
				return state.generic.categories?.selectedItems;
			}
			if (
				state.generic.categories?.selectedItems?.children &&
				state.generic.categories?.selectedItems?.children
					.length > 0
			) {
				return state.generic.categories.selectedItems
					?.children;
			}
			return [state.generic.categories?.selectedItems];
		},
	);
	const { lastSelected } = useSelector(
		(state: RootState) => state.generic.categories,
	);
	const mainCategories: Category[] = getNCategories(
		store.getState(),
		4,
	);

	let categoriesToUse =
		selectedCategories ?? mainCategories;

	const getChildrenForSelectedCategory = (
		category: Category,
	) => {
		store.dispatch(updatePageNumber(1));
		store.dispatch(resetFiltersApplied());
		store.dispatch(updateSelectedCategories(category));
		const { selectedItems, lastSelected } =
			store.getState().generic.categories;
		store.dispatch(
			updateDisplayableProducts({
				selectedItems,
				lastSelected,
			}),
		);
		handleCategoryToggle(category);
		navigate(`/shop/products?category=${category.id}`);
	};

	const categories = categoriesToUse.map(
		(category: Category) => {
			return (
				<Typography
					variant="h6"
					component="span"
					onClick={() =>
						getChildrenForSelectedCategory(category)
					}
					key={category.id}
					style={{
						cursor: category?.children
							? 'pointer'
							: 'not-allowed',
						textDecoration:
							lastSelected?.id === category.id
								? //||categoryIsInUrl
								  'underline'
								: 'none',
						textDecorationColor:
							lastSelected?.id === category.id
								? //||categoryIsInUrl
								  colors['brp-yellow'].main
								: 'inherit',
						textDecorationThickness:
							lastSelected?.id === category.id
								? //||categoryIsInUrl
								  3
								: 1,
						marginBottom: 1,
					}}
				>
					{category.name}
				</Typography>
			);
		},
	);

	return (
		<Grid
			item
			flexDirection="column"
			alignItems="flex-start"
			xs={12}
			md={isVehicles ? 4 : 12}
			lg={isVehicles ? 3 : 12}
			sx={{
				display: 'flex',
				paddingTop: isSmallScreen ? 2 : 0,
				paddingBottom: 2,
				paddingX: isSmallScreen ? 0 : 2,
			}}
		>
			{categories}
		</Grid>
	);
};

const ResetFilters = ({
	resetFilter,
	translations,
}: {
	resetFilter: () => void;
	translations: any;
}) => (
	<Grid
		container
		onClick={resetFilter}
		style={{
			width: 'max-content',
			cursor: 'pointer',
		}}
		sx={{ paddingX: { xs: 0, md: 2 } }}
	>
		<FontAwesomeIcon
			icon={faTimes}
			style={{
				color: colors['brp-grey'].main,
				marginRight: '8px',
			}}
		/>
		<Typography
			variant="body1"
			component="span"
			sx={{
				textAlign: 'left',
				color: colors['brp-grey'].contrastText,
				opacity: '0.6',
				marginTop: '-2px',
				textTransform: 'uppercase',
			}}
		>
			{translations.text.reset}
		</Typography>
	</Grid>
);

export const VehicleFilters = ({ setIsLoading }) => {
	const [expanded, setExpanded] = useState<string | false>(
		'panel-year',
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const panelYearId = 'panel-year';
	const navigate = useNavigate();

	const filtersApplied = useSelector(
		(state: RootState) =>
			state.generic.vehicles.filtersApplied,
	);
	const handleYearToggle = async (
		e,
		year: '2023' | '2024',
	) => {
		setIsLoading(true);

		store.dispatch(toggleYear({ yearToFetch: year }));
		await store.dispatch(
			fetchFilteredVehicles({
				sort: store.getState().generic.vehicles.sortApplied,
				filters:
					store.getState().generic.vehicles.filtersApplied,
			}),
		);
		setIsLoading(false);
	};
	const resetFilters = async () => {
		setIsLoading(true);

		store.dispatch(toggleLoadingSkeleton());
		store.dispatch(resetVehicleFilters());

		await store.dispatch(
			fetchFilteredVehicles({
				sort: store.getState().generic.vehicles.sortApplied,
				filters:
					store.getState().generic.vehicles.filtersApplied,
			}),
		);
		navigate('/shop/vehicles');
		store.dispatch(toggleLoadingSkeleton());
		setIsLoading(false);
	};
	return (
		<Grid container flexDirection="column">
			<ResetFilters
				resetFilter={resetFilters}
				translations={prompts[currentLocale]}
			/>
			<Accordion
				elevation={0}
				sx={{
					'&:before': { height: '0px' },
					width: '100%',
				}}
				style={{ boxShadow: 'none' }}
				disableGutters
				expanded={expanded === panelYearId}
				onChange={() =>
					setExpanded(
						expanded === panelYearId ? false : panelYearId,
					)
				}
			>
				<AccordionSummary
					expandIcon={<GridExpandMoreIcon />}
					sx={{
						'.MuiAccordionSummary-content.Mui-expanded': {
							margin: '12px 0',
						},
					}}
				>
					<Typography
						variant="body1"
						sx={{
							textAlign: 'left',
							opacity: '0.6',
							textTransform: 'uppercase',
						}}
					>
						{prompts[currentLocale].text.year}
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						paddingTop: 0,
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<GenericFilterCheckbox
						label="2023"
						checked={
							filtersApplied?.years.includes('2023') ??
							false
						}
						value={
							filtersApplied?.years.includes('2023') ??
							false
						}
						onChange={(e) => handleYearToggle(e, '2023')}
						icon={faSquare}
						checkedIcon={faCheckSquare}
					/>
					<GenericFilterCheckbox
						label="2024"
						checked={
							filtersApplied?.years.includes('2024') ??
							false
						}
						onChange={(e) => handleYearToggle(e, '2024')}
						value={
							filtersApplied?.years.includes('2024') ??
							false
						}
						icon={faSquare}
						checkedIcon={faCheckSquare}
					/>
				</AccordionDetails>
			</Accordion>
		</Grid>
	);
};

export const Filter = ({ setIsLoading }) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const [expanded, setExpanded] = useState<string | false>(
		false,
	);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const navigate = useNavigate();

	const sortAndfilters: any = useSelector(
		getFiltersAndSortApplied,
	);
	const { filters } = sortAndfilters;

	const isAttributeBeingFetched = useSelector(
		(state: RootState) =>
			state.shop.isAttributeBeingFetched,
	);
	const attributes = useSelector(
		(state: RootState) => state.shop.attributes,
	);
	const areAttributesPresent = !!attributes.all;
	const productAttributeValues = useSelector(
		(state: RootState) => state.products.attributeValues,
	);
	const products = useSelector(
		(state: RootState) => state.products.items,
	);

	useEffect(() => {
		if (products) {
			store.dispatch(getUniqueAttributeValues());
		}
	}, [products]);

	const showExtraAccordions = () => {
		const panelExtraId = 'panel-extra';
		return (
			<Grid item xs={12}>
				<Accordion
					style={{ boxShadow: 'none' }}
					sx={{
						'& .Mui-expanded .MuiAccordionSummary-content':
							{
								marginY: 0,
							},
					}}
					expanded={expanded === panelExtraId}
					onChange={() =>
						setExpanded(
							expanded === panelExtraId
								? false
								: panelExtraId,
						)
					}
				>
					<AccordionSummary
						expandIcon={<GridExpandMoreIcon />}
						sx={{
							'&.Mui-expanded': {
								minHeight: 0,
							},
							'& .MuiAccordionSummary-content.Mui-expanded':
								{
									margin: '12px 0',
								},
							paddingX: { xs: 0, md: 2 },
						}}
					>
						<Typography
							variant="body1"
							sx={{
								textAlign: 'left',
								opacity: '0.6',
								textTransform: 'uppercase',
							}}
						>
							{prompts[currentLocale].text.additional}
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							paddingTop: 0,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<GenericFilterCheckbox
							label={prompts[currentLocale].shop.in_stock}
							checked={filtersApplied?.inStock ?? false}
							onChange={handleInStockToggle}
							value={filtersApplied?.inStock ?? false}
							icon={faSquare}
							checkedIcon={faCheckSquare}
						/>
						<GenericFilterCheckbox
							icon={faSquare}
							checkedIcon={faCheckSquare}
							label={prompts[currentLocale].shop.on_sale}
							checked={
								filtersApplied?.active_discount ?? false
							}
							onChange={handleSaleToggle}
							value={
								filtersApplied?.active_discount ?? false
							}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
		);
	};

	useEffect(() => {
		// todo: does it need to be refetched everytime?
		if (!areAttributesPresent) {
			store.dispatch(getAttributes());
		}
	}, [areAttributesPresent]);

	const filtersApplied = useSelector(
		(state: RootState) => state.products.filtersApplied,
	);

	const handleSaleToggle = async () => {
		setIsLoading(true);

		store.dispatch(toggleSale());

		await store.dispatch(
			fetchFilteredProducts({
				filters: store.getState().products.filtersApplied,
				sort: store.getState().products.sortApplied,
			}),
		);
		setIsLoading(false);
	};
	const handleInStockToggle = async () => {
		setIsLoading(true);

		store.dispatch(toggleInStock());

		await store.dispatch(
			fetchFilteredProducts({
				filters: store.getState().products.filtersApplied,
				sort: store.getState().products.sortApplied,
			}),
		);
		setIsLoading(false);
	};
	const handlePriceFiltering = async () => {
		setIsLoading(true);

		await store.dispatch(
			fetchFilteredProducts({
				filters: store.getState().products.filtersApplied,
				sort: store.getState().products.sortApplied,
			}),
		);
		setIsLoading(false);
	};
	const handleAttributeToggle = async ({ target }) => {
		setIsLoading(true);
		store.dispatch(
			toggleAttribute({ value: target.value }),
		);

		// Modify URL when toggling color
		const currentURL = window.location.href;
		const updatedURL: object = addOrUpdateParam(
			'attribute',
			target.value,
			currentURL,
		);
		await store.dispatch(
			fetchFilteredProducts({
				filters: store.getState().products.filtersApplied,
				sort: store.getState().products.sortApplied,
			}),
		);
		navigate(updatedURL);
		setIsLoading(false);
	};
	const resetFilters = async () => {
		setIsLoading(true);

		store.dispatch(toggleLoadingSkeleton());
		store.dispatch(resetFilter());
		store.dispatch(resetForFilter());

		await store.dispatch(
			fetchFilteredProducts({
				filters: store.getState().products.filtersApplied,
				sort: store.getState().products.sortApplied,
			}),
		);
		navigate('/shop/products');
		store.dispatch(toggleLoadingSkeleton());
		setIsLoading(false);
	};
	const getFilterItems = () => {
		if (attributes.displayable && productAttributeValues) {
			const attributeValuesAlreadyReturned: string[] = [];
			const attributeFilters = attributes.displayable.map(
				(attribute: Attribute) => {
					const filteredAttributeValues =
						productAttributeValues.map(
							(productAttribute: ProductAttribute) => {
								if (
									productAttribute.attribute_id ===
										attribute.id &&
									!attributeValuesAlreadyReturned.includes(
										(
											productAttribute.value?.en ??
											productAttribute.value
										).toLowerCase(),
									)
								) {
									attributeValuesAlreadyReturned.push(
										(
											productAttribute.value?.en ??
											productAttribute.value
										).toLowerCase(),
									);
									return (
										<FilterCheckbox
											key={productAttribute.id}
											productAttribute={productAttribute}
											currentLocale={currentLocale}
											handleAttributeToggle={
												handleAttributeToggle
											}
											filters={filters}
										/>
									);
								}
								return null;
							},
						);

					return (
						<AttributeAccordion
							key={attribute.id}
							attribute={attribute}
							currentLocale={currentLocale}
							expanded={expanded}
							setExpanded={setExpanded}
							filteredAttributeValues={
								filteredAttributeValues
							}
						/>
					);
				},
			);

			return attributeFilters;
		}
		return <></>;
	};

	const location = useLocation();
	useEffect(() => {
		const categoryId = new URLSearchParams(
			location.search,
		).get('category');

		if (categoryId) {
			store.dispatch(
				updateSelectedCategoriesViaUrl(categoryId),
			);
		}
	}, []);

	return (
		<Grid
			item
			xs={12}
			// md={4}
			// lg={3}
			className={styles.filterContainer}
			sx={{
				minHeight: '30vh',
				width: '100%',
				// maxHeight: 'min(600px, 80vh)',
				height: 'max-content',
				alignItems: 'flex-start',
				display: 'flex',
				textAlign: 'center',
				flexDirection: 'column',
				position: isSmallScreen ? 'relative' : 'sticky',
				top: 0,
				// bottom: 15,
				boxSizing: 'border-box',
				padding: 0,
				overflowY: isSmallScreen ? 'visible' : 'auto',
			}}
			flexBasis={isSmallScreen ? 'auto' : '30% !important'}
		>
			<Grid
				container
				paddingTop={0}
				sx={{
					maxHeight: 'max(300px, 75vh)',
					marginBottom: '24px',
					// overflowY: 'scroll',
				}}
			>
				{isAttributeBeingFetched ? (
					<FilterSkeleton />
				) : (
					<Grid container padding={0} margin={0}>
						<Grid item xs={12}>
							{/* no resetting */}
							{/* {getMainCategories()} */}
							{/* {mainCategories()} */}
							<MainCategories />
							<ResetFilters
								resetFilter={resetFilters}
								translations={prompts[currentLocale]}
							/>
							{getFilterItems()}
							{showExtraAccordions()}
							<Grid
								item
								xs={12}
								paddingY={2}
								paddingX={isSmallScreen ? 0 : 2}
							>
								<Typography
									variant="body1"
									sx={{
										textAlign: 'left',
										color: colors['brp-grey'].contrastText,
										opacity: '0.6',
										textTransform: 'uppercase',
									}}
								>
									{prompts[currentLocale].shop.price}
								</Typography>
								<FormGroup sx={{ paddingX: 1 }}>
									<RangeSlider
										setFilterPrice={handlePriceFiltering}
									/>
								</FormGroup>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};
