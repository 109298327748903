import {
	Box,
	Button,
	Container,
	Grid,
	ListItem,
	Tab,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import {
	Product as ProductType,
	RootState,
	fetchProduct,
	store,
	selectCurrentProduct,
	calculateDiscountPrice,
	getImagesForProduct,
} from '../../../redux';
import { colors } from '../../../themes';
import {
	AddToCart,
	Gallery,
	QuantityButtons,
	ProductSkeleton,
} from '../../../components';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
	CustomBreadcrumbs,
	getCategoryChain,
	YouMayLike,
} from '../components';
import brpWorld_thumbnail from '../../../assets/brp.png';
import {
	VariantDropdown,
	getUniqueColors,
} from '../VariantDropdown';
import { parseHtml } from '../../helpers';
import {
	formatPriceToForint,
	useTranslation,
} from '../../../helpers';
import { useParams } from 'react-router-dom';

const Variants = ({ variants, colourToExclude }) => {
	const locale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const toExclude = colourToExclude.map(
		(colour) => colour[locale],
	);
	const colours = getUniqueColors({ variants, locale });
	const variantItems = colours.map((colour: string) => {
		if (toExclude.includes(colour)) return undefined;
		const productWithColour = variants.filter(
			(variant: ProductType) => {
				const colourVariant = variant.attributes.find(
					(attr) => attr.attribute.name === 'colour',
				)?.value;
				return colourVariant &&
					colourVariant[locale] === colour
					? colourVariant[locale]
					: undefined;
			},
		);
		const variant =
			productWithColour && productWithColour.length > 1
				? productWithColour[0]
				: productWithColour[0];
		return (
			<Link
				key={variant.id}
				to={`/shop/products/${variant.id}`}
			>
				<img
					style={{
						width: '48px',
						height: '48px',
						objectFit: 'contain',
					}}
					src={
						variant?.main_image?.src ?? brpWorld_thumbnail
					}
					alt="Variant"
				/>
			</Link>
		);
	});
	return (
		<Grid container marginTop={1} rowGap={1} columnGap={2}>
			{variantItems}
		</Grid>
	);
};

export const Product = () => {
	const productsState = useSelector(
		(state: RootState) => state.products,
	);
	const isFetching =
		productsState.currentProduct.isBeingFetched;
	const productToSwitchTo =
		productsState.currentProduct.variantSelected;

	const navigate = useNavigate();
	const theme = useTheme();
	const smallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const { state } = useLocation();
	const currentProduct: any = useSelector(
		selectCurrentProduct,
	).details;

	const params = useParams();
	const { id: urlId } = params;
	const id = state?.productId || urlId;

	useEffect(() => {
		if (!isNaN(id)) {
			store.dispatch(
				fetchProduct(parseInt(id) ?? currentProduct?.id),
			);
		}
	}, [id]);
	const categories = useSelector(
		(state: RootState) => state.generic.categories.items,
	);

	useEffect(() => {
		if (!categories.length || !currentProduct?.id) {
			return;
		}
		try {
			getCategoryChain(
				categories,
				currentProduct.category.id,
			);
		} catch (error) {
			console.error(
				'Failed to generate category chain:',
				error,
			);
		}
	}, [currentProduct, categories, id]);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const translations = useTranslation();

	const galleryImages = getImagesForProduct(currentProduct);
	const sizeGuideUrl = useSelector(
		(state: RootState) =>
			state.generic.settings.details?.size_chart?.src,
	);
	const [tabContextValue, setTabContextValue] =
		useState('1');
	const handleChange = (
		_event: React.SyntheticEvent,
		newTabContextValue: string,
	) => {
		setTabContextValue(newTabContextValue);
	};

	// we will exclude these colours from the variants section
	const coloursInAttribute =
		currentProduct &&
		currentProduct.attributes &&
		Array.isArray(currentProduct.attributes) &&
		Object.keys(currentProduct).length > 0
			? new Set(
					currentProduct.attributes
						.map((attr) =>
							attr &&
							attr.attribute &&
							attr.attribute.name === 'colour'
								? attr.value
								: undefined,
						)
						.filter(Boolean),
			  )
			: undefined;

	return isFetching ? (
		<ProductSkeleton />
	) : Object.keys(currentProduct)?.length > 1 ? (
		<Container>
			<Grid
				container
				style={{ width: '100%' }}
				sx={{ marginTop: { xs: 2, md: 0 } }}
			>
				<section
					onClick={() =>
						navigate(
							`/shop/products?category=${
								currentProduct.category?.parent_id ??
								currentProduct.category?.id
							}`,
						)
					}
					style={{
						width: 'max-content',
						paddingLeft: 6,
						display: 'flex',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<FontAwesomeIcon
						icon={faAngleLeft}
						size="1x"
						color={colors['brp-black'].main}
						style={{ marginTop: '-5px' }}
					/>
					<Typography
						gutterBottom
						variant="h6"
						component="p"
						sx={{
							margin: 0,
							marginLeft: '4px',
						}}
					>
						{translations.buttons.back}
					</Typography>
				</section>
				<CustomBreadcrumbs
					style={{ marginBottom: 0, width: 'max-content' }}
				/>
			</Grid>
			<Grid
				container
				maxWidth="lg"
				spacing={1}
				sx={{
					display: 'flex',
					padding: { md: 6 },
					justifyContent: 'space-between',
				}}
			>
				<Grid
					item
					xs={12}
					md={7}
					sx={{
						minHeight: '30vh',
						height: 'max-content',
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Gallery
						items={galleryImages}
						showPlayButton={false}
						showFullscreenButton={false}
						thumbnailPosition={
							smallScreen ? 'bottom' : 'left'
						}
					/>
				</Grid>
				<Grid
					xs={12}
					md={5}
					item
					sx={{
						height: 'max-content',
						padding: 2,
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<Grid container sx={{ flexDirection: 'column' }}>
						<Grid container justifyContent="space-between">
							<Typography
								component="p"
								color={
									currentProduct?.available_stock > 0 &&
									productToSwitchTo?.id !== -1
										? colors['green'].main
										: 'error'
								}
								sx={{ textTransform: 'capitalize' }}
							>
								•{' '}
								{currentProduct?.available_stock > 0 &&
								productToSwitchTo?.id !== -1
									? translations.shop.in_stock
									: translations.shop.out_of_stock}
							</Typography>
						</Grid>
						<Typography component="h2" variant="h4">
							{currentProduct?.name}
						</Typography>
						<Typography component="p">
							{currentProduct?.catalogue_number}
						</Typography>
						<section className="description">
							{parseHtml(currentProduct?.description)}
						</section>
						{currentProduct?.variants &&
						currentProduct.variants.length > 0 ? (
							<Variants
								colourToExclude={
									coloursInAttribute
										? [...coloursInAttribute]
										: undefined
								}
								variants={currentProduct.variants}
							/>
						) : null}
						<Grid
							container
							sx={{
								alignItems: 'center',
							}}
						>
							<Grid
								container
								sx={{
									my: 2,
									width: 'max-content',
								}}
							>
								<VariantDropdown
									variants={currentProduct.variants}
									attributes={currentProduct.attributes}
									locale={currentLocale}
								/>
							</Grid>
							{sizeGuideUrl ? (
								<a
									href={sizeGuideUrl}
									target="_blank"
									rel="noopener noreferrer"
									style={{ marginTop: '5px' }}
								>
									{translations.text.size_guide}
								</a>
							) : null}
						</Grid>
						<Grid
							container
							style={{
								padding: 0,
								justifyContent: 'space-between',
							}}
						>
							{currentProduct?.active_discount ? (
								<Grid
									container
									justifyContent="space-between"
									marginTop={1}
									width="100%"
									flexWrap="wrap"
								>
									<Grid
										container
										flex={1}
										alignItems="center"
									>
										<Typography
											variant="h4"
											component="h2"
											style={{ marginRight: '10%' }}
										>
											{calculateDiscountPrice(
												currentProduct,
											)}
										</Typography>
										<Typography
											variant="h5"
											component="h2"
											style={{
												textDecoration: 'line-through',
												color: colors['brp-grey'].faded,
												padding: 0,
											}}
										>
											{currentProduct.formatted?.price ??
												currentProduct.price}
										</Typography>
									</Grid>
								</Grid>
							) : (
								<>
									<Typography
										variant="h4"
										component="h2"
										style={{ marginTop: '8px' }}
									>
										{formatPriceToForint(
											currentProduct.price,
										)}
									</Typography>
								</>
							)}
						</Grid>
						{currentProduct?.available_stock > 0 &&
						productToSwitchTo?.id !== -1 ? (
							<Container
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									padding: 0,
									marginTop: 16,
								}}
							>
								<AddToCart
									id={currentProduct?.id}
									quantityToAdd={
										currentProduct?.quantity ?? 1
									}
								/>
								<QuantityButtons
									style={{ height: 'max-content' }}
									currentQuantity={currentProduct?.quantity}
									maximumQuantity={
										currentProduct.available_stock
									}
									// todo: this id shouldn't be optional
									productId={currentProduct?.id}
								/>
							</Container>
						) : null}
					</Grid>
				</Grid>
			</Grid>
			<Container maxWidth="lg">
				<TabContext value={tabContextValue}>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: 'divider',
						}}
					>
						<TabList onChange={handleChange}>
							<Tab
								label={translations.text.description}
								value="1"
							/>
							<Tab
								label={translations.text.specification}
								value="2"
							/>
						</TabList>
					</Box>
					<TabPanel value="1">
						<section
							className="description"
							style={{
								padding: 0,
								color: colors['brp-black'].main,
							}}
						>
							{/* 
                            maybe have a breaking value like
                            \n that creates a new typography element 
                            */}
							{currentProduct.category?.description ||
							currentProduct.category?.description
								.length === 0
								? translations.text.coming_soon
								: parseHtml(
										currentProduct.category.description,
								  )}
						</section>
					</TabPanel>
					<TabPanel value="2">
						<Grid container>
							{currentProduct.attributes.length > 0 ? (
								currentProduct.attributes.map(
									(currentAttribute) => {
										return (
											<ListItem
												key={currentAttribute.id}
												sx={{
													mb: 1,
													justifyContent: {
														xs: 'space-between',
														md: 'flex-start',
													},
													padding: 0,
												}}
											>
												<Grid item xs={4} md={2}>
													<Typography
														maxWidth="sm"
														variant="body2"
														sx={{
															padding: 0,
															fontWeight: 'bold',
														}}
														color="brp-black"
													>
														{
															currentAttribute.attribute
																.label[currentLocale]
														}
													</Typography>
												</Grid>

												<Grid item xs={6} md={8}>
													<Typography
														maxWidth="sm"
														variant="body2"
														sx={{
															padding: 0,
														}}
														color="brp-black"
													>
														{typeof currentAttribute.value ===
														'string'
															? currentAttribute.value
															: currentAttribute.value[
																	currentLocale
															  ]}
													</Typography>
												</Grid>
											</ListItem>
										);
									},
								)
							) : (
								<Typography
									maxWidth="sm"
									variant="body1"
									sx={{ paddingBottom: 0 }}
									color="brp-black"
								>
									{translations.text.no_specification}
								</Typography>
							)}
						</Grid>
					</TabPanel>
				</TabContext>
			</Container>
			<YouMayLike
				category={
					currentProduct?.category_id ??
					currentProduct?.category?.id ??
					null
				}
			/>
		</Container>
	) : (
		<Grid
			container
			flexDirection="column"
			alignItems="center"
			paddingTop={2}
			paddingBottom={4}
		>
			<Typography
				variant="h5"
				component="p"
				sx={{
					textTransform: 'uppercase',
					color: colors['brp-black'].main,
					marginBottom: '16px',
					position: 'relative',
					width: 'max-content',
				}}
			>
				{translations.shop.product_not_found}
			</Typography>
			<Button
				variant="brp-yellow"
				onClick={() => navigate('/shop/products')}
			>
				{translations.shop.back_to_shop}
			</Button>
		</Grid>
	);
};
