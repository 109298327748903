import { loadStripe } from '@stripe/stripe-js/pure';

export const initializeStripe = async () => {
	try {
		const stripe = loadStripe(
			'pk_live_51NriS2HmflW7Dz9svlY2WXzD6HKHsdsyZjggXRjLarDOix9dD1M4NHrAXMuQbtdFXqdb2USbJyh0P8WfWYm2uMvC00rWR4H3Ok',
		);
		if (!stripe) {
			throw new Error('Failed to load Stripe.js');
		}
		return stripe;
	} catch (error) {
		console.error('Error loading Stripe:', error);
		return null;
	}
};
