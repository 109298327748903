import {
	Button,
	CardActions,
	CardContent,
	Grid,
	Paper,
	Slide,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	Category,
	RootState,
	getNCategories,
	resetForFilter,
	store,
} from '../../../redux';
import placeholder from '../../../assets/placeholder.png';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import {
	addOrUpdateParam,
	handleCategoryToggle,
} from '../helpers';
import { useSelector } from 'react-redux';
import { colors } from '../../../themes';
import { CategorySkeleton } from '../../../components';
import { useRef, useState } from 'react';

export const CategoryCard = ({
	category,
	isLast,
}: {
	category: Category;
	isLast: boolean;
}) => {
	const navigate = useNavigate();
	const [shouldAnimate, setShouldAnimate] = useState(false);
	const doAnimation = () => {
		setShouldAnimate(true);
	};
	const stopAnimation = () => {
		setShouldAnimate(false);
	};

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const containerRef = useRef(null);
	const location = useLocation();
	const handleRedirect = () => {
		store.dispatch(resetForFilter());
		// vehicle needs special case
		if (category.id === 5) {
			navigate('/shop/vehicles', {
				state: { from: location },
			});
		} else {
			handleCategoryToggle(category);
			navigate(`/shop/products?category=${category.id}`, {
				state: { from: location },
			});
		}
	};

	return (
		<Grid
			item
			xs={12}
			md={6}
			justifyContent="center"
			sx={isLast ? { margin: 'auto' } : {}}
		>
			<Paper
				square
				sx={{
					width: { xs: '90%', md: '100%' },
					margin: { xs: '16px auto 0', md: '0' },
					height: isSmallScreen ? 'auto' : 300,
					border: isSmallScreen
						? `1px solid ${colors['brp-black'].main}`
						: 'none',
					borderRadius: 'none',
					boxShadow: 'none',
					':hover': {
						boxShadow: 20,
						cursor: 'ponter',
					},
					position: 'relative',
					overflow: 'hidden',
				}}
				onMouseOver={doAnimation}
				onMouseOut={stopAnimation}
				ref={containerRef}
				onClick={handleRedirect}
			>
				<img
					style={{
						height: 300,
						width: '100%',
						objectFit: 'cover',
					}}
					title={category.name}
					src={category?.main_image?.url ?? placeholder}
				/>
				{isSmallScreen ? (
					<Typography
						gutterBottom
						variant="h4"
						component="h5"
						sx={{
							textAlign: 'center',
							fontSize: '1.75em',
						}}
					>
						{category.name}
					</Typography>
				) : (
					<Slide
						direction="up"
						in={shouldAnimate}
						mountOnEnter
						unmountOnExit
						container={containerRef.current}
					>
						<Grid
							container
							sx={{
								zIndex: 10,
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								color: colors['brp-white'].main,
								background: colors['brp-black'].seeThru,
								cursor: 'pointer',
							}}
							alignItems="center"
							justifyContent="space-between"
						>
							<Typography
								gutterBottom
								variant="h4"
								component="h5"
								sx={{
									position: 'relative',
									margin: 'auto',
								}}
							>
								{category.name}

								<span
									style={{
										position: 'absolute',
										right: '10%',
										bottom: 0,
										width: '80px',
										maxWidth: '100%',
										height: '4px',
										backgroundColor:
											colors['brp-yellow'].main,
										marginLeft: 'calc(100% - 100px - 25%)',
									}}
								></span>
							</Typography>
						</Grid>
					</Slide>
				)}
			</Paper>
		</Grid>
	);
};

export const Overview = () => {
	// const loading = useSelector(selectIsLoading);
	const navigate = useNavigate();
	const navigateToShop = () => {
		navigate('/shop/products');
	};
	const categories: Category[] = getNCategories(
		store.getState(),
		5,
	);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);

	// todo: this currently won't show
	// at all as background loader will
	// disappear at the same time
	// return loading ? (
	// 	<CategorySkeleton />
	// ) :
	return (
		<Grid
			container
			maxWidth="lg"
			sx={{
				marginBottom: { xs: 2, md: 0 },
				marginLeft: 'auto',
				marginRight: 'auto',
				flexDirection: 'column',
			}}
		>
			<Grid
				container
				flexDirection={isSmallScreen ? 'column' : 'row'}
				width="100%"
				alignItems="center"
				justifyContent="space-between"
				maxWidth="lg"
				paddingX={3}
				paddingY={1}
				sx={{
					marginX: 'auto',
					marginBottom: { xs: 2, md: 3 },
					marginTop: isSmallScreen ? 2 : 0,
				}}
			>
				<Typography
					variant="h3"
					color={colors['brp-black'].main}
					component="h1"
					sx={{
						position: 'relative',
					}}
				>
					{prompts[currentLocale].text.shop_categories}
					{isSmallScreen ? null : (
						<span
							style={{
								position: 'absolute',
								right: '5%',
								bottom: 0,
								width: '100px',
								height: '4px',
								backgroundColor: colors['brp-yellow'].main,
								marginLeft: 'calc(100% - 100px - 25%)',
							}}
						></span>
					)}
				</Typography>
				<Button
					// sx={{ paddingTop: '4px', paddingBottom: 0 }}
					onClick={navigateToShop}
					variant="brp-white"
					size="small"
					disableElevation
					disableRipple
					sx={{
						'&.MuiButtonBase-root:hover': {
							background: colors['brp-white'].main,
						},
					}}
				>
					{prompts[currentLocale].text.see_all_products}
				</Button>
			</Grid>
			<Grid
				container
				// margin="0 auto"
				// justifyContent="space-between"
				padding={isSmallScreen ? 0 : 3}
				// paddingTop={0}
				spacing={isSmallScreen ? 0 : 1}
			>
				{categories.map((category, index) => {
					const isLast = categories.length === index + 1;
					return (
						<CategoryCard
							isLast={isLast}
							key={category.id}
							category={category}
						/>
					);
				})}
			</Grid>
		</Grid>
	);
};
