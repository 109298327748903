import {
	Button,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	cancelPayment,
	getIsCheckoutLoading,
	getItemsInCart,
	getSelectedDelivery,
	setCheckoutLoading,
	store, setCanProgress,
} from '../../../../redux';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { CostSummary } from '../../cart-page';
import { colors } from '../../../../themes';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { placeOrder } from '../helpers';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { CheckoutCheckboxes } from './CheckoutCheckboxes';
import { useTranslation } from '../../../../helpers';

export const ConfirmDetails = ({
	showButton = true,
	isOnlinePayment = false,
    setIsGuestStart
}) => {
	const [isTandCAccepted, setIsTandCAccepted] = useState({
		isAccepted: false as boolean,
		checked: false as boolean,
		message: undefined as string | undefined,
	});
	const [
		isPrivacyPolicyAccepted,
		setIsPrivacyPolicyAccepted,
	] = useState({
		isAccepted: false as boolean,
		checked: false as boolean,
		message: undefined as string | undefined,
	});
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const isCheckoutLoading = useSelector(
		getIsCheckoutLoading,
	);
	const translations = useTranslation();
	const checkoutState = useSelector(
		(state: RootState) => state.checkout,
	);
	const discount = checkoutState.discount;
	const deliveryDetails = checkoutState.deliveryDetails;
	const billingDetails = checkoutState.billingDetails;
	const personalDetails = checkoutState.personalDetails;
	const selectedLocker = checkoutState.parcelShops.selected;
	const shouldSubscribe = checkoutState.shouldSubscribe ?? false

	const cartItems = useSelector(getItemsInCart);
	const selectedDelivery = useSelector(getSelectedDelivery);

	const payment_method_id = useSelector(
		(state: RootState) =>
			state.checkout?.selectedPaymentMethod?.id,
	);
	const userDetails = useSelector(
		(state: RootState) => state.auth.details,
	);
	const navigateToConfirmation = async () => {
		store.dispatch(setCheckoutLoading(true));
		if (!isOnlinePayment) {
			if (!isTandCAccepted.isAccepted) {
				setIsTandCAccepted({
					isAccepted: false,
					checked: true,
					message:
						translations.checkbox
							.checkout_t_and_c_not_checked,
				});
				store.dispatch(setCheckoutLoading(false));
				return;
			}
			if (!isPrivacyPolicyAccepted.isAccepted) {
				setIsPrivacyPolicyAccepted({
					isAccepted: false,
					checked: true,
					message:
						translations.checkbox
							.checkout_privacy_not_checked,
				});
				store.dispatch(setCheckoutLoading(false));
				return;
			}
		}
		try {
			const orderPlaced: any = await placeOrder({
				name: personalDetails.name,
				company_name:
					personalDetails?.company_name === '' ||
					personalDetails?.company_name === undefined
						? null
						: personalDetails?.company_name,
				shipping: deliveryDetails,
				billing: billingDetails,
				email: personalDetails.email,
				password: personalDetails.password,
				password_confirmation:
					personalDetails.password_confirmation,
				payment_method_id: payment_method_id ?? '',
				cartItems,
				phone_number: deliveryDetails.phone_number,
				selectedDelivery,
				shouldSubscribe,
				selectedLocker,
				deliveryDetails,
				promo_code: discount?.code,
				userDetails,
				tax_number:
					personalDetails?.tax_number === '' ||
					personalDetails?.tax_number === undefined
						? null
						: personalDetails?.tax_number.replace(/-/g, ''),
				navigate,
				setIsGuestStart
			});

			if (orderPlaced) {
				navigate(
					`/completion?ref=${orderPlaced?.reference}&redirect_status=succeeded`,
					{
						state: { isOnline: false },
					},
				);
			}
		} catch (e) {
			console.log(e);
		}
		store.dispatch(setCheckoutLoading(false));
	};
	const navigateBack = () => {
		store.dispatch(setCanProgress(false));
		store.dispatch(cancelPayment());
	};
	return (
		<Paper
			square
			variant="outlined"
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							padding: 4,
							marginBottom: 4,
							flex: 2,
							// maxWidth: '40%',
					  }
			}
		>
			<Grid
				container
				sx={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					flexDirection: 'row',
					marginBottom: 2,
				}}
			>
				<Grid
					alignItems="center"
					minWidth="200px"
					container
				>
					<FontAwesomeIcon
						icon={faMoneyBill}
						style={{
							padding: 8,
							marginRight: 8,
							background: colors['brp-yellow'].main,
						}}
					/>
					<Typography
						variant="body1"
						component="span"
						sx={{
							textTransform: 'capitalize',
							color: colors['brp-black'].main,
						}}
						marginBottom="-5px"
					>
						{translations.shop.price_summary}
					</Typography>
				</Grid>
			</Grid>
			<CostSummary isOrderConfirm />
			{!isOnlinePayment ? (
				<CheckoutCheckboxes
					shouldSubscribe={shouldSubscribe}
					translations={translations}
					isTandCAccepted={isTandCAccepted}
					isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
					setIsPrivacyPolicyAccepted={
						setIsPrivacyPolicyAccepted
					}
					setIsTandCAccepted={setIsTandCAccepted}
				/>
			) : null}
			{showButton ? (
				<Grid
					container
					justifyContent="space-between"
					sx={{ mt: 2 }}
				>
					<Button
						onClick={navigateBack}
						variant="brp-black"
						sx={{ textTransform: 'capitalize' }}
						disabled={isCheckoutLoading}
					>
						{translations.buttons.cancel}
					</Button>
					<LoadingButton
						color="secondary"
						onClick={navigateToConfirmation}
						loading={isCheckoutLoading}
						loadingPosition="start"
						startIcon={<ShoppingBasketIcon />}
						variant="brp-yellow"
						sx={{
							marginLeft: isSmallScreen ? 2 : 0,
						}}
						className="capitalise"
					>
						<span style={{ marginTop: 2 }}>
							{translations.text.order_confirmation}
						</span>
					</LoadingButton>
				</Grid>
			) : null}
		</Paper>
	);
};
