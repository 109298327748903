import {
	RootState,
	selectAccountDetails,
	store,
	updateIsBusiness,
	isUserLoggedIn,
} from '../../../redux';
import {
	FormControlLabel,
	Grid,
	Paper,
	Switch,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	Field,
	useFormikContext,
} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser';
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import { colors } from '../../../themes';
import React from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import {
	Error,
	ToggleablePasswordField,
} from '../../../components';

export const PersonalDetails = ({ translations }) => {
	const formikContext = useFormikContext();
	const {
		handleChange,
		handleBlur,
		setFieldValue,
	} = formikContext;

	//@ts-ignore
	const values: any = formikContext.values.personalDetails;
	const accountDetails = useSelector(selectAccountDetails);
	const businessDetails = useSelector(
		(state: RootState) => state.checkout.business,
	);
	const userIsLoggedIn = useSelector(isUserLoggedIn);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);

	const handleBusinessChange = ({ target }) => {
		store.dispatch(updateIsBusiness(target.checked));
	};

	return (
		<Paper
			variant="outlined"
			id="personal-details"
			square
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							padding: 4,
							marginBottom: 4,
					  }
			}
		>
			<Grid
				container
				sx={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
				}}
			>
				<Grid
					alignItems="center"
					minWidth="200px"
					container
					sx={{
						width: 'max-content',
						marginBottom: 2,
					}}
				>
					<FontAwesomeIcon
						icon={faUser}
						style={{
							padding: 8,
							marginRight: 8,
							background: colors['brp-yellow'].main,
						}}
					/>
					<Typography
						variant="body1"
						component="span"
						sx={{
							textTransform: 'capitalize',
							color: colors['brp-black'].main,
						}}
					>
						{businessDetails.isBusiness
							? translations.text.company_details
							: translations.account.information.title}
					</Typography>
				</Grid>
				<FormControlLabel
					control={
						<Switch
							checked={businessDetails.isBusiness}
							onChange={handleBusinessChange}
						/>
					}
					label={
						<span style={{ marginTop: '5px' }}>
							{translations.text.business_purchase}
						</span>
					}
					sx={{ marginBottom: 1 }}
				/>
			</Grid>
			<Grid container>
				<Grid
					container
					columnGap={2}
					flexDirection={isSmallScreen ? 'column' : 'row'}
				>
					<Grid
						container
						flexDirection="column"
						style={{
							marginBottom: 16,
							flex: 1,
						}}
					>
						{businessDetails.isBusiness ? (
							<Grid container flexDirection="column">
								<Field
									name="personalDetails.company_name"
									as={TextField}
									required
									fullWidth
									value={values.company_name}
									onChange={handleChange}
									onBlur={handleBlur}
									label={translations.forms.company_name}
									placeholder={
										translations.placeholder.company_name
									}
								/>
								<Error name="personalDetails.company_name" />
							</Grid>
						) : (
							<Grid container flexDirection="column">
								<Field
									name="personalDetails.name"
									as={TextField}
									fullWidth
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									required
									label={translations.forms.name}
									placeholder={
										translations.placeholder.name
									}
								/>
								<Error name="personalDetails.name" />
							</Grid>
						)}
					</Grid>
					<Grid
						container
						flexDirection="column"
						style={{ marginBottom: 16, flex: 1 }}
					>
						<Field
							name="personalDetails.email"
							disabled={!!accountDetails?.email}
							as={TextField}
							fullWidth
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							required
							label={translations.forms.email}
							placeholder={translations.placeholder.email}
						/>
						<Error name="personalDetails.email" />
					</Grid>
				</Grid>
				{userIsLoggedIn ? null : (
					<Grid container >
						<Typography
							variant="body1"
							component="span"
							sx={{
								textTransform: 'inherit',
								marginBottom: 1,
								color: colors['brp-black'].main,
							}}
						>
							<FontAwesomeIcon
								icon={faInfo}
								style={{
									marginRight: 8,
									color: colors['brp-yellow'].main,
								}}
							/>
							{translations.shop.password_note}
						</Typography>
					</Grid>
				)}
				{userIsLoggedIn ? null : (
					<Grid
						container
						columnGap={2}
						flexDirection={isSmallScreen ? 'column' : 'row'}
					>
						<Grid
							style={{
								marginBottom: 16,
								flex: 1,
							}}
						>
							<Field
								// inputProps={{ tabIndex: 4 }}
								as={TextField}
								hideStartIcons={true}
								component={ToggleablePasswordField}
								type="password"
								value={values.password}
								onChange={handleChange}
								name="personalDetails.password"
								label={translations.forms.password}
								setFieldValue={setFieldValue}
								placeholder={
									translations.placeholder.password
								}
								fullWidth
								variant="outlined"
							/>
							<Error name="personalDetails.password" />
						</Grid>
						<Grid
							style={{
								marginBottom: 16,
								flex: 1,
							}}
						>
							<Field
								// inputProps={{ tabIndex: 5 }}
								as={TextField}
								hideStartIcons={true}
								component={ToggleablePasswordField}
								type="password"
								value={values.password_confirmation}
								onChange={handleChange}
								setFieldValue={setFieldValue}
								name="personalDetails.password_confirmation"
								label={
									translations.forms.password_confirmation
								}
								placeholder={
									translations.placeholder
										.password_confirmation
								}
								fullWidth
								variant="outlined"
							/>
							<Error name="personalDetails.password_confirmation" />
						</Grid>
					</Grid>
				)}
				{businessDetails.isBusiness ? (
					<Grid
						container
						flexDirection="column"
						style={{ marginBottom: 16, flex: 1 }}
					>
						<InputMask
							mask="99999999-9-99"
							value={values.tax_number}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							{() => (
								<Field
									style={{
										width: 'calc(50% - 8px)',
									}}
									required
									name="personalDetails.tax_number"
									as={TextField}
									fullWidth
									value={values.tax_number}
									onChange={handleChange}
									label={translations.forms.tax_number}
									placeholder={
										translations.placeholder.tax_number
									}
								/>
							)}
						</InputMask>

						<Error name="personalDetails.tax_number" />
					</Grid>
				) : null}
			</Grid>
			{/* </Form> */}
			{/* ); */}
			{/* }}
			</Formik> */}
		</Paper>
	);
};
// );
