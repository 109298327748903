import { Container, Grid, Skeleton } from '@mui/material';

export const YouMayLikeSkeleton = () => {
	return (
		<Grid
			container
			maxWidth="lg"
			style={{
				marginTop: '64px',
				marginBottom: '128px',
				paddingLeft: 0,
				marginLeft: 'auto',
				marginRight: 'auto',
				padding: 0,
				flexDirection: 'row',
				justifyContent: 'space-around',
			}}
		>
			<Skeleton width="30%" height={500} />
			<Skeleton width="30%" height={500} />
			<Skeleton width="30%" height={500} />
			{/* <Grid container xs={12}>
        </Grid> */}
			{/* <Grid item xs={6} md={3}></Grid>
			<Grid item xs={6} md={3}></Grid>
			<Grid item xs={6} md={3}></Grid> */}
		</Grid>
	);
};
