import {
	Container,
	Grid,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	RootState,
	fetchMostPopularProducts,
	getProductQuantity,
	store,
} from '../../../redux';
import {
	Heading,
	ProductCard,
	YouMayLikeSkeleton,
} from '../../../components';
import { colors } from '../../../themes';
import { useSelector } from 'react-redux';
import { pickRandomItemsFromArray } from '../helpers';
import { Product } from '../../../redux';
import { useEffect, useState } from 'react';

export const generateCards = (
	productsAvailable: Product[],
	numberOfCards: number,
	showRandomProducts,
) => {
	// const productsAvailable = useSelector(
	// 	(state: RootState) => state.products.items,
	// );
	const selectedProducts = pickRandomItemsFromArray(
		productsAvailable,
		numberOfCards,
	);

	const cards = selectedProducts.map((product: Product) => {
		const productQuantity: number = getProductQuantity(
			store.getState(),
			product.id,
		);
		return (
			<Grid key={product.id} item xs={6} md={3}>
				<ProductCard
					productQuantity={productQuantity}
					isShop={true}
					product={product}
					buttonsInRow={false}
					isSummary={true}
				/>
			</Grid>
		);
	});
	return (
		<Grid container justifyContent="space-evenly">
			{cards}
		</Grid>
	);
};

export const YouMayLike = ({
	numberOfCards = 3,
	showRandomProducts = false,
	category,
}: {
	numberOfCards?: number;
	category: number | null;
	showRandomProducts?: boolean;
}) => {
	const productState = useSelector(
		(state: RootState) => state.products,
	);
	const products = productState.mostPopularProducts;
	const isLoading = productState.status === 'loading';

	useEffect(() => {
		if (!products) {
			store.dispatch(
				fetchMostPopularProducts({ category, limit: 3 }),
			);
		}
	}, []);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);

	return !products || isLoading ? (
		<YouMayLikeSkeleton />
	) : (
		<Container
			maxWidth="lg"
			style={{
				marginTop: '64px',
				marginBottom: isSmallScreen ? '32px' : '128px',
				// paddingLeft: 0,
				padding: 0,
			}}
		>
			<Heading
				verticalLineColor={colors['brp-yellow'].main}
				title={prompts[currentLocale].text.may_like}
				subTitle={null}
				lineType="HORIZONTAL"
				// subTitle="Pellentesque ultrices ullamcorper sed tincidunt ac ultrices. Enim venenatis tortor pharetra velit ac. Sed."
			/>
			{generateCards(
				products,
				numberOfCards,
				showRandomProducts,
			)}
			{/* todo: resolve below */}
			{/* <Container
				maxWidth="lg"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					flexDirection: 'row',
					justifyContent: 'space-between',
					// my: 8,
				}}
			>
				<ProductCard isShop={false} product={products[0]} />
				<ProductCard product={products[1]} />
				<ProductCard product={products[2]} />
			</Container> */}
		</Container>
	);
};
