// src/features/todoSlice.ts
import {
	createSelector,
	createSlice,
	current,
} from '@reduxjs/toolkit';
import {
	Product,
	ProductSummary,
	RootState,
} from '../types';
import { setCartStateInLS } from '../../helpers';

// const initialState: CartState = {
const initialState: any = {
	items: [],
	updatedQuantities: [],
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		reinstateCart: (
			cartState,
			{
				payload,
			}: {
				payload: {
					id: number;
					quantity: number;
				}[];
			},
		) => {
			if (Array.isArray(payload)) {
				cartState.items = payload;
			}
		},
		addToCart: (cartState, action) => {
			const itemTracked = cartState.updatedQuantities.find(
				(item) => item.id === action.payload.id,
			);

			const itemPresent = cartState.items.find(
				(item) => item.id === action.payload.id,
			);
			if (itemPresent) {
				if (itemTracked) {
					itemPresent.quantity += parseInt(
						itemTracked.quantity,
					);
				} else {
					itemPresent.quantity++;
				}
			} else {
				cartState.items.push({
					id: action.payload.id,
					quantity: itemTracked?.quantity ?? 1,
				});
			}
			//remove after we've updated cart
			cartState.updatedQuantities =
				cartState.updatedQuantities.filter(
					(trackedItem) =>
						trackedItem.id !== action.payload.id,
				);

			setCartStateInLS(cartState.items);

			/* keeping this in for future purposes
			// todo: should this be here?
			// update cart in local storage
			// maybe move this to another reducer
			// so that it can be dispatched separately
			// better for debugging too?
			const currentStateOfCart =
				localStorage.getItem('cartState');
			if (currentStateOfCart !== null) {
				const parsedCart = JSON.parse(currentStateOfCart);
				console.log('parsedCart: ', parsedCart);
				const updatedCart = [...parsedCart, itemTracked];
				localStorage.setItem(
					'cartState',
					JSON.stringify(updatedCart)
				);
			} else {
				const stateToBeStored = itemTracked;
				localStorage.setItem(
					'cartState',
					JSON.stringify([stateToBeStored])
				);
			}
            */
		},
		resetCart: (cartState) => {
			cartState.items = [];
			cartState.updatedQuantities = [];
			localStorage.removeItem('cartState');
		},
		removeFromCart: (cartState, action) => {
			const cartAfterRemoval = cartState.items.filter(
				(item) => item.id !== action.payload.id,
			);
			const updatedQuantityRemoval =
				cartState.updatedQuantities.filter(
					(item) => item.id !== action.payload.id,
				);
			cartState.items = cartAfterRemoval;
			cartState.updatedQuantities = updatedQuantityRemoval;
			setCartStateInLS(cartState.items);
		},
		incrementQuantity: (cartState, action) => {
			const itemPresent = cartState.items.find(
				(item) => item.id === action.payload.id,
			);
			if (itemPresent) {
				itemPresent.quantity++;
			}
			setCartStateInLS(cartState.items);
		},
		decrementQuantity: (cartState, action) => {
			const itemPresent = cartState.items.find(
				(item) => item.id === action.payload.id,
			);
			if (itemPresent) {
				if (itemPresent.quantity === 1) {
					const removeItem = cartState.items.filter(
						(item) => item.id !== action.payload.id,
					);
					cartState.items = removeItem;
				} else {
					itemPresent.quantity--;
				}
			}
			setCartStateInLS(cartState.items);
		},
		resetUpdatedQuantityForProduct: (cartState, action) => {
			const updatedQuantities =
				cartState.updatedQuantities.filter(
					(item) => item.id !== action.payload.id,
				);
			cartState.updatedQuantities = updatedQuantities;
		},
		// Tracks all of the quantity button changes in shop, but will not be added to cart,
		// unless 'Add to Cart' is pressed and handleProductAddition is triggered
		trackProductQuantity: (state, { payload }) => {
			const { updatedQuantities } = state;
			if (updatedQuantities.length > 0) {
				const itemPresent = state.updatedQuantities.find(
					(item) => item.id === payload.id,
				);
				if (itemPresent !== undefined) {
					const items = state.updatedQuantities.map(
						(item) =>
							item.id === payload.id
								? (item = payload)
								: item,
					);
					state.updatedQuantities = items;
				} else {
					updatedQuantities.push(payload);
				}
			} else {
				updatedQuantities.push(payload);
			}
		},
	},
});

export const getProductQuantity = (
	state: RootState,
	productId: number,
) => {
	const { updatedQuantities } = state.cart;
	const product: { quantity: number; productId: number } =
		updatedQuantities?.find(
			(product) => product.id === productId,
		);
	if (product) {
		return product.quantity;
	}
	return 1;
};

export const productQuantitySelector = createSelector(
	[getProductQuantity],
	(product) => product,
);

const productDetailsForCartItemsSelector = (
	state: RootState,
) => {
	const products = state.products.items;
	const cartItems =
		state.cart?.items ??
		localStorage.getItem('cartState') ??
		// localStorage.getItem('cartItems') ??
		[];
	// todo: variants will not have a picture atm
	// items are refetched
	const productDetails: Product[] = [];
	cartItems.forEach((cartItem) => {
		const itemsToBeReturned = products.find(
			(product) => product.id === cartItem.id,
		);
		if (itemsToBeReturned) {
			productDetails.push({
				...itemsToBeReturned,
				quantity: cartItem.quantity,
			});
		}
	});

	// thought this could be in the createSelector
	// itself but looks like it can't be
	return productDetails;
};

export const getSubTotalOfCart = createSelector(
	[productDetailsForCartItemsSelector],
	(products: Product[]) => {
		return products.reduce((accumulator, product) => {
			return (
				accumulator +
				(product.current_price -
					product.current_price_vat_inclusive) *
					(product?.quantity ?? 1)
			);
		}, 0);
	},
);

export const getVatOfCart = createSelector(
	[productDetailsForCartItemsSelector],
	(products: Product[]) => {
		return products.reduce((accumulator, product) => {
			return (
				accumulator +
				product.current_price_vat_inclusive *
					(product?.quantity ?? 1)
			);
		}, 0);
	},
);
// hello world
const itemsInCartSelector = (state: RootState) => {
	return (
		state.cart?.items ??
		localStorage.getItem('cartState') ??
		// localStorage.getItem('cartItems') ??
		[]
	);
};

export const getItemsInCart = createSelector(
	[itemsInCartSelector],
	(items) => items,
);

export const getNumberOfItemsInCart = createSelector(
	[itemsInCartSelector],
	(items) => {
		return items.reduce(
			(sum, item) => sum + item.quantity,
			0,
		);
	},
);
export const getProductDetailsForCartItems = createSelector(
	[productDetailsForCartItemsSelector, getSubTotalOfCart],
	(products, total) => {
		const itemDetails: ProductSummary[] = products.map(
			(product: Product) => ({
				id: product.id,
				available_stock: product.available_stock,
				active_discount: product.active_discount,
				image: product.main_image?.url,
				name: product.name,
				description: product.description,
				quantity: product.quantity,
				price: product.price,
				price_vat_inclusive: product.price_vat_inclusive,
				current_price: product.current_price,
				current_price_vat_inclusive:
					product.current_price_vat_inclusive,
			}),
		);
		return {
			items: itemDetails,
			total,
		};
	},
);

export const {
	addToCart,
	reinstateCart,
	removeFromCart,
	incrementQuantity,
	decrementQuantity,
	trackProductQuantity,
	resetUpdatedQuantityForProduct,
	resetCart,
} = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
