import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteHandler } from './components/index';
import {
	fetchCategories,
	fetchProducts,
	fetchSettings,
	selectIsLoading,
	getPromoMessages,
	store,
	loginViaToken,
	getLangFile,
	fetchVehicleCategories,
} from './redux';
import { Backdrop, CircularProgress } from '@mui/material';
import './App.css';
import jetpower from './assets/jetpower.png';
import { colors } from './themes';
import { reinstateCartStateIfRequired } from './helpers';

const App = () => {
	const loading = useSelector(selectIsLoading);
	const tokenInState = useSelector(
		(state) => state.auth.token,
	);
	const settings = useSelector(
		(state) => state.generic.settings.details,
	);
	const promoMessages = useSelector(
		(state) => state.generic.promoMessages.items,
	);
	const categories = useSelector(
		(state) => state.generic.categories.status,
	);
	useEffect(() => {
		store.dispatch(getLangFile());
		if (!window.location.href.includes('shop')) {
			store.dispatch(fetchProducts());
		}
		if (!settings) {
			store.dispatch(fetchSettings());
		}
		if (!categories) {
			store.dispatch(fetchCategories());
		}
		if (window.location.href.includes('vehicles')) {
			store.dispatch(fetchVehicleCategories());
		}
		if (!promoMessages) {
			store.dispatch(getPromoMessages());
		}
		reinstateCartStateIfRequired();
		const token =
			tokenInState ?? localStorage.getItem('userToken');
		if (token && token !== 'undefined') {
			store.dispatch(loginViaToken());
		}
	}, []);
	return loading ? (
		<Backdrop
			sx={{
				color: '#fff',
				background: colors['brp-black'].main,
				flexDirection: 'column',
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={loading}
			// onClick={handleClose}
		>
			<img
				src={jetpower}
				alt="Footer Logo"
				style={{ marginBottom: '1rem', width: 200 }}
			/>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : (
		<RouteHandler />
	);
};

export default App;
