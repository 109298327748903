import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	NativeSelect,
} from '@mui/material';
import {
	Country,
	RootState,
	fetchFilteredProducts,
	fetchProducts,
	store,
	updatePageSize,
	updateSelectedCountry,
} from '../../../redux';
import { useSelector } from 'react-redux';
export const CountryDropdown = ({
	countries,
	style = {},
}: {
	countries: Country[] | undefined;
	style: object;
}) => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const { selected: selectedCountry } = useSelector(
		(state: RootState) => state.checkout.countries,
	);
	const translations = prompts[currentLocale];
	const handleChange = ({ target }) => {
		const { value } = target;

		store.dispatch(updateSelectedCountry(parseInt(value)));
	};
	return (
		<FormControl
			size="medium"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				marginBottom: '-10px',
			}}
			style={style}
			variant="standard"
		>
			<NativeSelect
				disabled
				placeholder={translations.forms.country}
				value={
					selectedCountry?.id ?? translations.forms.country
				}
				sx={{
					paddingBottom: 0,
					height: '100%',
					border: `1px solid rgba(0,0,0,0.23)`,
					'&:before': {
						display: 'none',
					},
					'& .MuiNativeSelect-select.MuiNativeSelect-standard':
						{
							height: '95%',
							paddingX: 2,
						},
				}}
				onChange={handleChange}
			>
				{countries &&
					countries.map((country) => (
						<option key={country.id} value={country.id}>
							{country.name}
						</option>
					))}
			</NativeSelect>
		</FormControl>
	);
};
