import {
	Grid,
	Pagination,
	PaginationItem,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	fetchFilteredProducts,
	fetchFilteredVehicles,
	fetchVehicles,
	store,
	updatePageNumber,
} from '../../redux';
import { Link, useLocation } from 'react-router-dom';
import { colors } from '../../themes';
import { doScrollToTop } from '../../helpers';

const constructURL = (
	page: number | null,
	shopType: 'vehicles' | 'products',
): string => {
	const query = new URLSearchParams(location.search);
	const newQuery = new URLSearchParams(query);
	if (page) {
		newQuery.set('page', page.toString());
	}
	return `/shop/${shopType}?${newQuery.toString()}`;
};
export const VehiclePagination = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const pagination = useSelector(
		(state: RootState) => state.generic.vehicles.pagination,
	);
	const isLoading = useSelector(
		(state: RootState) =>
			state.generic.vehicles.isBeingFetched,
	);
	const queryParams = new URLSearchParams(location.search);
	const page = queryParams.get('page');

	const currentPage = useSelector(
		(state: RootState) => state.products.pageNumber,
	);

	const handleVehiclePageChange = (
		_event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		doScrollToTop();
		store.dispatch(updatePageNumber(value));
		store.dispatch(
			fetchFilteredVehicles({
				sort: store.getState().generic.vehicles.sortApplied,
				filters:
					store.getState().generic.vehicles.filtersApplied,
			}),
		);
	};

	const itemRange = () => {
		if (pagination) {
			const total = pagination?.total;
			const current_page = pagination?.current_page;
			const per_page = pagination?.per_page;
			let lowerRange = 0;
			let higherRange = Math.min(total, per_page);
			let totalValue = total;
			if (current_page && per_page && current_page > 1) {
				lowerRange = 1 + (current_page - 1) * per_page;
				higherRange = Math.min(
					total,
					current_page * per_page,
				);
			}
			return {
				shown: true,
				range:
					lowerRange === 0
						? higherRange
						: `${lowerRange}-${higherRange}`,
				lowerBound: lowerRange,
				higherBound: higherRange,
				totalValue,
			};
		}
		return { shown: false };
	};
	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			sx={{ marginY: 2, paddingX: { xs: 2, md: 0 } }}
		>
			{pagination && itemRange().shown //&& rangeMaxValue && rangeMaxValue > 0
				? currentLocale === 'en'
					? `Showing ${itemRange().range} of ${
							itemRange().totalValue
					  } vehicles`
					: `${itemRange().range} jármű ${
							itemRange().totalValue
					  } járműből`
				: null}
			<Stack spacing={2} sx={{ marginLeft: 'auto' }}>
				<Pagination
					page={page ? parseInt(page) : currentPage}
					count={pagination?.last_page}
					variant="outlined"
					sx={{
						'& .MuiPaginationItem-root.Mui-selected': {
							color: colors['brp-black'].main,
							backgroundColor: colors['brp-yellow'].main,
						},
					}}
					shape="rounded"
					disabled={isLoading}
					onChange={handleVehiclePageChange}
					renderItem={(item) => (
						<PaginationItem
							component={Link}
							to={constructURL(item?.page, 'vehicles')} // Include existing search query parameters
							{...item}
						/>
					)}
				/>
			</Stack>
		</Grid>
	);
};

export const ProductPagination = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const pagination = useSelector(
		(state: RootState) => state.products.pagination,
	);
	const isLoading = useSelector(
		(state: RootState) =>
			state.products.status === 'loading',
	);
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const page = parseInt(query.get('page') || '1', 10);
	const currentPage = useSelector(
		(state: RootState) => state.products.pageNumber,
	);
	const displayableProducts = useSelector(
		(state: RootState) => state.products.displayable,
	);
	const handleChange = (
		_event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		doScrollToTop();
		store.dispatch(updatePageNumber(value));
		store.dispatch(
			fetchFilteredProducts({
				filters: store.getState().products.filtersApplied,
				sort: store.getState().products.sortApplied,
			}),
		);
	};
	const itemRange = () => {
		if (pagination) {
			const total = pagination?.total;
			const current_page = pagination?.current_page;
			const per_page = pagination?.per_page;
			let lowerRange = 0;
			let higherRange = Math.min(total, per_page);
			let totalValue = total;
			if (current_page && per_page && current_page > 1) {
				lowerRange = 1 + (current_page - 1) * per_page;
				higherRange = Math.min(
					total,
					current_page * per_page,
				);
			} else {
				totalValue = displayableProducts
					? Math.max(displayableProducts.length, total)
					: higherRange;
			}
			return {
				shown: true,
				range:
					lowerRange === 0
						? higherRange
						: `${lowerRange}-${higherRange}`,
				lowerBound: lowerRange,
				higherBound: higherRange,
				totalValue,
			};
		}
		return { shown: false };
	};

	return displayableProducts &&
		displayableProducts.length > 0 ? (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			sx={{ marginY: 2, paddingX: { xs: 2, md: 0 } }}
		>
			{/* 
			<Stack spacing={2}> */}
			{/* {pagination &&
				`Showing ${itemRangeShown()} of ${pagination?.total} items`} */}
			{pagination && itemRange().shown //&& rangeMaxValue && rangeMaxValue > 0
				? currentLocale === 'en'
					? `Showing ${itemRange().range} of ${
							itemRange().totalValue
					  } items`
					: `${itemRange().range} termék ${
							itemRange().totalValue
					  } termékből`
				: null}
			<Stack spacing={2} sx={{ marginLeft: 'auto' }}>
				<Pagination
					page={currentPage}
					count={pagination?.last_page}
					variant="outlined"
					sx={{
						'& .MuiPaginationItem-root.Mui-selected': {
							color: colors['brp-black'].main,
							backgroundColor: colors['brp-yellow'].main,
						},
					}}
					shape="rounded"
					disabled={isLoading}
					onChange={handleChange}
					renderItem={(item) => (
						<PaginationItem
							component={Link}
							to={constructURL(item?.page, 'products')}
							{...item}
						/>
					)}
				/>
			</Stack>
		</Grid>
	) : null;
};
