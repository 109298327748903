import {
	FormControl,
	Grid,
	MenuItem,
	Select,
} from '@mui/material';
import {
	RootState,
	Translatable,
	store,
	updateCurrentProductBasedOnVariant,
} from '../../redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const getUniqueColors = ({
	variants,
	attributes,
	locale,
}: {
	variants: object;
	attributes?: object;
	locale: string;
}) => {
	const merged = mergeAttributesAndVariants({
		variants,
		attributes,
	});
	return getAvailableColours(merged, locale);
};

const mergeAttributesAndVariants = ({
	variants,
	attributes,
}) => {
	const selectedProducts = useSelector(
		(state: RootState) => state.products.currentProduct,
	);
	const mergedAttributes =
		attributes?.length > 0
			? attributes
					.map((attr) => {
						const id = selectedProducts.details.id;
						const size =
							attr.attribute.name === 'size'
								? attr.value
								: undefined;
						//
						const colour =
							attr.attribute.name === 'colour'
								? attr.value
								: undefined;
						if (colour || size) {
							return { id, size, colour };
						}
						return undefined;
					})
					.filter(Boolean)
			: [];

	const mappedVariants =
		variants?.length > 0
			? variants.map((variant) => {
					const id = variant.id;
					const size = variant.attributes.find(
						(attr) => attr.attribute.name === 'size',
					)?.value;
					const colour = variant.attributes.find(
						(attr) => attr.attribute.name === 'colour',
					)?.value;

					return { id, size, colour };
			  })
			: [];

	const variantsAndAttributes = [
		...mergedAttributes,
		...mappedVariants,
	];
	return variantsAndAttributes;
};

export const getAvailableSizes = (
	potentialSizes: { size: string }[],
) => [...new Set(potentialSizes.map((item) => item.size))];

export const getAvailableColours = (
	potentialColours: { colour: Translatable }[],
	locale: string,
) => [
	...new Set(
		potentialColours
			.map((item) => item?.colour && item?.colour[locale])
			.filter(Boolean),
	),
];

export const VariantDropdown = ({
	variants,
	attributes,
	locale,
}) => {
	const navigate = useNavigate();
	const defaultSize = attributes.find(
		(attr) => attr.attribute.name === 'size',
	)?.value;

	const defaultColour = attributes.find(
		(attr) => attr.attribute.name === 'colour',
	)?.value;

	const variantsAndAttributes = mergeAttributesAndVariants({
		variants,
		attributes,
	});

	const [selectedSize, setSelectedSize] = useState(
		defaultSize ?? '',
	);
	const [selectedColour, setSelectedColour] = useState(
		defaultColour ? defaultColour[locale] : '',
	);
	useEffect(() => {
		const updatedSize = attributes.find(
			(attr) => attr.attribute.name === 'size',
		)?.value;
		setSelectedSize(updatedSize ?? defaultSize);

		const updatedColour = attributes.find(
			(attr) => attr.attribute.name === 'colour',
		)?.value;
		setSelectedColour(
			updatedColour ? updatedColour[locale] : defaultColour,
		);
	}, [attributes, variants, locale]);

	// todo enhancement: store already visited products
	// in redux store so they don't need to be refetched2
	const handleVariantChange = (
		value: string,
		variantType: 'size' | 'colour',
	) => {
		switch (variantType) {
			case 'size':
				setSelectedSize(value);
				break;
			case 'colour':
				setSelectedColour(value);
				break;
		}
		store.dispatch(
			updateCurrentProductBasedOnVariant({
				items: variantsAndAttributes,
				locale,
				size: variantType === 'size' ? value : selectedSize,
				colour:
					variantType === 'colour' ? value : selectedColour,
			}),
		);
	};
	const productToSwitchTo = useSelector(
		(state: RootState) =>
			state.products.currentProduct.variantSelected,
	);
	useEffect(() => {
		if (
			productToSwitchTo?.id &&
			productToSwitchTo.id !== -1
		) {
			navigate(`/shop/products/${productToSwitchTo.id}`);
		}
	}, [productToSwitchTo]);

	const availableSizes = getAvailableSizes(
		variantsAndAttributes,
	);
	const availableColours = getAvailableColours(
		variantsAndAttributes,
		locale,
	);

	const handleChange = ({ target }) => {
		const { value, name } = target;
		handleVariantChange(value, name);
	};
	return variants?.length > 0 ? (
		<Grid container>
			{availableSizes.length > 0 ? (
				<FormControl
					size="small"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '-10px',
						paddingRight: { xs: 0, md: 2 },
					}}
					variant="standard"
				>
					<Select
						onChange={handleChange}
						value={selectedSize} //uniqueSizes[0]}
						sx={{ paddingBottom: 0 }}
						name="size"
					>
						{availableSizes.map((size: any) => (
							<MenuItem key={size} value={size}>
								{size}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}
			{availableColours.length > 0 ? (
				<FormControl
					size="small"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '-10px',
						paddingRight: { xs: 0, md: 2 },
					}}
					variant="standard"
				>
					<Select
						value={selectedColour} //uniqueColours[0]}
						sx={{ paddingBottom: 0 }}
						name="colour"
						onChange={handleChange}
					>
						{availableColours.map((colour: any) => (
							<MenuItem key={colour} value={colour}>
								{colour}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}
		</Grid>
	) : null;
};
