import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
	Home,
	Contact,
	NotFound,
	Login,
	Signup,
	VirtualRoom,
	Shop,
	Blog,
	Services,
	Product,
	Cart,
	Partners,
	Checkout,
	Account,
	OrderHistory,
	DeliveryAddresses,
	AccountDetails,
	Completion,
	ForgottenPassword,
	PartsFinder,
	AccountActivation,
	Overview,
	Dynamic,
	Vehicle,
	Grants,
	PasswordReset,
	NewsletterUnsubscribe,
	BlogPost,
	ChangePassword,
	CheckoutDisabled,
} from '../../views';
import { Layout } from '..';
import {
	DynamicPage,
	RootState,
	selectAccountDetails,
} from '../../redux';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { useTranslation } from '../../helpers';

const OpenLinkPage = ({ link }) => {
	const translation = useTranslation();
	if (link) {
		window.open(link, '_blank');
	}
	return (
		<Grid container sx={{ height: '100%' }}>
			<Button
				sx={{ margin: 'auto' }}
				variant="brp-yellow"
				onClick={() => window.open(link, '_blank')}
			>
				{translation.text.re_open_link}
			</Button>
		</Grid>
	);
};
const External = ({ link }: { link: string }) => {
	// no new tab, just redirect to link
	window.location.replace(link);
	return null;
};
// function ExternalRoute({ children }: PropsWithChildren) {
// 	const navigate = useNavigate();

// 	useEffect(() => {
// 		if (window.history.state.idx > 0) {
// 			navigate(-1);
// 		} else {
// 			navigate('/');
// 		}
// 	}, [navigate]);
// 	return <>{children}</>;
// }

export const RouteHandler = () => {
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const dynamicPages: DynamicPage[] = useSelector(
		(state: RootState) =>
			state.locale.languages?.[currentLocale].pages,
	);

	const settings = useSelector(
		(state: RootState) => state.generic.settings.details,
	);
	const accountDetails = useSelector(selectAccountDetails);
	const userIsLoggedIn = !!accountDetails?.id;
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route path="/" element={<Home />} />
				{/* <Route path="services" element={<Services />} /> */}
				<Route path="search" element={<Home />} />
				<Route path="grants" element={<Grants />} />
				<Route path="sponsors" element={<Grants />} />
				{settings?.checkout_allowed ? (
					<Route path="checkout" element={<Checkout />} />
				) : (
					<Route
						path="checkout"
						element={<CheckoutDisabled />}
					/>
				)}
				{settings?.checkout_allowed ? (
					<>
						<Route
							path="checkout/payment"
							element={<Checkout />}
						/>
						<Route
							path="checkout/review"
							element={<Checkout />}
						/>
						<Route
							path="checkout/login"
							element={<Checkout />}
						/>
					</>
				) : (
					<Route
						path="checkout/payment"
						element={<CheckoutDisabled />}
					/>
				)}
				<Route path="blog">
					<Route index element={<Blog />} />
					<Route path=":id" element={<BlogPost />} />
				</Route>
				<Route path="shop/products">
					<Route index element={<Shop />} />
					<Route path=":id" element={<Product />} />
				</Route>
				<Route path="shop" element={<Shop />} />
				<Route path="shop/vehicles">
					<Route
						index
						element={<Shop isVehicles={true} />}
					/>
					<Route path=":id" element={<Vehicle />} />
				</Route>
				<Route
					path="shop/categories"
					element={<Overview />}
				/>
				<Route path="shop/products">
					<Route index element={<Shop />} />
					<Route path=":id" element={<Product />} />
				</Route>
				<Route path="services" element={<Services />} />
				<Route
					path="password-reset"
					element={<PasswordReset />}
				/>
				<Route
					path="newsletter-unsubscribe"
					element={<NewsletterUnsubscribe />}
				/>
				<Route
					path="forgotten-password"
					element={<ForgottenPassword />}
				/>
				{/* overrides */}
				<Route path="partners" element={<Partners />} />
				<Route path="contact" element={<Contact />} />
				{dynamicPages && dynamicPages.length > 0
					? dynamicPages.map((page: DynamicPage) => {
							return (
								<Route
									key={page.slug}
									path={page.slug}
									element={
										page.link ? (
											<External link={page.link} />
										) : (
											<Dynamic page={page} />
										)
									}
								/>
							);
					  })
					: null}
				{/* todo: should this be disabled if user is logged in? */}
				<Route
					path="account-activation"
					element={<AccountActivation />}
				/>
				<Route
					path="login"
					element={
						userIsLoggedIn ? (
							<Navigate to="/account" />
						) : (
							<Login />
						)
					}
				/>
				<Route
					path="virtual-tour"
					element={<VirtualRoom />}
				/>
				<Route
					path="parts-finder"
					element={<PartsFinder />}
				/>
				<Route path="completion" element={<Completion />} />
				<Route path="cart" element={<Cart />} />

				{/*
                todo: 
                on redirect login may
                not be displayed straight away
                */}
				<Route
					path="account"
					element={
						userIsLoggedIn ? (
							<Account />
						) : (
							<Navigate to="/login" />
						)
					}
				>
					{/* <Route index path="account" element={<Account />}> */}
					<Route
						path="order-history"
						element={<OrderHistory />}
					/>
					<Route
						path="delivery-details"
						element={<DeliveryAddresses />}
					/>
					<Route
						path="password-change"
						element={<ChangePassword />}
					/>
					<Route index element={<AccountDetails />} />
					<Route
						index
						path="details"
						element={<AccountDetails />}
					/>
				</Route>

				{/* Fallback */}
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};
