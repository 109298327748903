import React, { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
	BrowserRouter,
	Routes,
	Route,
} from 'react-router-dom';
import { store } from './redux';
import App from './App';
import { ThemeProvider } from './themes/index';
import './styles/general.scss';
import { GlobalStyles } from '@mui/material';
import { ScrollToTop } from './helpers';
import { GenericDialog } from './components';
import * as Sentry from '@sentry/react';

process.env.REACT_APP_ENV === 'production' &&
	Sentry.init({
		dsn: 'https://6d2e28f35b024cc479fa59d1b61949a6@o4508043621105664.ingest.de.sentry.io/4508043635654736',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 0.4,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

const container = document.getElementById(
	'root',
) as HTMLElement;

const inputGlobalStyles = <GlobalStyles styles={{}} />;

const root = createRoot(container);
root.render(
	<Provider store={store}>
		<ThemeProvider>
			{inputGlobalStyles}
			<BrowserRouter>
				<GenericDialog />
				<ScrollToTop />
				<Routes>
					<Route path="/*" element={<App />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	</Provider>,
);
