import {
	Button,
	CardActions,
	CardContent,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import placeholder from '../../assets/placeholder.png';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import {
	fetchVehiclesForCategory,
	store,
	toggleCategory,
	updatePageNumber,
	updateSelectedVehicleCategory,
} from '../../redux';
import { parseHtml } from '../../views/helpers';

export const CategoryCards = ({
	payload,
	hasCta,
	ctaText,
}) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	const navigate = useNavigate();
	const handleRedirect = () => {
		store.dispatch(updateSelectedVehicleCategory(payload));
		handleAttributeToggle(payload.id);
		navigate(`/shop/vehicles?category=${payload.id}`);
	};

	const handleAttributeToggle = async (
		categoryId: number,
	) => {
		store.dispatch(
			toggleCategory({
				categoryId,
				onlyReceivedCategories: true,
			}),
		);
	};

	return (
		<Paper
			sx={{
				maxWidth: 400,
				boxShadow: 'none',
			}}
		>
			{/*  */}
			<Link
				to={`/shop/vehicles?category=${payload.id}`}
				onClick={() =>
					store.dispatch(
						updateSelectedVehicleCategory(payload),
					)
				}
			>
				<img
					style={{
						height: 200,
						width: '100%',
						objectFit: 'cover',
					}}
					title={payload.name}
					src={payload?.main_image?.url ?? placeholder}
				/>
			</Link>
			<CardContent sx={{ padding: 0 }}>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography
						gutterBottom
						variant="h5"
						component="h5"
						sx={{ margin: 0 }}
					>
						{payload.name}
					</Typography>
					{hasCta && (
						<Button
							sx={{
								marginLeft: isSmallScreen ? 0 : 'auto',
							}}
							size="small"
							variant="brp-white"
							onClick={handleRedirect}
						>
							{ctaText}
						</Button>
					)}
				</Grid>
				<section
					className="description"
					style={{
						marginTop: '8px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{parseHtml(payload.description)}
				</section>
			</CardContent>
		</Paper>
	);
};
export const VehicleCards = ({ payload }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);

	return (
		<Paper
			sx={{
				maxWidth: 400,
				boxShadow: 'none',
			}}
		>
			<img
				onClick={() =>
					store.dispatch(
						updateSelectedVehicleCategory(payload),
					)
				}
				style={{
					height: 200,
					width: '100%',
					objectFit: 'cover',
					cursor: 'pointer',
				}}
				title={payload.name}
				src={payload?.main_image?.url ?? placeholder}
			/>
			<CardContent sx={{ padding: 0 }}>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography
						gutterBottom
						variant="h5"
						component="h5"
						sx={{ margin: 0 }}
					>
						{payload.name}
					</Typography>

					<Button
						sx={{
							marginLeft: isSmallScreen ? 0 : 'auto',
						}}
						size="small"
						variant="brp-white"
						onClick={() =>
							store.dispatch(
								updateSelectedVehicleCategory(payload),
							)
						}
					>
						{payload.name}
					</Button>
				</Grid>
				<section
					className="description"
					style={{
						marginTop: '8px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{parseHtml(payload.description)}
				</section>
			</CardContent>
		</Paper>
	);
};
