import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import {
	RootState,
	getProductQuantity,
	store,
} from '../../../redux';
import { useTranslation } from '../../../helpers';
import {
	NoItem,
	ProductCard,
	ProductPagination,
	ProductsSkeleton,
} from '../../../components';

export const ProductSection = ({ isLoading }) => {
	const products = useSelector(
		(state: RootState) =>
			state.products.displayable ?? state.products.items,
	);
	const areProductsBeingFetched = useSelector(
		(state: RootState) =>
			state.products.status !== 'fulfilled',
	);
	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);
	const translations = useTranslation();
	return (
		<>
			<Grid container className="productContainer">
				{isLoading || areProductsBeingFetched ? (
					<ProductsSkeleton />
				) : products?.length > 0 ? (
					products.map((product) => {
						const productQuantity: number =
							getProductQuantity(
								store.getState(),
								product?.id,
							);

						return (
							<Grid
								item
								xs={6}
								xl={isGridView ? 3 : 12}
								lg={isGridView ? 4 : 12}
								md={isGridView ? 6 : 12}
								key={product.id}
							>
								<ProductCard
									product={product}
									isShop={true} // Always in shop context
									productQuantity={productQuantity} // Pass product quantity
									buttonsInRow={isGridView} // Control button layout based on grid view
									isSummary={false} // Not a summary view
								/>
							</Grid>
						);
					})
				) : (
					<NoItem translations={translations} /> // Display "No Items" if no products are available
				)}
			</Grid>

			{/* Show pagination if there are products */}
			{products?.length > 0 && <ProductPagination />}
		</>
	);
};
