import { useSelector } from 'react-redux';
import { RootState, VehicleDetails } from '../../redux';
import {
	Button,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import notFound from '../../assets/brp_world.png';
import {
	formatPriceToEuro,
	useTranslation,
} from '../../helpers';

export const VehicleCard = ({
	vehicle,
}: {
	vehicle: VehicleDetails;
}) => {
	// todo: there is a potential that if the gridview is set to false
	// when looking at products, and they search for vehicles, it stays in that
	// so make sure it's reset to gridview whenever vehicles are shown
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const translations = useTranslation();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const isLargeScreen = useMediaQuery(
		theme.breakpoints.up('lg'),
	);

	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);

	return (
		<Grid
			container
			sx={{
				width: '100%',
				height: isGridView ? '100%' : 'auto',
				maxHeight: isGridView ? 'none' : 300,
				// columnGap: 1,
			}}
		>
			<CardContent
				style={{
					display: 'flex',
					flexDirection: isGridView ? 'column' : 'row',
					width: '100%',
					flex: 1,
					// padding: 8,
				}}
			>
				<Grid
					item
					xs={12}
					md={isGridView ? 12 : 4}
					sx={{
						flexBasis: isSmallScreen ? 'auto' : '100%',
					}}
				>
					<Link
						to={`/shop/vehicles/${vehicle?.id}`}
						state={{ vehicleId: vehicle.id }}
						style={{
							display: 'block',
							width: '100%',
						}}
					>
						<CardMedia
							component="img"
							alt={vehicle?.name}
							sx={{
								width: '100%',
								maxHeight: '200px',
								aspectRatio: 3 / 2,
								objectFit: 'contain',
							}}
							image={vehicle?.main_image?.src ?? notFound}
						/>
					</Link>
				</Grid>
				<Grid
					item
					xs={12}
					md={isGridView ? 12 : 8}
					padding={0}
					width={isGridView ? '100%' : 'max-content'}
					sx={{
						flex: 1,
						display: isGridView ? 'block' : 'flex',
					}}
					flexDirection={isGridView ? 'column' : 'row'}
					className="titleAndTextAndButtonContainer"
				>
					<Grid
						item
						md={isGridView ? 12 : 9}
						container
						display="flex"
						flexDirection="column"
					>
						<Link
							to={`/shop/vehicles/${vehicle.id}`}
							state={{ vehicleId: vehicle.id }}
						>
							<Typography
								gutterBottom
								variant="h6"
								component="div"
								sx={{ textTransform: 'uppercase' }}
							>
								{vehicle.year} {vehicle.name}
							</Typography>
						</Link>
					</Grid>
					<Grid
						item
						md={isGridView ? 12 : 3}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
						}}
						// sx={{
						// 	justifyContent: 'flex-end',
						// 	display: 'flex',
						// 	flexDirection: 'column',
						// }}
					>
						{vehicle?.price ? (
							<Typography variant="body1">
								{currentLocale === 'en' ? 'From ' : null}
								{formatPriceToEuro(vehicle.price)}
								{currentLocale !== 'en'
									? ' + Áfától'
									: ' + VAT'}
							</Typography>
						) : (
							<Typography variant="body1"></Typography>
						)}
						<CardActions
							sx={{
								padding: 0,
								display: 'flex',
								// justifyContent: 'space-between',
								justifyContent: 'flex-end',
								flexWrap: 'wrap',
								alignItems: isGridView
									? 'flex-start'
									: 'flex-end',
								height: '100%',
								flexDirection: 'column',
							}}
						>
							{vehicle.external_link ? (
								<Link
									to={vehicle.external_link}
									target="_blank"
									rel="noopener noreferrer"
									style={{
										width: '100%',
									}}
								>
									<Button
										variant="brp-yellow"
										style={{
											width: '100%',
											marginLeft: 0,
											marginTop: 8,
											minWidth: 120,
											flex: isGridView ? 1 : 'none',
										}}
									>
										{translations.text.customise_vehicle}
									</Button>
								</Link>
							) : null}
						</CardActions>
					</Grid>
				</Grid>
			</CardContent>
		</Grid>
	);
};
