import {
	Dialog,
	Grid,
	IconButton,
	Toolbar,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import {
	VehicleCategories,
	VehicleFilters,
	Filter,
	PageSizeDropdown,
} from '.';

export const FilterDialog = ({
	isFilterDialogOpen,
	toggleFilterDialog,
	setIsLoading,
	isVehicles,
}) => (
	<Dialog
		open={isFilterDialogOpen}
		onClose={toggleFilterDialog}
		fullScreen
		sx={{ zIndex: 999 }}
	>
		<Toolbar
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<IconButton
				edge="start"
				color="inherit"
				onClick={toggleFilterDialog}
				aria-label="close"
			>
				<GridCloseIcon />
			</IconButton>
			<PageSizeDropdown />
		</Toolbar>

		<Grid container paddingX={2} flexDirection="column">
			{isVehicles ? (
				<Grid
					container
					flexDirection="column"
					style={{ flexWrap: 'initial' }}
				>
					<VehicleCategories />
					<VehicleFilters setIsLoading={setIsLoading} />
				</Grid>
			) : (
				<Filter setIsLoading={setIsLoading} />
			)}
		</Grid>
	</Dialog>
);
