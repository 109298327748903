import {
	Checkbox,
	FormControlLabel,
	Grid,
	InputAdornment,
	Paper,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileInvoice,
	faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../themes';
import {
	RootState,
	getIsBillingSame,
	selectAccountDetails,
	setBillingIsSameAsShipping,
	store,
	getSelectedDelivery,
} from '../../../redux';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { DeliveryMethodDetails } from './DeliveryMethodDetails';
import { AddressDropdown } from './components';
import { CountryDropdown } from '../components';
import {
	LockerMap,
	PlacesAutocomplete,
} from './components/Map';
import { SelectedLocker } from './helpers';
import hungary from '../../../assets/hungarian-flag.svg';
import { useLoadScript } from '@react-google-maps/api';
import InputMask from 'react-input-mask';
import { Error } from '../../../components';

const libraries: any = ['places'];

export const DeliveryDetails = ({
	isCheckout = false,
	isDelivery = false,
	translations,
	isLocker = false,
	isPickup = false,
	isCod = false,
}: {
	isCheckout?: boolean;
	isDelivery?: boolean;
	isLocker?: boolean;
	isPickup?: boolean;
	isCod?: boolean;
	translations: any;
}) => {
	const formikContext = useFormikContext();
	const {
		handleChange,
		handleBlur,
		handleSubmit,
		setFieldValue,
	} = formikContext;
	const values: any = isDelivery
		? //@ts-ignore
		  formikContext.values.shipping
		: //@ts-ignore
		  formikContext.values.billing;

	const isBillingSame = useSelector(getIsBillingSame);
	const selectedDelivery = useSelector(getSelectedDelivery);

	const [selectedAddress, setSelectedAddress] =
		useState() as [any, any];

	const { isLoaded } = useLoadScript({
		googleMapsApiKey:
			process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY!,
		libraries,
	});

	// how could we rewrite this
	useEffect(() => {
		if (selectedAddress)
			for (const [key, value] of Object.entries(
				selectedAddress,
			)) {
				// @ts-ignore
				setFieldValue(
					`${isDelivery ? 'shipping' : 'billing'}.${key}`,
					value,
				);
			}
	}, [selectedAddress]);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);

	const prefix = isDelivery ? 'shipping' : 'billing';

	const selectedLocker = useSelector(
		(state: RootState) =>
			state.checkout.parcelShops.selected,
	);

	const handleCheckboxChange = ({ target }) => {
		store.dispatch(
			setBillingIsSameAsShipping(target.checked),
		);
	};

	const {
		items: countries,
		isBeingFetched: countriesFetched,
	} = useSelector(
		(state: RootState) => state.checkout.countries,
	);
	const accountDetails = useSelector(selectAccountDetails);
	const deliveryAddresses =
		accountDetails?.shipping_addresses;

	return (
		<Paper
			variant="outlined"
			square
			id={
				isDelivery ? 'delivery-details' : 'billing-details'
			}
			sx={
				isSmallScreen
					? { padding: 2, border: 'none' }
					: {
							padding: 4,
							marginBottom: 4,
					  }
			}
			style={
				isCheckout
					? {}
					: {
							marginBottom: 0,
							padding: 0,
							border: 'none',
					  }
			}
		>
			{isCheckout ? (
				<Grid
					container
					sx={{
						alignItems: 'flex-start',
						justifyContent: 'space-between',
					}}
				>
					<Grid
						alignItems="center"
						minWidth="200px"
						container
						sx={{
							width: 'max-content',
							marginBottom: 2,
						}}
					>
						<FontAwesomeIcon
							icon={isDelivery ? faTruck : faFileInvoice}
							style={{
								padding: 8,
								marginRight: 8,
								background: colors['brp-yellow'].main,
							}}
						/>
						<Typography
							variant="body1"
							component="span"
							sx={{
								textTransform: 'capitalize',
								color: colors['brp-black'].main,
							}}
						>
							{isDelivery
								? translations.navigation.delivery_details
								: translations.navigation.billing_details}
						</Typography>
					</Grid>
				</Grid>
			) : null}
			{(!isBillingSame || !isDelivery) &&
			deliveryAddresses &&
			deliveryAddresses.length > 0 &&
			!isPickup ? (
				<AddressDropdown
					translations={translations}
					addresses={deliveryAddresses}
					setSelected={setSelectedAddress}
				/>
			) : null}
			{(!isBillingSame || !isDelivery) && !isPickup ? (
				<Grid container>
					{isLoaded ? (
						<PlacesAutocomplete
							isAutofill={true}
							setSelected={setSelectedAddress}
							translations={translations}
						/>
					) : null}

					<Grid
						container
						columnGap={2}
						flexDirection={isSmallScreen ? 'column' : 'row'}
					>
						<Grid
							container
							flexDirection="column"
							style={{
								marginBottom: 16,
								flex: 1,
							}}
						>
							<Field
								name={`${prefix}.street`}
								as={TextField}
								fullWidth
								value={values!.street}
								onChange={handleChange}
								onBlur={handleBlur}
								required
								label={translations.forms.street}
								placeholder={
									translations.placeholder.street
								}
							/>
							<Error name={`${prefix}.street`} />
						</Grid>
						<Grid
							container
							flexDirection="column"
							style={{
								marginBottom: 16,
								flex: 1,
							}}
						>
							<Field
								name={`${prefix}.street_extra`}
								value={values!.street_extra}
								as={TextField}
								fullWidth
								onChange={handleChange}
								onBlur={handleBlur}
								label={translations.forms.street_extra}
								placeholder={
									translations.placeholder.street_extra
								}
							/>
							<Error name={`${prefix}.street_extra`} />
						</Grid>
					</Grid>
					<Grid
						container
						columnGap={2}
						flexDirection={isSmallScreen ? 'column' : 'row'}
					>
						<Grid
							container
							flexDirection="column"
							style={{
								marginBottom: 16,
								flex: 1,
							}}
						>
							<Field
								name={`${prefix}.city`}
								value={values!.city}
								as={TextField}
								fullWidth
								onChange={handleChange}
								onBlur={handleBlur}
								required
								label={translations.forms.city}
								placeholder={translations.placeholder.city}
							/>
							<Error name={`${prefix}.city`} />
						</Grid>
						<Grid
							container
							flexDirection="column"
							style={{
								marginBottom: 16,
								flex: 1,
							}}
						>
							<Field
								name={`${prefix}.post_code`}
								value={values!.post_code}
								as={TextField}
								fullWidth
								onChange={handleChange}
								onBlur={handleBlur}
								required
								label={translations.forms.post_code}
								placeholder={
									translations.placeholder.post_code
								}
							/>
							<Error name={`${prefix}.post_code`} />
						</Grid>
					</Grid>
					<Grid
						container
						columnGap={2}
						flexDirection="column"
						style={{
							marginBottom: 16,
							flex: 1,
						}}
					>
						<CountryDropdown
							style={{}}
							countries={countries}
						/>
						<Error name={`${prefix}.country`} />
					</Grid>
				</Grid>
			) : null}
			{!isDelivery || !isCheckout ? null : (
				<>
					<DeliveryMethodDetails
						name={`${prefix}.delivery_method_id`}
						delivery_method_id={values.delivery_method_id}
					/>
					<Error name={`${prefix}.delivery_method_id`} />
					<Grid
						container
						sx={{
							padding: 0,
							margin: 0,
							marginTop: 2,
						}}
						flexDirection="column"
					>
						<Typography
							variant="body1"
							component="p"
							sx={{
								paddingBottom: 0,
								marginBottom: 1,
							}}
						>
							{translations.text.phone_number_delivery}
						</Typography>
						<InputMask
							mask="+36999999999"
							value={values!.phone_number}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							{() => (
								<Field
									name={`${prefix}.phone_number`}
									sx={{
										marginTop: 0,
										width: {
											xs: '100%',
											md: '49%',
										},
									}}
									as={TextField}
									fullWidth
									margin="normal"
									required
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<img
													src={hungary}
													style={{
														height: '32px',
														width: '32px',
													}}
												/>
											</InputAdornment>
										),
									}}
									value={values!.phone_number}
									onChange={handleChange}
									onBlur={handleBlur}
									label={translations.forms.phone_number}
									placeholder={
										translations.placeholder.phone_number
									}
								/>
							)}
						</InputMask>
						<Error name={`${prefix}.phone_number`} />
					</Grid>

					{isLocker && selectedLocker === undefined ? (
						<LockerMap />
					) : isLocker && selectedLocker ? (
						<SelectedLocker lockerDetail={selectedLocker} />
					) : null}
				</>
			)}
			{!isCheckout ? null : isDelivery ? null : (
				<FormControlLabel
					control={
						<Checkbox
							checked={isBillingSame}
							onChange={handleCheckboxChange}
							name="isBillingSame"
							color="primary"
						/>
					}
					label={translations.text.delivery_is_same}
				/>
			)}
		</Paper>
	);
};
